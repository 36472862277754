function ImportStageMessage(importStage, t) {
  if (importStage === "File format validation") {
    return t("importStageMessage_1");
  }

  if (importStage === "Import in progress") {
    return t("importStageMessage_2");
  }

  if (importStage === "Data transfer") {
    return t("importStageMessage_3");
  }
  if (importStage==="Data Validation")
  {
    return t("importStageMessage_4");

  }
  if (importStage === "Import Complete") {
    return t("importStageMessage_5");
  }
  
}

export { ImportStageMessage };
