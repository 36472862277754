import api from "./ApiService";
import fileDownload from "js-file-download";
class MemoTransactionService {
  getMemoTransactionByHotelId(hotelId, lazyParams, filters) {
    return api.get(hotelId + "/memo_transactions", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        month: filters.month,
        year: filters.year,
        accountCode: filters.accountCode,
        departmentCode: filters.departmentCode,
        description: filters.description,
        amount: filters.amount,
        searchMode: filters.searchMode,
        accountType: "Memo",
      },
    });
  }
  addMemoTransactionByHotelId(
    hotelId,
    year,
    month,
    accountCode,
    departmentCode,
    amount,
    description,
    transactionDate
  ) {
    return api.post(hotelId + "/memo_transactions/add", {
      year: year,
      month: month,
      accountCode: accountCode,
      departmentCode: departmentCode,
      amount: amount,
      description: description,
      transactionDate: transactionDate,
    });
  }
  updateTransactionByHotelId(
    hotelId,
    transactionId,
    year,
    month,
    accountCode,
    departmentCode,
    description,
    transactionDate,
    amount
  ) {
    return api.put(hotelId + "/memo_transactions/update", {
      transactionId: transactionId,
      year: year,
      month: month,
      accountCode: accountCode,
      departmentCode: departmentCode,
      amount: amount,
      description: description,
      transactionDate: transactionDate,
    });
  }
  deleteTransactionByHotelIdAndId(hotelId, id) {
    return api.delete(hotelId + "/memo_transactions/" + id);
  }
  exportMemoTransactionFile(hotelId, filters) {
    return api
      .get(
        hotelId + "/memo_transactions/export",
        {
          params: {
            month: filters.month,
            year: filters.year,
            accountCode: filters.accountCode,
            departmentCode: filters.departmentCode,
            description: filters.description,
            amount: filters.amount,
          },
        },

        { responseType: "blob" }
      )
      .then(res => {
        fileDownload(res.data, "MemoTransaction.csv");
      });
  }
}

export default new MemoTransactionService();
