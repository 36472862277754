function CustomDateFormat(date, locale) {
    const convertedDate =new Date(date);
  let d = convertedDate.getDate(),
    m = convertedDate.toLocaleString(locale, { month: "short" }),
    y = convertedDate.getFullYear(),
    t = convertedDate.toLocaleTimeString(locale, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: "true",
    });

  return `${d} ${m}, ${y}, ${t}`;
}

export { CustomDateFormat };
