import React from "react";
import { Tooltip } from "primereact/tooltip";


import { ConfirmDelete } from "./ConfirmDeleteItem";
const actionBodyTemplate = (
  rowdata,
  t,
  handleVisibleEditSidebar,
  getSelectedRow,
  deletedItem,
  deleteAction
) => {
  return (
    <React.Fragment>
      <Tooltip target=".custom-target-icon" />
      <div style={{ width: "100%" }}>
        <div id="setup-for-account-datatable">
          <i
            className="custom-target-icon pi pi pi-pencil p-toolbar-separator mr-6 font-bold"
            data-pr-position="top"
            data-pr-tooltip={t("editTooltipMessage")}
            onClick={() => {
              handleVisibleEditSidebar();
              getSelectedRow(rowdata);
            }}
          />
          <i
            id="delete-icons"
            className="custom-target-icon pi pi-trash font-bold"
            data-pr-position="top"
            data-pr-tooltip={t("deleteTooltipMessage")}
            onClick={() => ConfirmDelete(t, deletedItem, deleteAction)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export { actionBodyTemplate };
