import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import CustomAutoComplete from "./CustomAutoComplete";
import { getLocalStorage } from "../services/LocalStorage";
import AccountService from "../services/AccountService";
import DepartmentService from "../services/DepartmentService";
import TransactionService from "../services/TransactionService";
import { useLanguageContext } from "../context/GlobalProvider";

export default function CustomFilterSideBar(props) {
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [accountCodeList, setAccountCodeList] = useState([]);
  const [departmentCodeList, setDepartmentCodeList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const { t } = useLanguageContext();

  function filteredDecription(filterValue) {
    TransactionService.lovGetTransactionByHotelId(DefaultHotelId, {
      selectedDescription: filterValue,
      searchMode: "AND",
    }).then(res => {
      setDescriptionList(
        res.data.map(item => ({
          id: item.description,
        }))
      );
    });
  }
  function filteredAccountCode(filterValue) {
    AccountService.lovGetAccountByHotelId(DefaultHotelId, {
      selectedAccountCode: filterValue,
      selectedAccountName: "",
      selectedAccountType: props.selectedAccountType,
      searchMode: "AND",
    }).then(res => {
      setAccountCodeList(
        res.data.map(item => ({
          id: item.accountCode,
        }))
      );
    });
  }
  function filteredDepartmentCode(filterValue) {
    DepartmentService.lovGetDepartmentByHotelId(DefaultHotelId, {
      selectedDepartmentCode: filterValue,
      selectedDepartmentName: "",
      searchMode: "OR",
    }).then(res => {
      setDepartmentCodeList(
        res.data.map(item => ({
          id: item.departmentCode,
        }))
      );
    });
  }

  const customHeaderFilter = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={props.handleVisibleFilterSideBar}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderFilter}
        id="sidebar-filter"
        visible={props.visibleFilterSideBar}
        position="right"
        className="p-sidebar-md"
      >
        <form onSubmit={props.handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={props.selectedAccountCode}
                  options={accountCodeList}
                  filterList={filteredAccountCode}
                  onChange={props.handleSelectedAccountCodeChange}
                  placeholder={t("placeholderAccountCodeField")}
                  dropdown={false}
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={props.selectedDepartmentCode}
                  options={departmentCodeList}
                  filterList={filteredDepartmentCode}
                  onChange={props.handleSelectedDepartmentCodeChange}
                  placeholder={t("placeholderDepartmentCodeField")}
                  dropdown={false}
                ></CustomAutoComplete>
                <div className="field mb-4 col-12"></div>

                <InputText
                  value={props.selectedDescription}
                  onChange={props.handleSelectedDesciptionChange}
                  placeholder={t("placeholderDescriptionField")}
                ></InputText>
              </div>
              <div className="field mb-4 col-12">
                <InputNumber
                  inputId="locale-us"
                  value={props.amount}
                  onValueChange={props.handleAmountChange}
                  mode="decimal"
                  locale="en-US"
                  minFractionDigits={3}
                />
              </div>
              <div className="field mb-4 col-12">
                <InputMask
                  mask="9999/99"
                  placeholder={t("placeholderPeriodField")}
                  value={props.year + "/" + props.month}
                  onChange={props.handlePeriodChange}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label={t("findButton")}
                  className="p-button p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={props.clearFilters}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={props.handleVisibleFilterSideBar}
                  className="p-button-secondary p-button-outlined  w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
    </React.Fragment>
  );
}
