import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import DataControls from "../../components/DataTools/DataControls";
import TransactionService from "../../services/TransactionService";
import { getLocalStorage } from "../../services/LocalStorage";
import CustomFilterSideBar from "../../components/CustomFilterSideBar";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showWarnToast, showErrorToast } from "../../components/CustomToast";

export default function Transaction(props) {
  const [visibleFilterSideBar, setVisibleFilterSideBar] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [totalRecords, setTotalRecords] = useState(0);
  const [month, setMonth] = useState(getLocalStorage("month"));
  const [year, setYear] = useState(getLocalStorage("year"));
  const [selectedAccountCode, setSelectedAccountCode] = useState("");
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const [filters, setFilters] = useState({
    accountCode: "",
    departmentCode: "",
    description: "",
    amount: "",
    month: month,
    year: year,
  });
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [transactionFilterBadges, setTransactionFilterBadges] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const onSort = event => {
    setLazyParams(event);
  };
  const onPage = event => {
    setLazyParams(event);
  };

  const { t } = useLanguageContext();

  function formatPeriod(year, month) {
    return year + "/" + month;
  }
  const loadLazyTransactionData = useCallback(() => {
    setLoading(true);
    TransactionService.getTransactionByHotelId(
      DefaultHotelId,
      lazyParams,
      filters
    )
      .then(res => {
        setTransactionData(res.data.transactions);
        setTotalRecords(res.data.totalRecords);
        setLoading(false);
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }, [DefaultHotelId, lazyParams, filters]);

  useEffect(() => {
    if (props.selectedHotel) {
      setTransactionFilterBadges([
        { key: "1", filterValue: formatPeriod(year, month), removable: false },
      ]);
    }
  }, [props.selectedHotel]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyTransactionData();
    }
  }, [loadLazyTransactionData, props.selectedHotel]);

  function handleSearchButtonClick() {
    setVisibleFilterSideBar(true);
  }
  function handleSelectedAccountCodeChange(event) {
    setSelectedAccountCode(event.target.value);
  }
  function handleSelectedDepartmentCodeChange(event) {
    setSelectedDepartmentCode(event.target.value);
  }
  function handleSelectedDesciptionChange(event) {
    setSelectedDescription(event.target.value);
  }
  function handleAmountChange(event) {
    setAmount(event.target.value);
  }

  function clearTransactionFilters() {
    setFilters({
      accountCode: "",
      departmentCode: "",
      description: "",
      amount: "",
      month: month,
      year: year,
    });
    setSelectedAccountCode("");
    setSelectedDepartmentCode("");
    setSelectedDescription("");
    setAmount("");
  }

  function handlePeriodChange(e) {
    setYear(e.target.value.substring(0, 4));
    setMonth(e.target.value.substring(5));
  }
  function dialogCallBack() {
    setYear(getLocalStorage("year"));
    setMonth(getLocalStorage("month"));
    setDialogVisible(false);
  }
  const dialogFooter = (
    <div>
      <Button
        label="OK"
        className="p-button-danger"
        onClick={() => dialogCallBack()}
      />
    </div>
  );
  const formatFiltredAmount = value => {
    return parseFloat(amount).toFixed(3);
  };

  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];

    newFilters.push({
      key: "1",
      filterValue: formatPeriod(year, month),
      removable: false,
    });
    if (selectedAccountCode.id || selectedAccountCode) {
      newFilters.push({
        key: "2",
        filterValue: selectedAccountCode.id || selectedAccountCode,
        removable: true,
      });
    }

    if (selectedDepartmentCode.id || selectedDepartmentCode) {
      newFilters.push({
        key: "3",
        filterValue: selectedDepartmentCode.id || selectedDepartmentCode,
        removable: true,
      });
    }
    if (selectedDescription.id || selectedDescription) {
      newFilters.push({
        key: "4",
        filterValue: selectedDescription.id || selectedDescription,
        removable: true,
      });
    }
    if (amount) {
      newFilters.push({
        key: "5",
        filterValue: formatFiltredAmount(amount),
        removable: true,
      });
    }
    setFilters({
      accountCode: selectedAccountCode.id || selectedAccountCode,
      departmentCode: selectedDepartmentCode.id || selectedDepartmentCode,
      description: selectedDescription.id || selectedDescription,
      amount: amount,
      month: month,
      year: year,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setTransactionFilterBadges(newFilters);

    setVisibleFilterSideBar(false);
  }
  function handleFilterBadgeClear() {
    setTransactionFilterBadges([
      { key: "1", filterValue: formatPeriod(year, month), removable: false },
    ]);
    setSelectedAccountCode("");
    setSelectedDepartmentCode("");
    setSelectedDescription("");
    setAmount("");

    setFilters({
      accountCode: "",
      departmentCode: "",
      description: "",
      amount: "",
      month: month,
      year: year,
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "2":
        setSelectedAccountCode("");
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
        break;
      case "3":
        setSelectedDepartmentCode("");
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
        break;
      case "4":
        setSelectedDescription("");
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
        break;
      // case "5":
      //   setAmount("");
      //   setFilters({
      //     accountCode: "",
      //     departmentCode: "",
      //     description: "",
      //     amount: "",
      //     month: month,
      //     year: year,
      //   });
      //   break;

      default:
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
    }
    setTransactionFilterBadges(current => current.filter(el => el.key !== key));
  }
  function exportCSVTransactionFile() {
    TransactionService.exportTransactionFile(DefaultHotelId, filters).then(
      res => {
        showWarnToast(toast, t);
      }
    );
  }
  function handleVisibleFilterSideBar() {
    setVisibleFilterSideBar(false);
  }

  const formatAmount = value => {
    return value.toLocaleString("en", { minimumFractionDigits: 3 });
  };

  const amountBodyTemplate = rowdata => {
    return formatAmount(rowdata.amount);
  };
  const formatDate = value => {
    return new Date(value).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const dateBodyTemplate = rowData => {
    return formatDate(rowData.transactionDate);
  };
  return (
    <React.Fragment>
      <Toast ref={toast}></Toast>
      <DataControls
        addButtonLabel="null"
        onSearchButtonClick={handleSearchButtonClick}
        filters={transactionFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        exportCSV={exportCSVTransactionFile}
        displayedImportExportDataControl="true"
        displayedDataControl="true"
      />
      <ConfirmDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        message={dialogMessage}
        footer={dialogFooter}
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        header="Information"
      />
      <DataTable
        value={transactionData}
        className="relative w-full"
        lazy
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
        responsiveLayout="scroll"
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column field="accountCode" sortable header={t("accountCode")} />
        <Column
          field="account.accountName"
          sortable
          header={t("accountName")}
        />
        <Column field="departmentCode" sortable header={t("departmentCode")} />
        <Column
          field="department.departmentName"
          sortable
          header={t("departmentName")}
        />
        <Column
          field="transactionDate"
          sortable
          header={t("transactionDate")}
          dataType="date"
          body={dateBodyTemplate}
        />
        <Column field="description" sortable header={t("description")} />
        <Column
          field="amount"
          body={amountBodyTemplate}
          sortable
          header={t("transactionAmount")}
        />
      </DataTable>
      <CustomFilterSideBar
        visibleFilterSideBar={visibleFilterSideBar}
        month={month}
        year={year}
        selectedAccountCode={selectedAccountCode}
        selectedDepartmentCode={selectedDepartmentCode}
        selectedDescription={selectedDescription}
        amount={amount}
        handleSearch={handleSearch}
        handleAmountChange={handleAmountChange}
        handleSelectedDepartmentCodeChange={handleSelectedDepartmentCodeChange}
        handleSelectedDesciptionChange={handleSelectedDesciptionChange}
        handleSelectedAccountCodeChange={handleSelectedAccountCodeChange}
        handleVisibleFilterSideBar={handleVisibleFilterSideBar}
        handlePeriodChange={handlePeriodChange}
        clearFilters={clearTransactionFilters}
        selectedAccountType=""
      ></CustomFilterSideBar>
    </React.Fragment>
  );
}
