import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useCallback } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import CustomAutoComplete from "../../components/CustomAutoComplete";
import BudgetVersionsService from "../../services/BudgetVersionsService";
import DataControls from "../../components/DataTools/DataControls";
import { getLocalStorage } from "../../services/LocalStorage";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast, showSuccessToast } from "../../components/CustomToast";
import { actionBodyTemplate } from "../../utils/DataTableAction";

export default function BudgetVersions(props) {
  const [budgetVersionData, setBudgetVersionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleAddBudgetVersionsSidebar, setvisibleAddBudgetVersionsSidebar] =
    useState(false);
  const [
    visibleEditBudgetVersionsSidebar,
    setVisibleEditBudgetVersionsSidebar,
  ] = useState(false);
  const [budgetVersionCode, setBudgetVersionCode] = useState("");
  const [budgetVersionDescription, setBudgetVersionDescription] = useState("");
  const [budgetVersionCodeList, setBudgetVersionCodeList] = useState([]);
  const [budgetVersionDescriptionList, setBudgetVersionDescriptionList] =
    useState([]);
  const [budgetVersionFilterBadges, setBudgetVersionFilterBadges] = useState(
    []
  );
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedBudgetVersionCode, setSelectedBudgetVersionCode] =
    useState("");
  const [
    selectedBudgetVersionDescription,
    setSelectedBudgetVersionDescription,
  ] = useState("");
  const [
    visibleFilterBudgetVersionsSidebar,
    setVisibleFilterBudgetVersionsSidebar,
  ] = useState(false);
  const [filters, setFilters] = useState({
    budgetVersionCode: "",
    description: "",
  });
  const toast = useRef(null);

  const [lazyParams, setLazyParams] = useState({
    first: 1,
    rows: 4,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const onSort = event => {
    setLazyParams(event);
  };
  const onPage = event => {
    setLazyParams(event);
  };
  const { t } = useLanguageContext();
  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(false);

  const loadLazyBudgetVersionData = useCallback(() => {
    setLoading(true);
    BudgetVersionsService.getBudgetVersionsByHotelId(
      DefaultHotelId,
      lazyParams,
      filters
    ).then(response => {
      setBudgetVersionData(response.data.budgetVersions);
      setTotalRecords(response.data.totalRecords);
      setLoading(false);
    });
  }, [DefaultHotelId, lazyParams, filters]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyBudgetVersionData();
    }
  }, [loadLazyBudgetVersionData, props.selectedHotel]);

  function saveBudgetVersion() {
    setClickedButton(true);
    BudgetVersionsService.addBudgetVersionsByHotelId(
      DefaultHotelId,
      budgetVersionCode,
      budgetVersionDescription
    )
      .then(res => {
        setClickedButton(false);
        setBudgetVersionCode("");
        setBudgetVersionDescription("");
        loadLazyBudgetVersionData();

        showSuccessToast(toast, t, "add");
        setvisibleAddBudgetVersionsSidebar(false);
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }
  function editBudgetVersion(e) {
    setClickedButton(true);
    e.preventDefault();
    BudgetVersionsService.updateBudgetVersionsByHotelId(
      DefaultHotelId,
      budgetVersionCode,
      budgetVersionDescription
    )
      .then(res => {
        setClickedButton(false);
        setBudgetVersionCode("");
        setBudgetVersionDescription("");
        loadLazyBudgetVersionData();
        setVisibleEditBudgetVersionsSidebar(false);

        showSuccessToast(toast, t, "update");
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }
  function deleteBudgetVersion(budgetVersionCode) {
    BudgetVersionsService.deleteBudgetVersionsByHotelIdAndId(
      DefaultHotelId,
      budgetVersionCode
    )
      .then(res => {
        setBudgetVersionData(
          budgetVersionData.filter(
            value =>
              value.budgetVersionId.budgetVersionCode !== budgetVersionCode
          )
        );

        showSuccessToast(toast, t, "delete");
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }

  function handleBudgetVersionCodeChange(event) {
    setBudgetVersionCode(event.target.value);
  }

  function handleBudgetVersionDescriptionChange(event) {
    setBudgetVersionDescription(event.target.value);
  }
  function handleButtonClick() {
    setvisibleAddBudgetVersionsSidebar(true);
  }

  function getSelectedRow(rowdata) {
    setBudgetVersionCode(rowdata.budgetVersionId.budgetVersionCode);
    setBudgetVersionDescription(rowdata.description);
  }

  function handleSearchButtonClick() {
    setVisibleFilterBudgetVersionsSidebar(true);
  }

  function handleSelectedBudgetVersionCodeChange(event) {
    setSelectedBudgetVersionCode(event.target.value);
  }

  function handleSelectedBudgetVersionDescriptionChange(event) {
    setSelectedBudgetVersionDescription(event.target.value);
  }

  function clearFilters() {
    setSelectedBudgetVersionCode("");
    setSelectedBudgetVersionDescription("");
  }
  const customHeaderNewbudgetVersion = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setvisibleAddBudgetVersionsSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("newBudgetVersionSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderEditBudgetVersion = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleEditBudgetVersionsSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("editBudgetVersionSidebarTitle")}
      </div>
    </React.Fragment>
  );

  const customHeaderFilterBudgetVersion = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleFilterBudgetVersionsSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );

  function formReset() {
    setBudgetVersionCode("");
    setBudgetVersionDescription("");
    setVisibleEditBudgetVersionsSidebar(false);
  }

  function filteredBudgetVersionCode(filterValue) {
    BudgetVersionsService.lovGetBudgetVersionByHotelId(DefaultHotelId, {
      selectedBudgetVersionCode: filterValue,
      selectedBudgetVersionDescription: "",
      searchMode: "AND",
    }).then(res => {
      setBudgetVersionCodeList(
        res.data.map(item => ({
          id: item.budgetVersionCode,
        }))
      );
    });
  }
  function filteredBudgetVersionDescription(filterValue) {
    BudgetVersionsService.lovGetBudgetVersionByHotelId(DefaultHotelId, {
      selectedBudgetVersionCode: "",
      selectedBudgetVersionDescription: filterValue,
      searchMode: "AND",
    }).then(res => {
      setBudgetVersionDescriptionList(
        res.data.map(item => ({
          id: item.description,
        }))
      );
    });
  }

  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];

    if (selectedBudgetVersionCode.id || selectedBudgetVersionCode) {
      newFilters.push({
        key: "1",
        filterValue: selectedBudgetVersionCode.id || selectedBudgetVersionCode,
        removable: true,
      });
    }
    if (
      selectedBudgetVersionDescription.id ||
      selectedBudgetVersionDescription
    ) {
      newFilters.push({
        key: "2",
        filterValue:
          selectedBudgetVersionDescription.id ||
          selectedBudgetVersionDescription,
        removable: true,
      });
    }

    setFilters({
      budgetVersionCode:
        selectedBudgetVersionCode.id || selectedBudgetVersionCode,
      description:
        selectedBudgetVersionDescription.id || selectedBudgetVersionDescription,
    });
    setLazyParams({
      first: 0,
      rows: 1,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setBudgetVersionFilterBadges(newFilters);
    setVisibleFilterBudgetVersionsSidebar(false);
  }
  function handleFilterBadgeClear() {
    setBudgetVersionFilterBadges([]);
    setSelectedBudgetVersionCode("");
    setSelectedBudgetVersionDescription("");

    setFilters({
      budgetVersionCode: "",
      description: "",
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "1":
        setSelectedBudgetVersionCode("");
        setFilters({
          budgetVersionCode: "",
          description:
            selectedBudgetVersionDescription.id ||
            selectedBudgetVersionDescription,
        });
        break;
      case "2":
        setSelectedBudgetVersionDescription("");
        setFilters({
          budgetVersionCode:
            selectedBudgetVersionCode.id || selectedBudgetVersionCode,
          description: "",
        });
        break;
      default:
    }
    setBudgetVersionFilterBadges(current =>
      current.filter(el => el.key !== key)
    );
  }

  function handleVisibleEditSidebar() {
    setVisibleEditBudgetVersionsSidebar(true);
  }
  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <DataControls
        addButtonLabel={t("addButton")}
        onAddButtonClick={handleButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        filters={budgetVersionFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        displayedDataControl="true"
        displayedImportExportDataControl="false"
      />
      <DataTable
        value={budgetVersionData}
        className="relative w-full"
        lazy
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 20, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
        responsiveLayout="scroll"
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column
          field="budgetVersionId.budgetVersionCode"
          sortable
          header={t("budgetVersionCode")}
        />
        <Column
          field="description"
          sortable
          header={t("budgetVersionDescription")}
        />
        <Column
          body={rowdata => {
            return actionBodyTemplate(
              rowdata,
              t,
              handleVisibleEditSidebar,
              getSelectedRow,
              rowdata.budgetVersionId.budgetVersionCode,
              deleteBudgetVersion
            );
          }}
        />
      </DataTable>

      {/* Start- SideBar for add new  budget version */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderNewbudgetVersion}
        visible={visibleAddBudgetVersionsSidebar}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        position="right"
        onHide={() => setvisibleAddBudgetVersionsSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label
                htmlFor="budgetVersionCode"
                className="font-medium text-900"
              >
                {t("budgetVersionCode")}
              </label>
              <InputText
                type="text"
                value={budgetVersionCode}
                onChange={handleBudgetVersionCodeChange}
                id="budgetVersionCode"
              />
            </div>
            <div className="field mb-4 col-12">
              <label
                htmlFor="budgetVersionDescription"
                className="font-medium text-900"
              >
                {t("budgetVersionDescription")}
              </label>
              <InputText
                type="text"
                value={budgetVersionDescription}
                onChange={handleBudgetVersionDescriptionChange}
                id="budgetVersionDescription"
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("saveButton")}
                icon="pi pi-plus"
                onClick={() => saveBudgetVersion()}
                loading={clickedButton}
                className="p-button w-auto p-button-success mr-2"
              />
              <Button
                label={t("cancelButton")}
                onClick={() => setvisibleAddBudgetVersionsSidebar(false)}
                className="p-button-secondary p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      {/* Start- SideBar for edit budget version */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderEditBudgetVersion}
        visible={visibleEditBudgetVersionsSidebar}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        position="right"
        onHide={() => setVisibleEditBudgetVersionsSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label
                htmlFor="budgetVersionCode"
                className="font-medium text-900"
              >
                {t("budgetVersionCode")}
              </label>
              <InputText
                type="text"
                disabled
                value={budgetVersionCode}
                onChange={handleBudgetVersionCodeChange}
                id="budgetVersionCode"
              />
            </div>
            <div className="field mb-4 col-12">
              <label
                htmlFor="budgetVersionDescription"
                className="font-medium text-900"
              >
                {t("budgetVersionDescription")}
              </label>
              <InputText
                type="text"
                value={budgetVersionDescription}
                onChange={handleBudgetVersionDescriptionChange}
                id="budgetVersionDescription"
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("updateButton")}
                onClick={editBudgetVersion}
                loading={clickedButton}
                className="p-button w-auto p-button-success  mr-2"
              />
              <Button
                label={t("cancelButton")}
                onClick={() => formReset()}
                className="p-button-secondary p-button-outlined  w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      {/* Start- SideBar for filter budget version */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderFilterBudgetVersion}
        id="sidebar-filter"
        visible={visibleFilterBudgetVersionsSidebar}
        position="right"
        className="p-sidebar-md	"
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedBudgetVersionCode}
                  options={budgetVersionCodeList}
                  filterList={filteredBudgetVersionCode}
                  onChange={handleSelectedBudgetVersionCodeChange}
                  placeholder={t("placeholderBudgetVersionCodeField")}
                  dropdown={false}
                  inputId="budgetVersionCode"
                  name="budgetVersionCode"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedBudgetVersionDescription}
                  options={budgetVersionDescriptionList}
                  filterList={filteredBudgetVersionDescription}
                  onChange={handleSelectedBudgetVersionDescriptionChange}
                  placeholder={t("placeholderBudgetVersionNameField")}
                  dropdown={false}
                  inputId="budgetVersionName"
                  name="budgetVersionName"
                ></CustomAutoComplete>
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label={t("findButton")}
                  className="p-button p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => clearFilters()}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={() => setVisibleFilterBudgetVersionsSidebar(false)}
                  className="p-button-secondary  p-button-outlined w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
      <ConfirmDialog />
    </React.Fragment>
  );
}
