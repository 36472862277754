import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import DataControls from "../../components/DataTools/DataControls";
import { useLanguageContext } from "../../context/GlobalProvider";
import { getLocalStorage } from "../../services/LocalStorage";
import KPIsAssignmentTableService from "../../services/KPIsAssignmentTableService";
export default function KPIsAssignmentTable() {
  const [selectedReportName, setSelectedReportrName] = useState(null);

  const [selectedFormat, setSelectedFormat] = useState(null);
  const [loadingExportReport, setLoadingExportReport] = useState(false);
  const { t } = useLanguageContext();

  const formatList = [
    { id: "xlsx", name: "excel" },
    { id: "pdf", name: "pdf" },
    { id: "csv", name: "csv" },
  ];

  const reportNameList = [
    {
      id: "TableAffectationDesComptesSections",
      name: t("AccountsectionsAssignmentTableReportName"),
    },
    {
      id: "Mapping_Lignes_PL_KPIs",
      name: t("MappingLignesPLKPIs"),
    },
  ];

  function exportReports() {
    setLoadingExportReport(true);
    const reportIdByGroupHotel = "TableAffectationDesComptesSections";
    const reportIdMappingLignesPLKPIsByGroupHotel = "Mapping_Lignes_PL_KPIs";
    let reportParamString = "";
    const groupHotel = getLocalStorage("groupHotel");
    const hotelId = getLocalStorage("pilotHotelId");
    if (selectedFormat === "xlsx") {
      if (selectedReportName === "TableAffectationDesComptesSections") {
        reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId}`;
        KPIsAssignmentTableService.printExcelReport(
          selectedReportName,
          "",
          reportIdByGroupHotel,
          reportParamString,
          selectedFormat
        ).then(res => {
          setLoadingExportReport(false);
          setSelectedFormat("");
          setSelectedReportrName("");
        });
      }
      if (selectedReportName === "Mapping_Lignes_PL_KPIs") {
        reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId}`;
        KPIsAssignmentTableService.printExcelReport(
          selectedReportName,
          "",
          reportIdMappingLignesPLKPIsByGroupHotel,
          reportParamString,
          selectedFormat
        ).then(res => {
          setLoadingExportReport(false);
          setSelectedFormat("");
          setSelectedReportrName("");
        });
      }
    }
    if (selectedFormat === "pdf") {
      setLoadingExportReport(true);
      const reportIdByGroupHotel = "TableAffectationDesComptesSections";
      const reportIdMappingLignesPLKPIsByGroupHotel = "Mapping_Lignes_PL_KPIs";
      let reportParamString = "";
      const groupHotel = getLocalStorage("groupHotel");
      const hotelId = getLocalStorage("pilotHotelId");
      if (selectedReportName === "TableAffectationDesComptesSections") {
        reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId}`;
        KPIsAssignmentTableService.printPdfReport(
          selectedReportName,
          "",
          reportIdByGroupHotel,
          reportParamString,
          selectedFormat
        ).then(res => {
          setLoadingExportReport(false);
          setSelectedFormat("");
          setSelectedReportrName("");
        });
      }
      if (selectedReportName === "Mapping_Lignes_PL_KPIs") {
        reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId}`;
        KPIsAssignmentTableService.printPdfReport(
          selectedReportName,
          "",
          reportIdMappingLignesPLKPIsByGroupHotel,
          reportParamString,
          selectedFormat
        ).then(res => {
          setLoadingExportReport(false);
          setSelectedFormat("");
          setSelectedReportrName("");
        });
      }
    }
    if (selectedFormat === "csv") {
      setLoadingExportReport(true);

      const reportIdByGroupHotel = "TableAffectationDesComptesSections";
      const reportIdMappingLignesPLKPIsByGroupHotel = "Mapping_Lignes_PL_KPIs";
      let reportParamString = "";
      const groupHotel = getLocalStorage("groupHotel");
      const hotelId = getLocalStorage("pilotHotelId");

      if (selectedReportName === "TableAffectationDesComptesSections") {
        reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId}`;
        KPIsAssignmentTableService.printCsvReport(
          selectedReportName,
          "",
          reportIdByGroupHotel,
          reportParamString,
          selectedFormat
        ).then(res => {
          setLoadingExportReport(false);
          setSelectedFormat("");
          setSelectedReportrName("");
        });
      }
      if (selectedReportName === "Mapping_Lignes_PL_KPIs") {
        reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId}`;
        KPIsAssignmentTableService.printCsvReport(
          selectedReportName,
          "",
          reportIdMappingLignesPLKPIsByGroupHotel,
          reportParamString,
          selectedFormat
        ).then(res => {
          setLoadingExportReport(false);
          setSelectedFormat("");
          setSelectedReportrName("");
        });
      }
    }
  }

  return (
    <React.Fragment>
      <DataControls displayedImportExportDataControl="false" />
      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="p-fluid pr-0 md:pr-6">
              <div className="text-center text-xl font-bold"></div>
              <div className="field mb-3">
                <label htmlFor="reportName" className="font-medium">
                  {t("labelReportNameField")} *
                </label>
                <Dropdown
                  value={selectedReportName}
                  onChange={e => setSelectedReportrName(e.value)}
                  options={reportNameList}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("placeholderReportNameField")}
                  inputId="reportName"
                />
              </div>

              <div className="field mb-3">
                <label htmlFor="selectedFormat" className="font-medium">
                  {t("labelFormatField")} *
                </label>
                <Dropdown
                  value={selectedFormat}
                  onChange={e => setSelectedFormat(e.value)}
                  options={formatList}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("placeholderFormatField")}
                  inputId="selectedFormat"
                />
              </div>

              <Button
                type="submit"
                label={t("exportButton")}
                className="w-auto p-button-success"
                onClick={() => exportReports()}
                loading={loadingExportReport}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
