import api from "./ApiService";

class UserService {
  getUserInformation(userName) {
    return api.get("users/" + userName);
  }
  getClosedPeriod(hotelId) {
    return api.get(hotelId + "/closed_period");
  }
  updateUser(
    apiUserId,
    apiUserName,
    password,
    confirmPassword,
    firstName,
    lastName,
    apiUserStatus,
    defaultHotelId,
    language,
    apiUserRoleIds
  ) {
    return api.put(
      "/users/update",
      {
        apiUserId: apiUserId,
        apiUserName: apiUserName,
        password: password,
        confirmPassword: confirmPassword,
        firstName: firstName,
        lastName: lastName,
        apiUserStatus: apiUserStatus,
        defaultHotelId: defaultHotelId,
        language: language,
        apiUserRoleIds: apiUserRoleIds,
      },
      // {
      //   params: {
      //     apiNewPassword: newPassword,
      //     apiConfirmPassword: confirmNewPassword,
      //   },
      // }
    );
  }
}
export default new UserService();
