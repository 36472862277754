import React, { useState, useRef, useEffect, useCallback } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import { FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";

import DataControls from "../../components/DataTools/DataControls";
import TransactionService from "../../services/TransactionService";
import { getLocalStorage, setLocalStorage } from "../../services/LocalStorage";

import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast } from "../../components/CustomToast";
import { ImportStageMessage } from "../../utils/ImportStage";

export default function UploadJounral() {
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [initialSyncValue, setInitialSyncValue] = useState(false);
  const [periodValue, setPeriodValue] = useState("");
  const [uploadJournalBadges, setUploadJournalBadges] = useState([]);
  const [month] = useState(getLocalStorage("month"));
  const [year] = useState(getLocalStorage("year"));
  const toast = useRef(null);

  const { t } = useLanguageContext();

  //for ProgressBar process status indicator.
  const [importStage, setImportStage] = useState("");
  let [progressValue, setProgressValue] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showSpinnerBar, setShowSpinnerBar] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [importStatus, setImportStatus] = useState(false);
  const interval = useRef(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const selectMaxFileSize = 1024 * 1024 * 100;
  const ref = useRef();
  const onHandleClearSelectedFile = e => {
    ref.current.clear();
  };

  function handlePeriodValueChange(event) {
    setPeriodValue(event.target.value);
  }

  function formatLastClosedPeriod(year, month) {
    return year + "/" + month;
  }

  useEffect(() => {
    setUploadJournalBadges([
      {
        key: "1",
        filterValue: formatLastClosedPeriod(year, month),
        removable: false,
      },
    ]);
  }, [year, month]);

  //i
  const importJournal = useCallback(
    event => {
      setLocalStorage("periodvalue", periodValue);
      setLocalStorage("currentSelectedFile", ref.current.value);

      //disable button
      setDisabledButton(true);
      setShowSpinnerBar(true);

      setShowCancelButton(false);
      const FormData = global.FormData;
      const formData = new FormData();
      formData.append("isInitialSync", initialSyncValue);
      formData.append(
        "period",
        periodValue.substring(0, 4) + "0" + periodValue.substring(5)
      );
      formData.append("file", event.files[0]);
      TransactionService.uploadTransactionFile(DefaultHotelId, formData)
        .then(res => {})
        .catch(error => {
          showErrorToast(toast, t, error);
          setDisabledButton(false);
          setShowSpinnerBar(false);
          setShowProgressBar(false);
          setShowCancelButton(true);
        });

      //uploadTransactionFileProgress service
      clearInterval(interval.current);
      interval.current = setInterval(() => {
        TransactionService.uploadTransactionFileProgress(
          DefaultHotelId,
          getLocalStorage("groupHotel")
        )
          .then(res => {
            //addded new
            setShowSpinnerBar(true);
            console.log("res.data", res.data.importStatus);
            //setImport status
            setImportStatus(res.data.importStatus);
            setProgressValue(res.data.importProgress);
            setImportStage(res.data.importStage);
            if (res.data.importStatus === true) {
              setShowProgressBar(true);

              setShowSpinnerBar(true);
            }
          })
          .catch(error => {
            clearInterval(interval.current);
            showErrorToast(toast, t, error);
            setDisabledButton(false);
            setShowSpinnerBar(false);
            setShowProgressBar(false);
            setShowCancelButton(true);
          });
      }, 2000);

      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    },
    [DefaultHotelId, t, periodValue, initialSyncValue, showProgressBar]
  );

  useEffect(() => {
    clearInterval(interval.current);

    TransactionService.uploadTransactionFileProgress(
      DefaultHotelId,
      getLocalStorage("groupHotel")
    )
      .then(res => {
        //setImport status
        setImportStatus(res.data.importStatus);
        if (res.data.importStatus === true) {
          setShowProgressBar(true);

          clearInterval(interval.current);
          interval.current = setInterval(() => {
            TransactionService.uploadTransactionFileProgress(
              DefaultHotelId,
              getLocalStorage("groupHotel")
            )
              .then(res => {
                //setImport status
                setImportStatus(res.data.importStatus);
                if (res.data.importStatus === true) {
                  setImportStage(res.data.importStage);
                  setProgressValue(res.data.importProgress);

                  setShowProgressBar(true);
                  setShowSpinnerBar(true);
                  setDisabledButton(true);
                  setShowCancelButton(false);
                }
                if (res.data.importStatus === false) {
                  toast.current.show({
                    severity: "info",
                    summary: "Success",
                    detail: ImportStageMessage(res.data.importStage, t),
                    life: "60000",
                  });
                  setProgressValue(0);
                  setPeriodValue("");
                  ref.current.clear();

                  setDisabledButton(false);
                  //hide spinner bar
                  setShowSpinnerBar(false);
                  setShowProgressBar(false);
                  //show cancelbutton
                  setShowCancelButton(true);
                  clearInterval(interval.current);
                }
              })
              .catch(error => {
                clearInterval(interval.current);
                setDisabledButton(false);
                setShowSpinnerBar(false);
                setShowProgressBar(false);
                setShowCancelButton(true);
                showErrorToast(toast, t, error);
              });
          }, 3000);
        } else if (res.data.importStatus === false) {
          setShowProgressBar(false);
          //new added maybe we deleted

          clearInterval(interval.current);
        }
      })
      .catch(error => {
        //catch error
        clearInterval(interval.current);
        setDisabledButton(false);
        setShowSpinnerBar(false);
        setShowProgressBar(false);
        setShowCancelButton(true);
        showErrorToast(toast, t, error);
      });
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [DefaultHotelId, showProgressBar, t]);

  useEffect(() => {
    if (importStatus === true) {
      setShowProgressBar(true);
    }
  }, [importStatus]);

  return (
    <React.Fragment>
      <DataControls
        displayedImportExportDataControl="false"
        filters={uploadJournalBadges}
      />
      <Toast ref={toast} />
      <div className="surface-card p-8 shadow-2 border-round">
        <div className="field mb-4 col-6">
          <FaFileDownload style={{ color: "blue" }} />

          <Link
            to="../../../assets/files/Jounal Template.csv"
            target="_blank"
            download
          >
            {t("titleJournalTemplateDownloadLink")}
          </Link>
        </div>
        <div className="mb-3 flex align-items-center justify-content-between">
          {getLocalStorage("role") === "ADMIN" && (
            <div className=" grid field mb-4 col-6">
              <div>
                <label>{t("labelInitialSyncField")}</label>
              </div>

              <div>
                <InputSwitch
                  id="initialSync"
                  style={{ marginLeft: "12px" }}
                  checked={initialSyncValue}
                  onChange={e => setInitialSyncValue(e.value)}
                  disabled={disabledButton}
                ></InputSwitch>
              </div>
            </div>
          )}
        </div>

        <div className="field mb-4 col-6">
          <InputMask
            mask="9999/99"
            placeholder={t("placeholderPeriodField")}
            id="period"
            value={periodValue}
            onChange={handlePeriodValueChange}
            disabled={disabledButton}
          />
        </div>

        <div className="field mb-4 col-6">
          <div className="import-action-container">
            <div className="upload-file-container">
              <FileUpload
                mode="basic"
                name="file"
                accept=".csv"
                maxFileSize={selectMaxFileSize}
                customUpload={true}
                chooseLabel={t("fileUploadButton")}
                uploadHandler={importJournal}
                ref={ref}
                disabled={disabledButton}
              />
            </div>
            <div className="cancel-button-container">
              {showCancelButton ? (
                <Button
                  label={t("cancelButton")}
                  onClick={() => {
                    onHandleClearSelectedFile();
                  }}
                  className="p-button-secondary  p-button-outlined  w-auto mr-2 ml-2"
                />
              ) : null}
            </div>

            <div className="spinner-bar-container">
              {showSpinnerBar && (
                <span className="flex" style={{ color: "#64748b" }}>
                  <i
                    className="flex align-items-center justify-content-center pi pi-spin pi-spinner"
                    style={{ fontSize: "2em" }}
                  ></i>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="field mb-2 col-6">
          {showProgressBar && (
            <React.Fragment>
              <span className="font-bold text-xl ">
                {ImportStageMessage(importStage, t)}
              </span>
              <ProgressBar value={progressValue} className="mt-3" />
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
