import React, { useState, useEffect, useCallback, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Link } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";

import CustomConfirmDialog from "../../components/CustomConfirmDialog";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import DataControls from "../../components/DataTools/DataControls";
import DepartmentService from "../../services/DepartmentService";
import { getLocalStorage } from "../../services/LocalStorage";
import CustomFileUpload from "../../components/CustomFileUpload";
import { useLanguageContext } from "../../context/GlobalProvider";
import {
  showErrorToast,
  showSuccessToast,
  showWarnToast,
} from "../../components/CustomToast";
import { actionBodyTemplate } from "../../utils/DataTableAction";

export default function Department(props) {
  const [departmentCode, setDepartmentCode] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [visibleAddDepartmentSidebar, setVisibleAddDepartmentSidebar] =
    useState(false);
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [visibleEditDepartmentSidebar, setVisibleEditDepartmentSidebar] =
    useState(false);
  const [visibleSearchDepartmentSidebar, setVisibleSearchDepartmentSidebar] =
    useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const [visibleImportDepartmentSidebar, setVisibleImportDepartmentSidebar] =
    useState(false);
  const [filters, setFilters] = useState({
    departmentCode: "",
    departmentName: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [departmentCodeList, setDepartmentCodeList] = useState([]);
  const [departmentNameList, setDepartmentNameList] = useState([]);
  const [DepartmentFilterBadges, setDepartmentFilterBadges] = useState([]);
  const [visibleConfirmDialogUpload, setVisibleConfirmDialogUpload] =
    useState(false);
  const toast = useRef(null);
  const { t } = useLanguageContext();

  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(false);

  //for file  upload component
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showSpinnerBar, setShowSpinnerBar] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const loadLazyDepartmentData = useCallback(() => {
    setLoading(true);
    DepartmentService.getDepartmentByHotelId(
      DefaultHotelId,
      lazyParams,
      filters
    ).then((res, error) => {
      setDepartmentData(res.data.departments);
      setTotalRecords(res.data.totalRecords);
      setLoading(false);
    });
  }, [lazyParams, filters, DefaultHotelId]);

  useEffect(() => {
    loadLazyDepartmentData();
  }, [loadLazyDepartmentData]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyDepartmentData();
    }
  }, [loadLazyDepartmentData, props.selectedHotel]);
  const onPage = event => {
    setLazyParams(event);
  };

  function handleButtonClick() {
    setVisibleAddDepartmentSidebar(true);
  }
  function handleImportButtonClick() {
    setVisibleImportDepartmentSidebar(true);
  }

  const uploadDepartmentCSVFile = event => {
    //setVisibleConfirmDialogUpload(true);
    setLoadingUpload(true);
    setShowCancelButton(false);
    setShowSpinnerBar(true);
    setDisabledButton(true);
    const FormData = global.FormData;
    const formData = new FormData();

    formData.append("file", event.files[0]);

    DepartmentService.uploadDepartmentFile(DefaultHotelId, formData)
      .then(res => {
        //setVisibleConfirmDialogUpload(false);
        showSuccessToast(toast, t, "import");
        setLoadingUpload(false);
        setShowCancelButton(true);
        setShowSpinnerBar(false);
        setDisabledButton(false);
      })
      .catch(error => {
        //setVisibleConfirmDialogUpload(false);
        setLoadingUpload(false);
        setShowCancelButton(true);
        setShowSpinnerBar(false);
        setDisabledButton(false);
        showErrorToast(toast, t, error);
      });
  };
  function handleDepartmentCodeChange(e) {
    setDepartmentCode(e.target.value);
  }
  const onSort = event => {
    setLazyParams(event);
  };

  function exportCSVDepartmentsFile() {
    DepartmentService.exportDepartmentFile(DefaultHotelId, filters).then(
      res => {
        showWarnToast(toast, t);
      }
    );
  }
  function handleDepartmentNameChange(e) {
    setDepartmentName(e.target.value);
  }
  function saveDepartment() {
    setClickedButton(true);
    DepartmentService.addDepartmentByHotelId(
      DefaultHotelId,
      departmentCode,
      departmentName
    )
      .then(res => {
        setDepartmentCode("");
        setDepartmentName("");
        loadLazyDepartmentData();
        setClickedButton(false);
        showSuccessToast(toast, t, "add");
        setVisibleAddDepartmentSidebar(false);
      })

      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }
  const customHeaderNewDepartment = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleAddDepartmentSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("newDepartmentSidebarTitle")}
      </div>
    </React.Fragment>
  );

  function getSelectedRow(rowdata) {
    setDepartmentCode(rowdata.departmentCode);
    setDepartmentName(rowdata.departmentName);
  }
  function editDepartment(e) {
    setClickedButton(true);
    e.preventDefault();
    DepartmentService.updateDepartmentByHotelId(
      DefaultHotelId,
      departmentCode,
      departmentName
    )
      .then(res => {
        showSuccessToast(toast, t, "update");
        setClickedButton(false);
        loadLazyDepartmentData();
        setDepartmentCode("");
        setDepartmentName("");
        setVisibleEditDepartmentSidebar(false);
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }

  function deleteDepartment(departmentCode) {
    DepartmentService.deleteDepartmentByDepartmentIdAndHotelId(
      DefaultHotelId,
      departmentCode
    )
      .then(res => {
        setDepartmentData(
          departmentData.filter(
            value => value.departmentCode !== departmentCode
          )
        );
        showSuccessToast(toast, t, "delete");
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }

  function handleSearchButtonClick() {
    setVisibleSearchDepartmentSidebar(true);
  }
  const customHeaderFilterDepartment = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleSearchDepartmentSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>

      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderImportDepartmentFile = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleImportDepartmentSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("importDepartmentFileSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderEditDepartment = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleEditDepartmentSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("editDepartmentSidebarTitle")}
      </div>
    </React.Fragment>
  );
  function handleSelectedDepartmentCodeChange(event) {
    setSelectedDepartmentCode(event.target.value);
  }

  function handleSelectedDepartmentNameChange(event) {
    setSelectedDepartmentName(event.target.value);
  }
  function clearDepartmentInputFilters() {
    setSelectedDepartmentCode("");
    setSelectedDepartmentName("");
  }
  function formReset() {
    setDepartmentCode("");
    setDepartmentName("");

    setVisibleEditDepartmentSidebar(false);
  }
  function filteredDepartmentCode(filterValue) {
    DepartmentService.lovGetDepartmentByHotelId(DefaultHotelId, {
      selectedDepartmentCode: filterValue,
      selectedDepartmentName: "",
      searchMode: "OR",
    }).then(res => {
      setDepartmentCodeList(
        res.data.map(item => ({
          id: item.departmentCode,
        }))
      );
    });
  }
  function filteredDepartmentName(filterValue) {
    DepartmentService.lovGetDepartmentByHotelId(DefaultHotelId, {
      selectedDepartmentCode: "",
      selectedDepartmentName: filterValue,
      searchMode: "AND",
    }).then(res => {
      setDepartmentNameList(
        res.data.map(item => ({
          id: item.departmentName,
        }))
      );
    });
  }
  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];

    if (selectedDepartmentCode.id || selectedDepartmentCode) {
      newFilters.push({
        key: "1",
        filterValue: selectedDepartmentCode.id || selectedDepartmentCode,
        removable: true,
      });
    }
    if (selectedDepartmentName.id || selectedDepartmentName) {
      newFilters.push({
        key: "2",
        filterValue: selectedDepartmentName.id || selectedDepartmentName,
        removable: true,
      });
    }
    setFilters({
      departmentCode: selectedDepartmentCode.id || selectedDepartmentCode,
      departmentName: selectedDepartmentName.id || selectedDepartmentName,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setDepartmentFilterBadges(newFilters);

    setVisibleSearchDepartmentSidebar(false);
  }
  function handleFilterBadgeClear() {
    setDepartmentFilterBadges([]);
    setSelectedDepartmentCode("");
    setSelectedDepartmentName("");

    setFilters({
      departmentCode: "",
      departmentName: "",
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "1":
        setSelectedDepartmentCode("");
        setFilters({
          departmentCode: "",
          departmentName: selectedDepartmentName.id || selectedDepartmentName,
        });
        break;

      case "2":
        setSelectedDepartmentName("");

        setFilters({
          departmentCode: selectedDepartmentCode.id || selectedDepartmentCode,
          departmentName: "",
        });
        break;
      default:
    }
    setDepartmentFilterBadges(current => current.filter(el => el.key !== key));
  }
  function handleVisibleEditSidebar() {
    setVisibleEditDepartmentSidebar(true);
  }
  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <CustomConfirmDialog
        visibleConfirmDialogUpload={visibleConfirmDialogUpload}
      ></CustomConfirmDialog>
      <DataControls
        addButtonLabel={t("addButton")}
        onAddButtonClick={handleButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        onImportFileClick={handleImportButtonClick}
        exportCSV={exportCSVDepartmentsFile}
        filters={DepartmentFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        displayedDataControl="true"
        displayedImportExportDataControl="true"
        displayedImportDataControl="true"
      />
      <DataTable
        value={departmentData}
        className="relative w-full"
        dataKey="departmentCode"
        lazy
        selectionMode="single"
        responsiveLayout="scroll"
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column field="departmentCode" sortable header={t("departmentCode")} />
        <Column field="departmentName" sortable header={t("departmentName")} />
        <Column
          body={rowdata => {
            return actionBodyTemplate(
              rowdata,
              t,
              handleVisibleEditSidebar,
              getSelectedRow,
              rowdata.departmentCode,
              deleteDepartment
            );
          }}
        />
      </DataTable>

      {/* Start- SideBar for add new  department */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderNewDepartment}
        visible={visibleAddDepartmentSidebar}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        position="right"
        onHide={() => setVisibleAddDepartmentSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="departmentCode" className="font-medium text-900">
                {t("departmentCode")}
              </label>
              <InputText
                type="text"
                value={departmentCode}
                onChange={handleDepartmentCodeChange}
                id="departmentCode"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="departmentName" className="font-medium text-900">
                {t("departmentName")}
              </label>
              <InputText
                type="text"
                value={departmentName}
                onChange={handleDepartmentNameChange}
                id="departmentName"
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("saveButton")}
                icon="pi pi-plus"
                onClick={() => saveDepartment()}
                loading={clickedButton}
                className="p-button w-auto p-button-success mr-2"
              />
              <Button
                label={t("cancelButton")}
                onClick={() => setVisibleAddDepartmentSidebar(false)}
                className="p-button-secondary p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>

      {/* Start- SideBar for edit department */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderEditDepartment}
        visible={visibleEditDepartmentSidebar}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        position="right"
        onHide={() => setVisibleEditDepartmentSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="departmentCode" className="font-medium text-900">
                {t("departmentCode")}
              </label>
              <InputText
                type="text"
                disabled
                value={departmentCode}
                onChange={handleDepartmentCodeChange}
                id="departmentCode"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="departmentName" className="font-medium text-900">
                {t("departmentName")}
              </label>
              <InputText
                type="text"
                value={departmentName}
                onChange={handleDepartmentNameChange}
                id="departmentName"
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("updateButton")}
                onClick={editDepartment}
                loading={clickedButton}
                className="p-button w-auto p-button-success mr-2"
              />
              <Button
                label={t("cancelButton")}
                onClick={() => formReset()}
                className="p-button-secondary p-button-outlined  w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      {/* Start- SideBar for filter  department */}
      <Sidebar
        showCloseIcon={false}
        icons={customHeaderFilterDepartment}
        id="sidebar-filter"
        visible={visibleSearchDepartmentSidebar}
        position="right"
        className="p-sidebar-md	"
        onHide={() => setVisibleSearchDepartmentSidebar(false)}
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedDepartmentCode}
                  options={departmentCodeList}
                  filterList={filteredDepartmentCode}
                  onChange={handleSelectedDepartmentCodeChange}
                  placeholder={t("placeholderDepartmentCodeField")}
                  dropdown={false}
                  name="selectedDepartmentCode"
                  inputId="selectedDepartmentCode"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedDepartmentName}
                  options={departmentNameList}
                  filterList={filteredDepartmentName}
                  onChange={handleSelectedDepartmentNameChange}
                  placeholder={t("placeholderDepartmentNameField")}
                  dropdown={false}
                  name="selectedDepartmentName"
                  inputId="selectedDepartmentName"
                ></CustomAutoComplete>
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label={t("findButton")}
                  className="p-button p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => clearDepartmentInputFilters()}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={() => setVisibleSearchDepartmentSidebar(false)}
                  className="p-button-secondary  p-button-outlined w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
      {/* Start- SideBar for Import  department */}
      <Sidebar
        showCloseIcon={false}
        icons={customHeaderImportDepartmentFile}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleImportDepartmentSidebar}
        position="right"
        onHide={() => setVisibleImportDepartmentSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12" style={{ textAlign: "right" }}>
              <FaFileDownload style={{ color: "blue" }} />
              <Link
                to="../../../assets/files/Department.csv"
                target="_blank"
                download
              >
                {t("titleDepartmentDownloadLink")}
              </Link>
            </div>
            <div className="field mb-4 col-12">
              <label> {t("labelImportFile")} : </label>
              <CustomFileUpload
                uploadCSVFile={uploadDepartmentCSVFile}
                loadingUpload={loadingUpload}
                showCancelButton={showCancelButton}
                showSpinnerBar={showSpinnerBar}
                disabledButton={disabledButton}
              />
            </div>
          </div>
        </div>
      </Sidebar>

      <ConfirmDialog />
    </React.Fragment>
  );
}
