import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Toolbar } from "primereact/toolbar";
import { Link } from "react-router-dom";

import UserService from "../../services/UserService";
import {
  clearLocalStorage,
  getLocalStorage,
} from "../../services/LocalStorage";
import DataControls from "../../components/DataTools/DataControls";
import Input from "../../components/Input";
import LanguageSelect from "../../components/LanguageSelect";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast, showSuccessToast } from "../../components/CustomToast";

export default function UserProfile() {
  const { t, language } = useLanguageContext();

  const navigate = useNavigate();
  const [password] = useState(getLocalStorage("password"));
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(false);

  const toast = useRef(null);

  const [user, setUser] = useState({
    userName: getLocalStorage("username"),
    firstName: getLocalStorage("firstName"),
    lastName: getLocalStorage("lastName"),
    apiUserStatus: "",
    apiUserRoleIds: [],
  });

  useEffect(() => {}, [language]);
  const handleInput = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const newPasswordChangeHandler = event => {
    setNewPassword(event.target.value);
  };
  const confirmNewPasswordChangeHandler = event => {
    setConfirmNewPassword(event.target.value);
  };

  const updateUser = async e => {
    setClickedButton(true);
    e.preventDefault();

    try {
      await UserService.updateUser(
        getLocalStorage("userId"),
        user.userName,
        newPassword,
        confirmNewPassword,
        user.firstName,
        user.lastName,
        user.apiUserStatus,
        getLocalStorage("defaultHotelId"),
        language,
        user.apiUserRoleIds
      );
      showSuccessToast(toast, t, "update");
      setClickedButton(false);

      setTimeout(navigate, 4000, "/", clearLocalStorage());
    } catch (error) {
      setClickedButton(false);
      showErrorToast(toast, t, error);
    }
  };
  const leftContents = (
    <React.Fragment>
      <React.Fragment>
        <Link to="/LandingPage">
          <img
            className="cursor-pointer"
            src="../assets/img/operandi-logo.png"
            alt="operandi logo"
            style={{ width: "100%", marginLeft: "3%" }}
          />
        </Link>
      </React.Fragment>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Toolbar
        left={leftContents}
        className="overflow-hidden backNavigation-toolbar"
      />
      <Toast ref={toast} className="toast" />
      <DataControls displayedImportExportDataControl="false" />

      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="grid">
          <div className="col-12 lg:col-2">
            <div className="text-900 font-medium text-xl mb-3">
              {t("profileTitle")}
            </div>

            <div className="flex align-items-center">
              {/* <img
                src="assets/images/blocks/flag/flag_placeholder.png"
                alt={"exple"}
              /> */}
            </div>
          </div>
          <div className="col-12 lg:col-10">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="firstName" className="font-medium text-900">
                  {t("firstNameLabelField")}
                </label>
                <Input
                  type="text"
                  name="firstName"
                  value={user.firstName}
                  handleInput={handleInput}
                  id="firstName"
                ></Input>
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="lastName" className="font-medium text-900">
                  {t("lastNameLabelField")}
                </label>
                <Input
                  type="text"
                  name="lastName"
                  value={user.lastName}
                  handleInput={handleInput}
                  id="lastName"
                ></Input>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="userName" className="font-medium text-900">
                  {t("userNameLabelField")}
                </label>
                <Input
                  type="text"
                  name="userName"
                  value={user.userName}
                  handleInput={handleInput}
                  id="userName"
                ></Input>
              </div>

              <React.Fragment>
                <div className="field mb-4 col-12 md:col-6">
                  <label htmlFor="newPassword" className="font-medium text-900">
                    {t("newPasswordLabelField")}
                  </label>
                  <Password
                    inputId="newPassword"
                    placeholder={t("placeholderNewPasswordField")}
                    value={newPassword}
                    onChange={newPasswordChangeHandler}
                    toggleMask
                  />
                </div>
                <div className="field mb-4 col-12 md:col-6">
                  <label
                    htmlFor="confirmPassword"
                    className="font-medium text-900"
                  >
                    {t("confirmNewPasswordLabelField")}
                  </label>
                  <Password
                    inputId="confirmPassword"
                    placeholder={t("placeholderConfirmNewPassword")}
                    value={confirmNewPassword}
                    onChange={confirmNewPasswordChangeHandler}
                    toggleMask
                  />
                </div>
              </React.Fragment>

              <div className="field mb-4 col-12">
                <label htmlFor="language" className="font-medium text-900">
                  {t("languageLabelField")}
                </label>
                <div className="flex align-items-center">
                  <LanguageSelect />
                </div>
              </div>
              <div className="col-12">
                <Button
                  label={t("updateButton")}
                  onClick={updateUser}
                  loading={clickedButton}
                  className="p-button w-auto p-button-success w-auto mt-3 mr-2"
                />
                <Link to="/LandingPage">
                  <Button
                    label={t("cancelButton")}
                    className="p-button-secondary  p-button-outlined w-auto w-auto mt-3"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
