import api from "./ApiService";

class KpiService {
  getKpisByHotelId(hotelId, lazyParams, kpisfiltersValue) {
    return api.get(hotelId + "/kpis", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        name: kpisfiltersValue.name,
        description: kpisfiltersValue.description,
        type: kpisfiltersValue.type,
      },
    });
  }
  lovGetKpisByHotelId(hotelId, filters) {
    return api.get(hotelId + "/kpis/lov", {
      params: {
        name: filters.selectedKpiName,
        description: filters.selectedKpiDescription,
      },
    });
  }

  deleteKpiByIdAndHotelId(hotelId, id) {
    return api.delete(hotelId + "/kpis/" + id);
  }
  updateKpisByHotelId(
    hotelId,
    kpiId,
    name,
    description,
    type,
    formula,
    value,
    numberFormat,
    decimalPlaces,
    isExpense,
    percentageType,
    sort,
    isDashboardKpi,
    isActive,
    kpiAccountDepartmentPayloads,
    groupKpiId
  ) {
    return api.put(hotelId + "/kpis/update", {
      kpiId: kpiId,
      name: name,
      description: description,
      type: type,
      formula: formula,
      value: value,
      numberFormat: numberFormat,
      decimalPlaces: decimalPlaces,
      isExpense: isExpense,
      percentageType: percentageType,
      sort: sort,
      isDashboardKpi: isDashboardKpi,
      isActive: isActive,
      kpiAccountDepartmentPayloads: kpiAccountDepartmentPayloads,
      groupKpiId: groupKpiId,
    });
  }
  addKpisByHotelId(
    hotelId,
    name,
    description,
    type,
    formula,
    value,
    numberFormat,
    decimalPlaces,
    isExpense,
    percentageType,
    sort,
    isDashboardKpi,
    isActive,
    kpiAccountDepartmentPayloads,
    groupKpiId
  ) {
    return api.post(hotelId + "/kpis/add", {
      name: name,
      description: description,
      type: type,
      formula: formula,
      value: value,
      numberFormat: numberFormat,
      decimalPlaces: decimalPlaces,
      isExpense: isExpense,
      percentageType: percentageType,
      sort: sort,
      isDashboardKpi: isDashboardKpi,
      isActive: isActive,
      kpiAccountDepartmentPayloads: kpiAccountDepartmentPayloads,
      groupKpiId: groupKpiId,
    });
  }

  addkpiAccountDepartmentByHotelId(
    hotelId,
    kpiId,
    KpiAccountDepartmentPayload
  ) {
    return api.post(
      hotelId + "/kpi_account_departments/refresh",
      JSON.stringify(KpiAccountDepartmentPayload),
      {
        params: { kpiId: kpiId },
      }
    );
  }
  deletekpiAccountDepartmentByHotelId(hotelId, kpiId, accountId, departmentId) {
    return api.delete(hotelId + "/kpi_account_departments", {
      params: {
        kpiId: kpiId,
        accountId: accountId,
        departmentId: departmentId,
      },
    });
  }

  getkpiAccountDepartmentByHotelId(hotelId, kpiId) {
    return api.get(hotelId + "/kpi_account_departments", {
      params: {
        kpiId: kpiId,
      },
    });
  }

  getKpisDependencyItem(hotelId, accountDepartment, kpiName) {
    return api.get(hotelId + "/kpis/dependency", {
      params: {
        accountDepartment: accountDepartment,
        name: kpiName,
      },
    });
  }
}
export default new KpiService();
