import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactDOM } from "react";
import {
  FaRegFileExcel,
  FaRegBuilding,
  FaMoneyCheckAlt,
  FaArchive,
  FaFileImport,
  FaClone,
  FaBookOpen,
  FaBorderAll,
  FaCalculator,
  FaCompass,
  FaFileExport,
  FaTable,
  FaSitemap,
} from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import classNames from "classnames";

import BackNavigation from "./BackNavigation/BackNavigation";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "../services/LocalStorage";
import { useLanguageContext } from "../context/GlobalProvider";
import TransactionService from "../services/TransactionService";
import ComputeKpisService from "../services/ComputeKpisService";
import { CustomDateFormat } from "../utils/CustomDateFormat";
import ApiHttpTraceService from "../services/ApiHttpTraceService";
import { showErrorToast } from "../components/CustomToast";
import CustomDialog from "../components/CustomDialog";

export default function LandingPage(props) {
  const [leftMenuState, setLeftMenuState] = useState(false);
  const [lastImportDate, setLastImportDate] = useState("");
  const [lastComputeDate, setLastComputeDate] = useState("");
  const [visibleLastImportLogDialog, setVisibleLastImportLogDialog] =
    useState(false);
  const [visibleLastComputeLogDialog, setVisibleLastComputeLogDialog] =
    useState(false);

  //
  const { t } = useLanguageContext();

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });

  //Params for datatable
  const [selectedLogData, setSelectedLogData] = useState(null);
  const [loadingImportLogData, setLoadingImportLogData] = useState(false);
  const [loadingComputeLogData, setLoadingComputeLogData] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const onPage = event => {
    setLazyParams(event);
  };
  const onSort = event => {
    setLazyParams(event);
  };

  //Initial last import  list
  const [importLogList, setImportLogList] = useState([]);

  //Initial last import  list
  const [computeLogList, setComputeLogList] = useState([]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      clearLocalStorage();
    }
  }, []);

  useEffect(() => {
    TransactionService.getLastImportDate(
      getLocalStorage("defaultHotelId")
    ).then(res => {
      setLastImportDate(res.data);
    });

    ComputeKpisService.getLastComputeDate(
      getLocalStorage("defaultHotelId")
    ).then(res => {
      setLastComputeDate(res.data);
    });
  }, []);

  function handleLeftMenuState(visibleLeft) {
    setLeftMenuState(visibleLeft);
  }

  //Get last import Log
  function getLastImportLog() {
    setVisibleLastImportLogDialog(true);
    setLoadingImportLogData(true);
    ApiHttpTraceService.getApiHttpTrace(lazyParams, {
      method: "",
      uri: "transactions/import",
      responseStatusCode: "",
      requestBody: "",
      requestDate: "",
      apiUserName: "",
    })
      .then(res => {
        setImportLogList(res.data.httpTraces);
        setTotalRecords(res.data.totalRecords);
        setLoadingImportLogData(false);
      })
      .catch(error => {
        showErrorToast(t, error);
        setLoadingImportLogData(false);
      });
  }

  // Get last Compute log
  function getLastComputeLog() {
    setLoadingComputeLogData(true);
    setVisibleLastComputeLogDialog(true);

    const uri = "compute_kpi/collection";
    ApiHttpTraceService.getApiHttpTrace(lazyParams, {
      method: "",
      uri: uri,
      responseStatusCode: "",
      requestBody: "",
      requestDate: "",
      apiUserName: "",
    })
      .then(res => {
        setComputeLogList(res.data.httpTraces);
        setTotalRecords(res.data.totalRecords);
        setLoadingComputeLogData(false);
      })
      .catch(error => {
        showErrorToast(t, error);
        setLoadingComputeLogData(false);
      });
  }

  //Set  pagination
  useEffect(() => {
    if (visibleLastImportLogDialog) {
      getLastImportLog();
    }
  }, [visibleLastImportLogDialog, lazyParams]);

  useEffect(() => {
    if (visibleLastComputeLogDialog) {
      getLastComputeLog();
    }
  }, [visibleLastComputeLogDialog, lazyParams]);
  const statusBodyTemplate = rowData => {
    const status = rowData.responseStatusCode >= 400 ? "Failed" : "Success";
    const statusClassName = classNames("p-badge", {
      "p-badge-danger": status === "Failed",
      "p-badge-success": status === "Success",
    });

    return <span className={statusClassName}>{status}</span>;
  };

  //Import  Log dialog Content
  const dialogContentLastImportLog = () => {
    return (
      <React.Fragment>
        <DataTable
          className="relative w-full"
          value={importLogList}
          lazy
          selectionMode="single"
          selection={selectedLogData}
          onPage={onPage}
          onSort={onSort}
          loading={loadingImportLogData}
          onSelectionChange={e => setSelectedLogData(e.value)}
          responsiveLayout="scroll"
          breakpoint="960px"
          dataKey="id"
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={[10, 25, 50, 100]}
          first={lazyParams.first}
          rows={lazyParams.rows}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          totalRecords={totalRecords}
          emptyMessage={t("datatableEmptyMessage")}
        >
          <Column field="apiUserName" sortable header={t("name")}></Column>

          <Column
            field="requestDate"
            sortable
            header={t("date")}
            dataType="date"
            body={dateBodyTemplate}
          />
          <Column
            field="requestStatusCode"
            sortable
            header={t("status")}
            body={statusBodyTemplate}
          />
        </DataTable>
      </React.Fragment>
    );
  };
  //Compute  Log dialog Content
  const dialogContentLastComputLog = () => {
    return (
      <React.Fragment>
        <DataTable
          className="relative w-full"
          value={computeLogList}
          lazy
          selectionMode="single"
          selection={selectedLogData}
          onPage={onPage}
          onSort={onSort}
          loading={loadingComputeLogData}
          onSelectionChange={e => setSelectedLogData(e.value)}
          responsiveLayout="scroll"
          breakpoint="960px"
          dataKey="id"
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={[10, 25, 50, 100]}
          first={lazyParams.first}
          rows={lazyParams.rows}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          totalRecords={totalRecords}
          emptyMessage={t("datatableEmptyMessage")}
        >
          <Column field="apiUserName" sortable header={t("name")}></Column>

          <Column
            field="requestDate"
            sortable
            header={t("date")}
            dataType="date"
            body={dateBodyTemplate}
          />
          <Column
            field="requestStatusCode"
            sortable
            header={t("status")}
            body={statusBodyTemplate}
          />
        </DataTable>
      </React.Fragment>
    );
  };

  //Hide import Log Dialog
  function handleHideImportLogDialog() {
    setVisibleLastImportLogDialog(false);
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
  }

  //Hide Compute Log Dialog
  function handleHideComputeLogDialog() {
    setVisibleLastComputeLogDialog(false);
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
  }
  const dateBodyTemplate = rowData => {
    return CustomDateFormat(rowData.requestDate, "en-US");
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: leftMenuState ? "255px" : "" }}>
        <BackNavigation
          onLeftMenuStateChange={handleLeftMenuState}
          leftMenuState={leftMenuState}
        />
        {/* Import log dialog */}
        <CustomDialog
          visibleDialog={visibleLastImportLogDialog}
          dialogContent={dialogContentLastImportLog()}
          headerTitle={t("importLogDialogHeaderTitle")}
          onHideDialog={handleHideImportLogDialog}
        ></CustomDialog>

        {/* Compute log dialog */}
        <CustomDialog
          visibleDialog={visibleLastComputeLogDialog}
          dialogContent={dialogContentLastComputLog()}
          headerTitle={t("computeLogDialogHeaderTitle")}
          onHideDialog={handleHideComputeLogDialog}
        ></CustomDialog>

        <div className="landing-page  border-round surface-card p-8">
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/Accounts"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaRegFileExcel />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_1")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/Departments"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaRegBuilding />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_2")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 md:col-6 xl:col-4 p-3">
              <Link
                to="/ControlPanel/Budget"
                className="flex flex-column h-full  card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center transition-ease-out hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="flex flex-row flex-wrap card-container blue-container surface-border pb-3 ">
                    <span
                      className="custom-menu-item flex align-items-center justify-content-center text-7xl"
                      style={{ width: "90px", height: "90px" }}
                    >
                      <FaMoneyCheckAlt />
                    </span>

                    <span className="flex align-items-center justify-content-center text-800 text-xl font-bold">
                      {t("menuItem_4")}
                    </span>
                    <div></div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3"
              onClick={() => getLastImportLog()}
            >
              <div className="h-full  flex flex-column card-container no-underline">
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaFileImport />
                      </div>
                      {/* <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_11")} :{lastImportDate}
                      </span> */}
                      <div className="text-800 text-xl  font-bold">
                        {t("menuItem_11")}
                      </div>
                      <div
                        className="text-l  font-bold"
                        style={{ color: "#22c55e" }}
                      >
                        {CustomDateFormat(lastImportDate, "en-US")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3"
              onClick={() => getLastComputeLog()}
            >
              <div className="h-full  flex flex-column card-container no-underline">
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaClone />
                      </div>
                      {/* <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_14")}:{lastComputeDate}
                      </span> */}
                      <div className="text-800 text-xl  font-bold">
                        {t("menuItem_14")}
                      </div>
                      <div
                        className="text-l  font-bold"
                        style={{ color: "#22c55e" }}
                      >
                        {CustomDateFormat(lastComputeDate, "en-US")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-3">
              <Link
                to="/ControlPanel/BudgetVersions"
                className="flex flex-column  h-full card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center transition-ease-out hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="flex flex-row flex-wrap card-container blue-container surface-border pb-3">
                    <span
                      className="custom-menu-item flex align-items-center justify-content-center text-7xl"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <FaArchive />
                    </span>

                    <span className="flex align-items-center justify-content-center text-800 text-xl font-bold">
                      {t("menuItem_3")}
                    </span>
                    <div></div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/Transactions"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaBookOpen />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_5")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/Memos"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaBorderAll />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_6")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 md:col-6 xl:col-4 p-3">
              <Link
                to="/ControlPanel/ComputeKpi"
                className="flex flex-column h-full  card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center transition-ease-out hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="flex flex-row flex-wrap card-container blue-container surface-border pb-3">
                    <span
                      className="custom-menu-item flex align-items-center justify-content-center text-7xl"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <FaCalculator />
                    </span>

                    <span className="flex align-items-center justify-content-center text-800 text-xl font-bold">
                      {t("menuItem_10")}
                    </span>
                    <div></div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/Kpis"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaCompass />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_9")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/ListKPIsMapping"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaSitemap />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_13")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 md:col-6 xl:col-4 p-3">
              <Link
                to="/ControlPanel/UploadJournal"
                className="flex flex-column h-full  card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center h-full transition-ease-out hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="flex flex-row flex-wrap card-container blue-container surface-border pb-3">
                    <span
                      className="custom-menu-item flex align-items-center justify-content-center text-7xl"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <FaFileImport />
                    </span>

                    <span className="flex align-items-center justify-content-center text-800 text-xl font-bold">
                      {t("menuItem_8")}
                    </span>
                    <div></div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/ConcordanceReport"
                className=" h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaFileExport />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_15")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 sm:col-6 lg:col-3 xl:col-2 p-3">
              <Link
                to="/ControlPanel/KPIsAssignmentTable"
                className="h-full  flex flex-column card-container no-underline"
              >
                <div className="flex align-items-center justify-content-center cursor-pointer h-full hover:border-500 surface-overlay border-3 border-round font-bold surface-card shadow-2 border-rounded p-5 transition-ease-out border-1 border-200">
                  <div className="surface-border">
                    <div className="text-center">
                      <div className="landing-page-menu-item  cursor-pointer text-600 text-5xl border-200">
                        <FaTable />
                      </div>
                      <span className="text-800 text-xl mb-3 font-bold">
                        {t("menuItem_12")}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
