import React from "react";
import { useRef } from "react";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useLanguageContext } from "../../context/GlobalProvider";

export default function DataOptions(props) {
  const toast = useRef(null);
  const { t } = useLanguageContext();
  const items = [
    {
      label: "Import",
      icon: "pi pi-plus",

      template: (item, options) => {
        return (
          /* custom element */
          props.displayedImportDataControl === "true" && (
            <Button
              label={t("importButton")}
              className="hover:gray-300 import-export-button"
              icon="pi pi-plus"
              onClick={() => {
                props.onImportFileClick();
              }}
            ></Button>
          )
        );
      },
    },
    {
      label: "Export",
      icon: "pi pi-upload",
      template: (item, options) => {
        return (
          /* custom element */
          <Button
            className="hover:gray-300 import-export-button"
            label={t("exportButton")}
            icon="pi pi-upload"
            onClick={props.exportCsvFile}
          ></Button>
        );
      },
    },
  ];

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div>
        <Menu
          //className="menu-data-options"
          model={items}
          popup
          ref={props.menu}
          //id="popup_menu"
        />
      </div>
    </div>
  );
}
