import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { FaRedo } from "react-icons/fa";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import DataControls from "../../components/DataTools/DataControls";
import CustomAutoComplet from "../../components/CustomAutoComplete";
import GroupKpisServices from "../../services/GroupKpisServices";
import { getLocalStorage } from "../../services/LocalStorage";

export default function GroupKpis(props) {
  const [groupKpisData, setGroupKpisData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visibleAddGroupKpisSidebar, setVisibleAddGroupKpisSidebar] =
    useState(false);
  const [visibleEditGroupKpisSidebar, setVisibleEditGroupKpisSidebar] =
    useState(false);
  const [visibleFilterGroupKpisSidebar, setVisibleFilterGroupKpisSidebar] =
    useState(false);
  const [kpiGroupName, setKPIGroupName] = useState("");
  const [kpiGroupSort, setKPIGroupSort] = useState("");
  const [kpiGroupId, setKPIGroupId] = useState("");
  const [selectedGroupKpisName, setSelectedGroupKpisName] = useState("");
  const [selectedGroupKpisId, setSelectedGroupKpisId] = useState("");
  const [groupKpisIdList, setGroupKpisIdList] = useState([]);
  const [groupKpisNameList, setGroupKpisNameList] = useState([]);
  const [groupKpisFilterBadges, setGroupKpisFilterBadges] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 1,
    rows: 5,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const toast = useRef(null);
  const onSort = event => {
    setLazyParams(event);
  };
  const onPage = event => {
    setLazyParams(event);
  };
  const [filters, setFilters] = useState({
    groupKpiId: "",
    name: "",
  });
  const loadLazyGroupKpiData = useCallback(() => {
    setLoading(true);
    GroupKpisServices.getGroupKpisByHotelId(
      DefaultHotelId,
      lazyParams,
      filters
    ).then(res => {
      setGroupKpisData(res.data.groupKpis);
      setTotalRecords(res.data.totalRecords);
      setLoading(false);
    });
  }, [DefaultHotelId, lazyParams, filters]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyGroupKpiData();
    }
  }, [loadLazyGroupKpiData, props.selectedHotel]);

  useEffect(() => {
    loadLazyGroupKpiData();
  }, [loadLazyGroupKpiData]);

  const customHeaderNewGroupKpi = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleAddGroupKpisSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        New Group Kpis
      </div>
    </React.Fragment>
  );
  const customHeaderEditGroupKpis = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => formReset()}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        Edit Group Kpis
      </div>
    </React.Fragment>
  );
  const customHeaderFilterGroupKpis = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleFilterGroupKpisSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div>
        <button
          type="button"
          className="p-sidebar-icon p-link"
          aria-label="refresh"
        >
          <FaRedo
            onClick={() => {
              clearFilters();
            }}
          />
        </button>
      </div>
      <div className="text-900 font-bold text-900 text-xl ml-3">Filter</div>
    </React.Fragment>
  );
  function handleSelectedgroupKpisNameChange(e) {
    setSelectedGroupKpisName(e.target.value);
  }

  function handleKpiGroupNameChange(event) {
    setKPIGroupName(event.target.value);
  }

  function handleKpiGroupSortChange(event) {
    setKPIGroupSort(event.target.value);
  }
  function handleKpiGroupIdChange(event) {
    setKPIGroupId(event.target.value);
  }

  function handleButtonClick() {
    setVisibleAddGroupKpisSidebar(true);
  }
  function handleSearchButtonClick() {
    setVisibleFilterGroupKpisSidebar(true);
  }

  function saveGroupKpis() {
    GroupKpisServices.addGroupKpisByHotelId(
      DefaultHotelId,
      kpiGroupId,
      kpiGroupName,
      kpiGroupSort
    )
      .then(res => {
        setKPIGroupName("");
        setKPIGroupSort("");
        loadLazyGroupKpiData();
        toast.current.show({
          severity: "success",
          summary: "Add ",
          detail: "successfully added",
          life: 200000,
        });
        setVisibleAddGroupKpisSidebar(false);
      })
      .catch(error => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: error.response.data.message,
          life: 8000,
        });
      });
  }
  function editGroupKpis(e) {
    e.preventDefault();
    GroupKpisServices.updateGroupKpisByHotelId(
      DefaultHotelId,
      kpiGroupId,
      kpiGroupName,
      kpiGroupSort
    )
      .then(res => {
        const updatedGroupKpis = res.data;
        console.log(updatedGroupKpis);
        toast.current.show({
          severity: "success",
          summary: "update",
          detail: "successfully updated",
          life: 200000,
        });
        loadLazyGroupKpiData();
        setVisibleEditGroupKpisSidebar(false);
        setKPIGroupName("");
        setKPIGroupSort("");
      })
      .catch(error => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: error.response.data.message,
          life: 8000,
        });
      });
  }
  function getSelectedRow(rowdata) {
    setKPIGroupName(rowdata.name);
    setKPIGroupSort(rowdata.sort);
    setKPIGroupId(rowdata.groupKpiId.groupKpiId);
  }

  function deleteGroupKpi(groupKipiId) {
    GroupKpisServices.deleteGroupKpiByHotelIdAndId(DefaultHotelId, groupKipiId)
      .then(res => {
        setGroupKpisData(
          groupKpisData.filter(
            value => value.groupKpiId.groupKpiId !== groupKipiId
          )
        );
        toast.current.show({
          severity: "success",
          summary: "delete",
          detail: "successfully deleted",
          life: 1000,
        });
      })
      .catch(error => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: error.response.data.message,
          life: 8000,
        });
      });
  }
  function handleSelectedgroupKpisIdChange(e) {
    setSelectedGroupKpisId(e.target.value);
  }

  function confirmDeleteGroupKpi(groupKipiId) {
    confirmDialog({
      message: "Are you sure you want delete selected accounts ?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteGroupKpi(groupKipiId),
    });
  }
  function formReset() {
    setKPIGroupId(0);
    setKPIGroupName("");
    setKPIGroupSort(0);
    setVisibleEditGroupKpisSidebar(false);
  }

  function filteredGroupKpisId(filterValue) {
    GroupKpisServices.LovGetGroupKpiByHotelId(DefaultHotelId, {
      selectedGroupKpisName: "",
      selectedGroupKpiId: filterValue,
      searchMode: "AND",
    }).then(res => {
      setGroupKpisIdList(
        res.data.map(item => ({
          id: item.groupKpiId,
        }))
      );
    });
  }
  function filteredGroupKpisName(filterValue) {
    GroupKpisServices.LovGetGroupKpiByHotelId(DefaultHotelId, {
      selectedGroupKpisName: filterValue,
      selectedGroupKpiId: "",
      searchMode: "AND",
    }).then(res => {
      setGroupKpisNameList(
        res.data.map(item => ({
          id: item.name,
        }))
      );
    });
  }
  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];

    if (selectedGroupKpisId.id) {
      newFilters.push({
        key: "1",
        filterValue: selectedGroupKpisId.id,
        removable: true,
      });
    }

    if (selectedGroupKpisName.id) {
      newFilters.push({
        key: "2",
        filterValue: selectedGroupKpisName.id,
        removable: true,
      });
    }
    setFilters({
      groupKpiId: selectedGroupKpisId.id,
      name: selectedGroupKpisName.id,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setGroupKpisFilterBadges(newFilters);

    setVisibleFilterGroupKpisSidebar(false);
  }
  function handleFilterBadgeClear() {
    setGroupKpisFilterBadges([]);
    setSelectedGroupKpisId("");
    setSelectedGroupKpisName("");

    setFilters({
      groupKpiId: "",
      name: "",
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "1":
        setSelectedGroupKpisId("");
        setFilters({
          groupKpiId: "",
          name: "",
        });
        break;

      default:
        setSelectedGroupKpisName("");
        setFilters({
          groupKpiId: "",
          name: "",
        });
    }
    setGroupKpisFilterBadges(current => current.filter(el => el.key !== key));
  }
  function clearFilters() {
    setFilters({
      groupKpiId: "",
      name: "",
    });
    setSelectedGroupKpisId("");
    setSelectedGroupKpisName("");
  }

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <DataControls
        addButtonLabel="Add"
        onAddButtonClick={handleButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        filters={groupKpisFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        displayedImportExportDataControl="displayed"
      />
      <DataTable
        value={groupKpisData}
        className="relative w-full"
        lazy
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
        responsiveLayout="scroll"
        totalRecords={totalRecords}
      >
        <Column field="name" sortable header="KPI Group Name" />
        <Column field="sort" sortable header="KPI Group Sort" />
        <Column
          body={rowdata => {
            return (
              <div style={{ width: "100%" }}>
                <div id="setup-for-account-datatable">
                  <i
                    tooltip="Delete"
                    className="pi pi pi-pencil p-toolbar-separator mr-2"
                    onClick={() => {
                      setVisibleEditGroupKpisSidebar(true);
                      getSelectedRow(rowdata);
                    }}
                  />
                  <i
                    id="delete-icons"
                    className="pi pi-trash"
                    onClick={() =>
                      confirmDeleteGroupKpi(rowdata.groupKpiId.groupKpiId)
                    }
                  />
                </div>
              </div>
            );
          }}
        />
      </DataTable>
      {/* Start- SideBar for add new group kpis */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderNewGroupKpi}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleAddGroupKpisSidebar}
        position="right"
        onHide={() => setVisibleAddGroupKpisSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="KPIGroupCode" className="font-medium text-900">
                KPI Group Code
              </label>
              <InputText
                value={kpiGroupId}
                onChange={handleKpiGroupIdChange}
                type="text"
              />
            </div>

            <div className="field mb-4 col-12">
              <label htmlFor="KPIGroupName" className="font-medium text-900">
                KPI Group Name
              </label>
              <InputText
                value={kpiGroupName}
                onChange={handleKpiGroupNameChange}
                type="text"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="KPIGroupSort" className="font-medium text-900">
                KPI Group Sort
              </label>
              <InputText
                type="text"
                value={kpiGroupSort}
                onChange={handleKpiGroupSortChange}
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label="Save"
                onClick={() => saveGroupKpis()}
                className="p-button w-auto p-button-success  mr-2"
              />
              <Button
                label="Cancel"
                onClick={() => setVisibleAddGroupKpisSidebar(false)}
                className="p-button-secondary  p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>

      {/* Start- SideBar for edit group kpis */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderEditGroupKpis}
        visible={visibleEditGroupKpisSidebar}
        position="right"
        className="p-sidebar-md	"
        onHide={() => setVisibleEditGroupKpisSidebar(false)}
        id="sidebar-for-add-account"
      >
        <div className="surface-card p-0  border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="kpiGroupId" className="font-medium text-900">
                KPI Group Id
              </label>
              <InputText
                value={kpiGroupId}
                onChange={handleKpiGroupIdChange}
                type="text"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="kpiGroupName" className="font-medium text-900">
                KPI Group Name
              </label>
              <InputText
                value={kpiGroupName}
                onChange={handleKpiGroupNameChange}
                type="text"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="kpiGroupSort" className="font-medium text-900">
                KPI Group Sort
              </label>
              <InputText
                type="text"
                value={kpiGroupSort}
                onChange={handleKpiGroupSortChange}
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label="Update"
                onClick={editGroupKpis}
                className="p-button p-button-success w-auto mr-2"
              />

              <Button
                label="Cancel"
                onClick={() => formReset()}
                className="p-button-secondary  p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      {/* Start- SideBar for filter group kpis */}
      <Sidebar
        showCloseIcon={false}
        blockScroll="true"
        icons={customHeaderFilterGroupKpis}
        id="sidebar-filter"
        visible={visibleFilterGroupKpisSidebar}
        position="right"
        className="p-sidebar-md	"
        onHide={() => setVisibleFilterGroupKpisSidebar(false)}
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label>KPI Group ID</label>
                <CustomAutoComplet
                  value={selectedGroupKpisId}
                  options={groupKpisIdList}
                  filterList={filteredGroupKpisId}
                  onChange={handleSelectedgroupKpisIdChange}
                  placeholder="Select a Group Kpi Id"
                  dropdown={false}
                ></CustomAutoComplet>
              </div>

              <div className="field mb-4 col-12">
                <label>KPI Group Name</label>
                <CustomAutoComplet
                  value={selectedGroupKpisName}
                  options={groupKpisNameList}
                  filterList={filteredGroupKpisName}
                  onChange={handleSelectedgroupKpisNameChange}
                  placeholder="Select a Group Kpi Name"
                  dropdown={false}
                ></CustomAutoComplet>
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label="Find"
                  className="p-button p-button-success mr-2 w-auto"
                />

                <Button
                  label="Cancel"
                  onClick={() => setVisibleFilterGroupKpisSidebar(false)}
                  className="p-button-secondary p-button-outlined   w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>

      <ConfirmDialog />
    </React.Fragment>
  );
}
