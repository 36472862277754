import api from "./ApiService";
import fileDownload from "js-file-download";

class AccountService {
  getAccountByHotelId(hotelId, lazyParams, filters) {
    return api.get(hotelId + "/accounts", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        accountCode: filters.accountCode,
        accountName: filters.accountName,
        accountType: filters.accountType,
        searchMode: filters.searchMode,
      },
    });
  }

  lovGetAccountByHotelId(hotelId, filters) {
    return api.get(hotelId + "/accounts/lov", {
      params: {
        accountCode: filters.selectedAccountCode,
        accountName: filters.selectedAccountName,
        accountType: filters.selectedAccountType,
      },
    });
  }
  getAccountByAccountIdAndHotelId(hotelId, accountId) {
    return api.get(hotelId + "/accounts/" + accountId);
  }
  addAccountByHotelId(hotelId, accountCode, accountName, accountType) {
    return api.post(hotelId + "/accounts/add", {
      accountCode: accountCode,
      accountName: accountName,
      accountType: accountType,
    });
  }

  updateAccountByHotelId(hotelId, accountCode, accountName, accountType) {
    return api.put(hotelId + "/accounts/update", {
      accountCode: accountCode,
      accountName: accountName,
      accountType: accountType,
    });
  }
  deleteAccountByHotelIdAndId(hotelId, id) {
    return api.delete(hotelId + "/accounts/" + id);
  }

  uploadAccountFile(hotelId, formData) {
    return api
      .post(hotelId + "/accounts/import", formData)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      });
  }
  exportAccountFile(hotelId, filters, lazyParams) {
    return api
      .get(
        hotelId + "/accounts/export",
        {
          params: {
            accountCode: filters.accountCode,
            accountName: filters.accountName,
            accountType: filters.accountType,
          },
        },
        { responseType: "blob" }
      )
      .then(res => {
        fileDownload(res.data, "Account.csv");
      });
  }
}
export default new AccountService();
