import { InputText } from "primereact/inputtext";
import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ placeholder, keyfilter, value, handleInput, name, id }) => {
  return (
    <InputText
      keyfilter={keyfilter}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={handleInput}
      id={id}
    />
  );
};
