import React, { useCallback, useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";

import DataControls from "../../components/DataTools/DataControls";
import { useLanguageContext } from "../../context/GlobalProvider";
import { getLocalStorage } from "../../services/LocalStorage";
import ApiHttpTraceService from "../../services/ApiHttpTraceService";
import { CustomFilterSideBarHeader } from "../../utils/CustomSidebarHeaderFilter";
import { CustomDateFormat } from "../../utils/CustomDateFormat";
import CustomAutoComplete from "../../components/CustomAutoComplete";

export default function HttpTrace() {
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [apiHttpTraceData, setApiHttpTraceData] = useState([]);
  const [hotelListName, setHotelListName] = useState([]);
  const [selectedApiHttpTrace, setSelectedApiHttpTrace] = useState(null);
  const [visibleSearchSideBar, setVisibleSearchSidebar] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });

  const [filters, setFilters] = useState({
    method: "",
    uri: "",
    responseStatusCode: "",
    requestBody: "",
    requestDate: "",
    apiUserName: "",
  });

  const httpMethodList = [
    { id: "1", name: "GET" },
    { id: "2", name: "PUT" },
    { id: "3", name: "POST" },
    { id: "4", name: "DELETE" },
  ];

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  //attribute for filter
  const [selectedHttpMethod, setSelectedHttpMethod] = useState("");
  const [apiUserName, setApiUserName] = useState("");
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [requestBody, setRequestBody] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [responseStatusCode, setResponseStatusCode] = useState("");
  const [apiHttpRaceFilterBadges, setApiHttpRaceFilterBadges] = useState([]);

  //for  lov
  const [apiUserNameList, setApiUserNameList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState("");

  const [isHotelSelected, setIsHotelSelected] = useState(false);

  //for visible dialog

  const [visibleDialogRequestBody, setVisibleDialogRequestBody] =
    useState(false);

  const [displayedRequestBody, setDisplayedRequestBody] = useState("");
  const { t } = useLanguageContext();
  const onPage = event => {
    setLazyParams(event);
  };
  const onSort = event => {
    setLazyParams(event);
  };

  const loadLazyApiHttpTraceData = useCallback(() => {
    setLoading(true);
    ApiHttpTraceService.getApiHttpTrace(lazyParams, filters).then(res => {
      setTotalRecords(res.data.totalRecords);
      setApiHttpTraceData(res.data.httpTraces);
      setLoading(false);
    });
  }, [lazyParams, filters, DefaultHotelId]);

  useEffect(() => {
    loadLazyApiHttpTraceData();
  }, [loadLazyApiHttpTraceData]);

  //get hotel list
  useEffect(() => {
    setHotelListName(
      getLocalStorage("userHotels").map(item => ({
        id: item.hotelId,
        name: item.name,
        logo: item.logo,
        pilot: item.pilot,
      }))
    );
  }, []);
  function onVisibleFilterSideBar() {
    setVisibleSearchSidebar(false);
  }
  function handleSearchButtonClick() {
    setVisibleSearchSidebar(true);
  }

  const selectedHotelTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value  flex align-items-center">
          <img
            alt={option.name}
            src={`https://app.operandi.travel/images/logos/${option.logo}`}
            style={{ width: "20px", height: "20px" }}
            onError={e =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`border-circle  flag flag-${option.name.toLowerCase()}`}
          />
          <div className="selected-hotel-name">{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const hotelOptionTemplate = option => {
    return (
      <React.Fragment>
        <div className="country-item  flex align-items-center">
          <img
            alt={option.name}
            src={`https://app.operandi.travel/images/logos/${option.logo}`}
            style={{ width: "17px" }}
            onError={e =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.name.toLowerCase()}`}
          />
          <div
            className="hotel-name"
            style={{ marginLeft: "12px", color: option.pilot ? "red" : "" }}
          >
            {option.name}
          </div>
        </div>
      </React.Fragment>
    );
  };

  function onHotelChange(e) {
    setIsHotelSelected(true);
    setSelectedHotel(e.target.value);
  }

  function handleSelectedHttpMethodChange(e) {
    setSelectedHttpMethod(e.target.value);
  }

  function handleSelectedApiUserNameChange(e) {
    setApiUserName(e.target.value);
  }
  function handleRequestBodyChange(e) {
    setRequestBody(e.target.value);
  }
  function handleResponseStatusCodeChange(e) {
    setResponseStatusCode(e.target.value);
  }
  function handleRequestDateChange(e) {
    console.log("date", e.target.value);
    let v = new Date(e.target.value);

    setRequestDate(v.toLocaleDateString("sv-SE"));
  }

  function searchInUri(selectedPermission, selectedHotel) {
    let uri = "fd";
    let searchValue = "";
    let containValue = "{hotelId}";

    if (selectedPermission.name !== undefined && isHotelSelected) {
      if (
        selectedPermission.name.includes(containValue) &&
        selectedHotel !== undefined
      ) {
        return (
          uri + selectedPermission.name.replace(containValue, selectedHotel)
        );
      }
    }
    if (selectedPermission.id !== undefined && !isHotelSelected) {
      if (selectedPermission.name.includes(containValue)) {
        return searchValue + selectedPermission.name.replace("/{hotelId}/", "");
      } else {
        return searchValue + selectedPermission.name.replace("/", "");
      }
    }
    if (selectedHotel !== undefined && isHotelSelected) {
      return uri + "/" + selectedHotel;
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    let newFilters = [];

    if (selectedHttpMethod) {
      newFilters.push({
        key: "1",
        filterValue: selectedHttpMethod,
        removable: true,
      });
    }
    if (
      (selectedPermission.id && selectedHotel) ||
      selectedPermission.id ||
      selectedHotel ||
      selectedPermission
    ) {
      newFilters.push({
        key: "2",
        filterValue:
          searchInUri(selectedPermission, selectedHotel) || selectedPermission,
        removable: true,
      });
    }

    if (responseStatusCode) {
      newFilters.push({
        key: "3",
        filterValue: responseStatusCode,
        removable: true,
      });
    }

    if (requestDate) {
      newFilters.push({
        key: "4",
        filterValue: requestDate,
        removable: true,
      });
    }

    if (requestBody) {
      newFilters.push({
        key: "5",
        filterValue: requestBody,
        removable: true,
      });
    }
    if (apiUserName.id || apiUserName) {
      newFilters.push({
        key: "6",
        filterValue: apiUserName.id || apiUserName,
        removable: true,
      });
    }
    setFilters({
      method: selectedHttpMethod,
      uri: searchInUri(selectedPermission, selectedHotel) || selectedPermission,
      responseStatusCode: responseStatusCode,
      requestBody: requestBody,
      requestDate: requestDate,
      apiUserName: apiUserName.id || apiUserName,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });

    setApiHttpRaceFilterBadges(newFilters);

    setVisibleSearchSidebar(false);
  }
  function handleFilterBadgeClear() {
    setApiHttpRaceFilterBadges([]);
    setApiUserName("");
    setRequestBody("");
    setResponseStatusCode("");
    setSelectedHotel("");
    setRequestDate("");
    setSelectedPermission("");
    setIsHotelSelected(false);
    setFilters({
      method: "",
      uri: "",
      responseStatusCode: "",
      requestBody: "",
      requestDate: "",
      apiUserName: "",
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "1":
        setSelectedHttpMethod("");
        setFilters({
          method: "",
          uri: searchInUri(selectedPermission, selectedHotel),
          responseStatusCode: responseStatusCode,
          requestBody: requestBody,
          requestDate: requestDate,
          apiUserName: apiUserName.id || apiUserName,
        });
        break;
      case "2":
        setSelectedHotel("");
        setSelectedPermission("");
        setIsHotelSelected(false);
        setFilters({
          method: selectedHttpMethod,
          uri: "",
          responseStatusCode: responseStatusCode,
          requestBody: requestBody,
          requestDate: requestDate,
          apiUserName: apiUserName.id || apiUserName,
        });
        break;
      case "3":
        setResponseStatusCode("");
        setFilters({
          method: selectedHttpMethod,
          uri: searchInUri(selectedPermission, selectedHotel),
          responseStatusCode: "",
          requestBody: requestBody,
          requestDate: requestDate,
          apiUserName: apiUserName.id || apiUserName,
        });
        break;
      case "4":
        setRequestDate("");
        setFilters({
          method: selectedHttpMethod,
          uri: searchInUri(selectedPermission, selectedHotel),
          responseStatusCode: responseStatusCode,
          requestBody: requestBody,
          requestDate: "",
          apiUserName: apiUserName.id || apiUserName,
        });
        break;
      case "5":
        setRequestBody("");
        setFilters({
          method: selectedHttpMethod,
          uri: searchInUri(selectedPermission, selectedHotel),
          responseStatusCode: responseStatusCode,
          requestBody: "",
          requestDate: requestDate,
          apiUserName: apiUserName.id || apiUserName,
        });
        break;
      case "6":
        setApiUserName("");
        setFilters({
          method: selectedHttpMethod,
          uri: searchInUri(selectedPermission, selectedHotel),
          responseStatusCode: responseStatusCode,
          requestBody: requestBody,
          requestDate: requestDate,
          apiUserName: "",
        });
        break;
      default:
        setRequestBody("");
        setFilters({
          method: selectedHttpMethod,
          uri: searchInUri(selectedPermission, selectedHotel),
          responseStatusCode: requestBody,
          requestBody: requestBody,
          requestDate: requestDate,
          apiUserName: "",
        });
    }
    setApiHttpRaceFilterBadges(current => current.filter(el => el.key !== key));
  }

  function filteredApiUserName(filterValue) {
    ApiHttpTraceService.lovUsers({
      apiUserName: filterValue,
      firstName: "",
      lastName: "",
    }).then(res => {
      setApiUserNameList(
        res.data.map(item => ({
          id: item.apiUserName,
        }))
      );
    });
  }

  function filteredPermission(filterValue) {
    ApiHttpTraceService.lovGetPermission({
      description: filterValue,
      path: "",
    }).then(res => {
      setPermissionList(
        res.data.map(item => ({
          id: item.description,
          name: item.path,
        }))
      );
    });
  }

  const dateBodyTemplate = rowData => {
    return CustomDateFormat(rowData.requestDate, "en-US");
  };
  function handleSelectedPermissionChange(e) {
    setSelectedPermission(e.target.value);
  }
  function getSelectedRow(rowdata) {
    setDisplayedRequestBody(rowdata.requestBody);
  }
  function clearHttpTraceInputFilters() {
    setSelectedHotel("");
    setApiUserName("");
    setIsHotelSelected(false);
    setSelectedPermission("");
    setRequestBody("");
    setRequestDate("");
    setSelectedHttpMethod("");
    setResponseStatusCode("");
  }

  function formattedRequestBody(jsonRequestBody) {
    try {
      const requestBody = JSON.parse(jsonRequestBody);
      return (
        <div>
          <pre>
            {Object.entries(requestBody).map(([key, item]) => (
              <div key={key}>
                <strong>{key}:</strong> {JSON.stringify(item, null, 2)}
              </div>
            ))}
          </pre>
        </div>
      );
    } catch (error) {
      return <div>Error parsing JSON</div>;
    }
  }
  return (
    <React.Fragment>
      <DataControls
        addButtonLabel="null"
        displayedDataControl="true"
        displayedImportExportDataControl="false"
        displayedImportDataControl="true"
        onSearchButtonClick={handleSearchButtonClick}
        filters={apiHttpRaceFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
      />

      <Dialog
        header={t("requestBody")}
        visible={visibleDialogRequestBody}
        onHide={() => setVisibleDialogRequestBody(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <p className="m-0">{formattedRequestBody(displayedRequestBody)}</p>
      </Dialog>
      <DataTable
        value={apiHttpTraceData}
        className="relative w-full"
        lazy
        selectionMode="single"
        selection={selectedApiHttpTrace}
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        onSelectionChange={e => setSelectedApiHttpTrace(e.value)}
        responsiveLayout="scroll"
        breakpoint="960px"
        dataKey="id"
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column field="apiUserName" sortable header={t("apiUserName")}></Column>
        <Column field="method" sortable header={t("httpMethod")} />
        <Column field="uri" sortable header={t("uri")} />

        <Column
          field="requestDate"
          sortable
          header={t("requestDate")}
          dataType="date"
          body={dateBodyTemplate}
        />
        <Column
          field="responseStatusCode"
          sortable
          header={t("responseStatusCode")}
        />

        <Column
          field="userAgent"
          sortable
          header={t("userAgent")}
          style={{ width: "25%" }}
        />
        <Column
          body={rowdata => {
            return (
              rowdata.requestBody !== null &&
              rowdata.requestBody !== undefined &&
              rowdata.requestBody !== "" && (
                <React.Fragment>
                  <Tooltip target=".custom-target-icon" />

                  <div style={{ width: "100%" }}>
                    <div id="setup-for-account-datatable">
                      <i
                        className="custom-target-icon pi pi-question-circle  p-toolbar-separator mr-2"
                        data-pr-position="top"
                        data-pr-tooltip={t("requestBody")}
                        onClick={() => {
                          setVisibleDialogRequestBody(true);
                          getSelectedRow(rowdata);
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )
            );
          }}
        />
      </DataTable>
      <Sidebar
        showCloseIcon={false}
        icons={CustomFilterSideBarHeader(onVisibleFilterSideBar, t)}
        blockScroll="true"
        id="sidebar-filter"
        visible={visibleSearchSideBar}
        position="right"
        className="p-sidebar-md	"
        onHide={() => setVisibleSearchSidebar(false)}
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={apiUserName}
                  options={apiUserNameList}
                  filterList={filteredApiUserName}
                  onChange={handleSelectedApiUserNameChange}
                  placeholder={t("placeholderApiUserNameField")}
                  dropdown={false}
                  name="selectedApiUserName"
                  inputId="selectedApiUserName"
                ></CustomAutoComplete>
              </div>

              <div className="field mb-4 col-12">
                <InputText
                  value={requestBody}
                  onChange={handleRequestBodyChange}
                  placeholder={t("placeholderRequestBodyField")}
                  id="requestBody"
                ></InputText>
              </div>
              <div className="field mb-4 col-12">
                <InputText
                  value={responseStatusCode}
                  onChange={handleResponseStatusCodeChange}
                  placeholder={t("placeholderResponseStatusCodeField")}
                  id="reponseStatusCode"
                ></InputText>
              </div>
              <div className="field mb-4 col-12">
                <Dropdown
                  placeholder={t("placeholderMethodField")}
                  options={httpMethodList}
                  value={selectedHttpMethod}
                  onChange={handleSelectedHttpMethodChange}
                  optionValue="name"
                  optionLabel="name"
                  name="selectedMethod"
                  inputId="selectedMethod"
                ></Dropdown>
              </div>
              <div className="field mb-4 col-12">
                <Dropdown
                  placeholder={t("placeholderHotelListField")}
                  valueTemplate={selectedHotelTemplate}
                  itemTemplate={hotelOptionTemplate}
                  value={selectedHotel}
                  options={hotelListName}
                  onChange={onHotelChange}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  filterBy="name"
                  filterMatchMode="startsWith"
                  name="hotelListDropDown"
                  inputId="hotelListDropDown"
                ></Dropdown>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedPermission}
                  options={permissionList}
                  filterList={filteredPermission}
                  onChange={handleSelectedPermissionChange}
                  placeholder={t("placeholderPermissionField")}
                  dropdown={false}
                  name="selectedPathPermission"
                  inputId="selectedPathPermission"
                ></CustomAutoComplete>
              </div>

              <div className="field mb-4 col-12">
                <Calendar
                  placeholder={t("placeholderRequestDateField")}
                  inputId="requestDate"
                  id="icon"
                  type="date"
                  value={requestDate}
                  onChange={handleRequestDateChange}
                  dateFormat="yy-mm-dd"
                  showIcon
                />
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label={t("findButton")}
                  className="p-button p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => {
                    clearHttpTraceInputFilters();
                  }}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={() => setVisibleSearchSidebar(false)}
                  className="p-button-secondary p-button-outlined  w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
    </React.Fragment>
  );
}
