import React, { useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import KpisService from "../../services/KpisService";
import { getLocalStorage } from "../../services/LocalStorage";
import AccountService from "../../services/AccountService";
import DepartmentService from "../../services/DepartmentService";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast } from "../../components/CustomToast";
import { ConfirmDelete } from "../../utils/ConfirmDeleteItem";
import { CustomConfirmDialogForKpiAssignment } from "../../utils/CustumConfirmDialogForKpiAssignment";

export default function KpiAccountDepartment(props) {
  const [selectedAccountCode, setSelectedAccountCode] = useState("");
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");
  const [kpiAccountDepartmentData, setKpiAccountDepartmentData] = useState([]);
  const [accountCodeList, setAccountCodeList] = useState([]);
  const [departmentCodeList, setDepartmentCodeList] = useState([]);
  const toast = useRef(null);
  const defaultHotelId = getLocalStorage("defaultHotelId");
  const { t } = useLanguageContext();

  useEffect(() => {
    if (
      props.kpiId !== null &&
      props.kpiId !== "undefined" &&
      props.kpiId !== ""
    ) {
      KpisService.getkpiAccountDepartmentByHotelId(
        defaultHotelId,
        props.kpiId
      ).then(res => {
        setKpiAccountDepartmentData(
          res.data.map(item => ({
            accountCode: item.accountId,
            departmentCode: item.departmentId,
          }))
        );
      });
    }
  }, [defaultHotelId, props.kpiId]);

  function addToListKpiAccountDepartment(account, department) {
    if (account && department) {
      const foundAccountCodeDepartmentCode = kpiAccountDepartmentData.find(
        el =>
          el.accountCode === account.id && el.departmentCode === department.id
      );
      if (!foundAccountCodeDepartmentCode) {
        setKpiAccountDepartmentData(kpiAccountDepartmentData => [
          ...kpiAccountDepartmentData,
          {
            accountCode: account.id,
            departmentCode: department.id,
          },
        ]);
        setSelectedAccountCode("");
        setSelectedDepartmentCode("");
      } else {
        toast.current.show({
          severity: "error",
          summary: t("errorMessage"),
          detail: t("toastMessageErrorValidationExist"),
          life: 60000,
        });
        //showErrorToast(toast, t, t("toastMessageErrorValidationExist"));
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: t("errorMessage"),
        detail: t("toastMessageErrorValidationRequired"),
        life: 60000,
      });
      //showErrorToast(toast, t, t("toastMessageErrorValidationrequired"));
    }
  }

  function removeFromListKpiAccountDepartment(rowdata) {
    setKpiAccountDepartmentData(
      kpiAccountDepartmentData.filter(el => {
        return (
          el.accountCode + el.departmentCode !==
          rowdata.accountCode + rowdata.departmentCode
        );
      })
    );
  }
  function clearKpiAccountDepartmentList() {
    setKpiAccountDepartmentData([]);
  }
  function handleSelectedAccountCodeChange(e) {
    setSelectedAccountCode(e.target.value);
  }

  function handleSelectedDepartmentCodeChange(e) {
    setSelectedDepartmentCode(e.target.value);
  }

  function saveKpi(e) {
    if (props.actionType === "add") {
      props.addKpi(kpiAccountDepartmentData, null, 0);
    } else {
      e.preventDefault();
      props.updateKpi(kpiAccountDepartmentData, null, 0);
    }
  }

  function filterAccountList(filterValue) {
    AccountService.getAccountByHotelId(
      defaultHotelId,
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        accountCode: filterValue,
        accountName: filterValue,
        accountType: filterValue,
        searchMode: "OR",
      }
    ).then(res => {
      setAccountCodeList(
        res.data.accounts.map(item => ({
          id: item.accountCode,
          name: item.accountName,
        }))
      );
    });
  }

  function filterDepartmentList(filterValue) {
    DepartmentService.getDepartmentByHotelId(
      defaultHotelId,
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        departmentCode: filterValue,
        departmentName: filterValue,
        searchMode: "OR",
      }
    ).then((res, error) => {
      setDepartmentCodeList(
        res.data.departments.map(item => ({
          id: item.departmentCode,
          name: item.departmentName,
        }))
      );
    });
  }

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />

      <div>
        <div className="surface-card p-1  border-round p-fluid">
          <div className="grid">
            <div className="col-6" style={{ minHeight: "300px" }}>
              <div className="shadow-3 p-2" style={{ height: "100%" }}>
                <div className="field mb-4 col-12">
                  <CustomAutoComplete
                    value={selectedAccountCode}
                    inputId="selectedAccountCode"
                    name="selectedAccountCode"
                    options={accountCodeList}
                    filterList={filterAccountList}
                    onChange={handleSelectedAccountCodeChange}
                    placeholder={t("placeholderAccountCodeField")}
                  ></CustomAutoComplete>
                </div>
                <div className="field mb-4 col-12">
                  <CustomAutoComplete
                    value={selectedDepartmentCode}
                    inputId="selectedDepartmentCode"
                    name="selectedDepartmentCode"
                    options={departmentCodeList}
                    filterList={filterDepartmentList}
                    onChange={handleSelectedDepartmentCodeChange}
                    placeholder={t("placeholderDepartmentCodeField")}
                  ></CustomAutoComplete>
                </div>
                <Button
                  className=" p-button-outlined"
                  label={t("addToListButton")}
                  onClick={() =>
                    addToListKpiAccountDepartment(
                      selectedAccountCode,
                      selectedDepartmentCode
                    )
                  }
                />
              </div>
            </div>

            <div className="col-6">
              <div className="shadow-3 ml-3 p-2" style={{ height: "100%" }}>
                <DataTable
                  value={kpiAccountDepartmentData}
                  scrollable
                  scrollHeight="270px"
                  autoLayout="true"
                >
                  <Column field="accountCode" header={t("account")} sortable />
                  <Column
                    field="departmentCode"
                    header={t("department")}
                    sortable
                  />
                  <Column
                    //header={header}
                    body={rowdata => {
                      return (
                        <div style={{ width: "100%" }}>
                          <i
                            className="pi pi-times text-red-500 font-bold"
                            onClick={() =>
                              CustomConfirmDialogForKpiAssignment(
                                t,
                                rowdata,
                                t(
                                  "customConfirmDialogDeleteMessageForAssignmentListItem"
                                ),
                                removeFromListKpiAccountDepartment
                              )
                            }
                          ></i>
                        </div>
                      );
                    }}
                  />
                </DataTable>
              </div>
            </div>
          </div>

          <div className="field mb-4 col-12 md:col-12">
            <Button
              iconPos="left"
              icon="pi pi-arrow-left"
              label={t("previousButton")}
              onClick={props.onButtonPreviousClick}
              className="p-button-secondary  p-button-outlined w-auto mr-2"
            />
            <Button
              type="reset"
              label={t("resetButton")}
              onClick={() => {
                CustomConfirmDialogForKpiAssignment(
                  t,
                  null,
                  t("customConfirmDialogDeleteMessageForClearAssignmentList"),
                  clearKpiAccountDepartmentList
                );
              }}
              className="p-button-secondary p-button-outlined mr-2  w-auto"
            />
            <Button
              label={t("saveButton")}
              icon="pi pi-plus"
              loading={props.clickedButton}
              onClick={saveKpi}
              className="p-button p-button-success w-auto"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
