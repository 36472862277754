import api from "./ApiService";

class ComputeKpisService {
  computeKpiCollection(
    groupHotelId,
    hotelIds,
    kpiRefs,
    months,
    years,
    budgetVersionCode
  ) {
    return api.post("compute_kpi/collection", {
      groupHotelId: groupHotelId,
      hotelIds: hotelIds,
      kpiRefs: kpiRefs,
      months: months,
      years: years,
      budgetVersionCode: budgetVersionCode,
    });
  }
  computeKpi(
    hotelId,
    kpiRef,
    period,
    actual,
    currentMonth,
    thisYear,
    budgetVersionCode
  ) {
    return api.get("/compute_kpi", {
      params: {
        hotelId: hotelId,
        kpiRef: kpiRef,
        period: period,
        actual: actual,
        currentMonth: currentMonth,
        thisYear: thisYear,
        budgetVersionCode: budgetVersionCode,
      },
    });
  }
  computeKpiProgress(groupHotelId) {
    return api.get("compute_kpi/progress", {
      params: {
        groupHotelId: groupHotelId,
      },
    });
  }
  computeKpiFormula(
    hotelId,
    formula,
    period,
    actual,
    currentMonth,
    thisYear,
    budgetVersionCode
  ) {
    return api.get("compute_kpi/formula", {
      params: {
        hotelId: hotelId,
        formula: formula,
        period: period,
        actual: actual,
        currentMonth: currentMonth,
        thisYear: thisYear,
        budgetVersionCode: budgetVersionCode,
      },
    });
  }
  getLastComputeDate(hotelId) {
    return api.get("compute_kpi/last-compute-date", {
      params: {
        hotelId: hotelId,
      },
    });
  }
}
export default new ComputeKpisService();
