import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Password } from "primereact/password";

import AuthenticationService from "../../services/AuthenticationService";
import useQueryParams from "../../hooks/useQueryParams";
import { setLocalStorage } from "../../services/LocalStorage";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast, showSuccessToast } from "../../components/CustomToast";

export default function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const token = setLocalStorage("token", useQueryParams("token"));
  const toast = useRef(null);
  const { t } = useLanguageContext();
  const navigate = useNavigate();

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }
  function handlePasswordConfirmationChange(e) {
    setPasswordConfirmation(e.target.value);
  }
  function resetPassword() {
    AuthenticationService.resetPasswordRequest(password, passwordConfirmation)
      .then(res => {
        showSuccessToast(toast, t, "resetPassword");
        setPassword("");
        setPasswordConfirmation("");
        setTimeout(navigate, 4000, "/");
      })

      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <img
              src="../assets/img/operandi-logo-2.png"
              alt="operandi-logo"
              height={50}
              className="mb-3"
            />
            <div className="text-center m-5-auto">
              <h2>{t("ResetPasswordPageTitle")}</h2>
            </div>
            <p>
              <br />

              <Password
                className="password-input"
                inputId="newPassword"
                placeholder={t("placeholderNewPasswordField")}
                value={password}
                onChange={handlePasswordChange}
                toggleMask
              />
            </p>
            <p>
              <Password
                inputId="confirmNewPassword"
                className="password-input"
                placeholder={t("placeholderConfirmNewPassword")}
                value={passwordConfirmation}
                onChange={handlePasswordConfirmationChange}
                toggleMask
              />
            </p>

            <p>
              <Button
                id="sub_btn"
                label={t("confirmPasswordButton")}
                onClick={() => resetPassword()}
              />
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
