import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";
import { Steps } from "primereact/steps";
import { ScrollTop } from "primereact/scrolltop";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

import DataControls from "../../components/DataTools/DataControls";
import KpisService from "../../services/KpisService";
import KpiConstant from "./KpiConstant";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import GroupKpisServices from "../../services/GroupKpisServices";
import BudgetVersionsService from "../../services/BudgetVersionsService";
import ComputeKpisService from "../../services/ComputeKpisService";
import Expresssion from "./Expresssion";
import KpiAccountDepartment from "./KpiAccountDepartment";
import {
  getLocalStorage,
  removeItemFromLocalStorage,
} from "../../services/LocalStorage";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast, showSuccessToast } from "../../components/CustomToast";
import { actionBodyTemplate } from "../../utils/DataTableAction";

export default function Kpis(props) {
  const [kpisList, setKpisList] = useState([]);
  const [kpisFiltredList, setKpisFiltredList] = useState([]);
  const [kpisData, setKpisData] = useState([]);
  const [selectedKpiName, setSelectedKpiName] = useState("");
  const [selectedKpiDescription, setSelectedKpiDescription] = useState("");
  const [selectedKpiType, setSelectedKpiType] = useState("");
  const [kpiFormula, setKpiFormula] = useState("");
  const [actionType, setActionType] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [errorsName, setErrorsName] = useState("");
  const [errorsDescription, setErrorsDescription] = useState("");
  const [errorsKpiType, setErrorsKpiType] = useState("");
  const [errorsKpiFormat, setErrorKpiFormat] = useState("");
  const [classNameKpiFormat, setClassNameKpiFormat] = useState("");
  const [classNameDecimalPlaces, setClassNameDecimalPlaces] = useState("");
  const [errorsDecimalPlaces, setErrorsDecimalPlaces] = useState("");
  const [sideBarTitle, setSideBarTitle] = useState("");
  const [period, setPeriod] = useState(
    getLocalStorage("year") + 0 + getLocalStorage("month")
  );
  const [visibleKpiSidebar, setVisibleKpiSidebar] = useState(false);
  const [month, setMonth] = useState(getLocalStorage("month"));
  const [classNameKpiName, setClassNameKpiName] = useState("");
  const [classNameKpiDescription, setClassNameKpiDescription] = useState("");
  const [classNameKpiType, setClassNameKpiType] = useState("");
  const [year, setYear] = useState(getLocalStorage("year"));
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [loading, setLoading] = useState(false);
  const [visibleSearchSideBar, setVisibleSearchSideBar] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [checkedIIsExpense, setCheckedIIsExpense] = useState(false);
  const [kpiId, setKpiId] = useState("");
  const [kpiConstantValue, setKpiConstantValue] = useState(0);

  const toast = useRef(null);

  const { t } = useLanguageContext();

  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const typeKpi = [
    { id: "1", name: "TRANSACTION" },
    { id: "2", name: "EXPRESSION" },
    { id: "3", name: "CONSTANT" },
  ];
  const format = [
    { id: "1", name: "NUMBER" },
    { id: "2", name: "PERCENT" },
    { id: "3", name: "CONSTANT" },
  ];
  const RatioTypesOptions = [
    { id: 1, name: "POR" },
    { id: 2, name: "PAR" },
    { id: 3, name: "OTHER" },
    { id: 4, name: "PER" },
    { id: 5, name: "COVER" },
  ];
  const items = [
    {
      label: t("kpiStepsIndex_0"),
    },
    {
      label: t("kpiStepsIndex_1"),
    },
  ];
  const [kpiName, setKpiName] = useState("");
  const [kpiDescription, setKpiDescription] = useState("");
  const [kpiType, setKpiType] = useState("");
  const [formatkpi, setFormatKpi] = useState("");
  const [groupKpiData, setGroupKpiData] = useState([]);
  const [selectedGroupKpi, setSelectedGroupKpi] = useState("");
  const [decimalPlaces, setDecimalPlaces] = useState("2");
  const [checkedDashboard, setCheckedDashboard] = useState(true);
  const [checkedStatus, setCheckedStatus] = useState(true);
  const [ratioTypes, setRatioTypes] = useState("OTHER");
  const [sortKpi, setSortKpi] = useState("");
  const [kpisfilterValues, setKpisfilterValues] = useState({
    name: "" || getLocalStorage("currentClickedKpisName"),
    description: "",
    type: "",
  });
  const [kpiFilterBadges, setKpiFilterBadges] = useState([]);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [budgetVersion, setBudgetVersion] = useState([]);
  const [budgetVersionCodeValue, setBudgetVersionCodeValue] = useState("");

  function formatPeriod(period) {
    return period.substring(0, 4) + "/" + period.substring(5);
  }

  //Lov
  const LovGetKpisByHotelId = useCallback(() => {
    KpisService.lovGetKpisByHotelId(DefaultHotelId, {
      kpiName: "",
      kpiDescription: "",
      kpiType: "",
    }).then(res => {
      setKpisList(res.data);
    });
  }, [DefaultHotelId]);

  useEffect(() => {
    LovGetKpisByHotelId();

    if (props.selectedHotel) {
      BudgetVersionsService.getBudgetVersionsByHotelId(
        DefaultHotelId,
        {
          first: 1,
          rows: 500,
          page: 0,
          sortOrder: null,
          sortField: null,
        },
        {
          budgetVersionCode: "",
          description: "",
        }
      ).then(res => {
        setBudgetVersionCodeValue({
          id: res.data.budgetVersions[0].budgetVersionId.budgetVersionCode,
        });
        if (
          formatPeriod(period) !== null &&
          res.data.budgetVersions[0].budgetVersionId.budgetVersionCode !== null
        ) {
          setKpiFilterBadges([
            { key: "1", filterValue: formatPeriod(period), removable: false },
            {
              key: "2",
              filterValue:
                res.data.budgetVersions[0].budgetVersionId.budgetVersionCode,
              removable: false,
            },
          ]);
        }

        if (getLocalStorage("currentClickedKpisName") !== undefined) {
          setKpiFilterBadges([
            { key: "1", filterValue: formatPeriod(period), removable: false },
            {
              key: "2",
              filterValue:
                res.data.budgetVersions[0].budgetVersionId.budgetVersionCode,
              removable: false,
            },

            {
              key: "6",
              filterValue: getLocalStorage("currentClickedKpisName"),
              removable: true,
            },
          ]);
        }
      });
    }
  }, [props.selectedHotel, LovGetKpisByHotelId]);

  const loadLazyKpiData = useCallback(() => {
    setLoading(true);
    KpisService.getKpisByHotelId(
      DefaultHotelId,
      lazyParams,
      kpisfilterValues
    ).then(res => {
      setKpisData(res.data.kpis);
      setTotalRecords(res.data.totalRecords);
      setLoading(false);
    });
  }, [lazyParams, DefaultHotelId, kpisfilterValues]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyKpiData();
    }
  }, [loadLazyKpiData, props.selectedHotel]);

  const onPage = event => {
    setLazyParams(event);
  };

  const formatComputeKpiResult = value => {
    return value.toLocaleString("en", { minimumFractionDigits: 3 });
  };
  function computeKpiActionCurrentMonth(rowdata) {
    ComputeKpisService.computeKpi(
      DefaultHotelId,
      rowdata.name,
      period,
      true,
      true,
      true,
      budgetVersionCodeValue.id
    ).then(res => {
      showSuccessToast(
        toast,
        t,
        "computeKpi",
        formatComputeKpiResult(res.data)
      );
    });
  }

  function computeKpiBudgetCurrentMonth(rowdata) {
    ComputeKpisService.computeKpi(
      DefaultHotelId,
      rowdata.name,
      period,
      false,
      true,
      true,
      budgetVersionCodeValue.id
    ).then(res => {
      showSuccessToast(
        toast,
        t,
        "computeKpi",
        formatComputeKpiResult(res.data)
      );
    });
  }

  function computeKpiActionYearToDate(rowdata) {
    ComputeKpisService.computeKpi(
      DefaultHotelId,
      rowdata.name,
      period,
      true,
      false,
      true,
      budgetVersionCodeValue.id
    ).then(res => {
      showSuccessToast(
        toast,
        t,
        "computeKpi",
        formatComputeKpiResult(res.data)
      );
    });
  }

  function computeKpiBudgetYearToDate(rowdata) {
    ComputeKpisService.computeKpi(
      DefaultHotelId,
      rowdata.name,
      period,
      false,
      false,
      true,
      budgetVersionCodeValue.id
    ).then(res => {
      showSuccessToast(
        toast,
        t,
        "computeKpi",
        formatComputeKpiResult(res.data)
      );
    });
  }

  function validateKpisInput(
    name,
    description,
    kpiType,
    formatkpi,
    decimalPlaces
  ) {
    if (name.length === 0) {
      setErrorsName(t("kpiNameRequired"));
      setClassNameKpiName("p-invalid");
    } else {
      setErrorsName("");
      setClassNameKpiName("");
    }

    if (kpiType.length === 0) {
      setErrorsKpiType(t("kpiTypeRequired"));
      setClassNameKpiType("p-invalid");
    } else {
      setErrorsKpiType("");
      setClassNameKpiName("");
    }

    if (description.length === 0) {
      setErrorsDescription(t("kpiDescriptionRequired"));
      setClassNameKpiDescription("p-invalid");
    } else {
      setErrorsDescription("");
      setClassNameKpiType("");
    }

    if (formatkpi.length === 0) {
      setErrorKpiFormat(t("kpiFormatRequired"));
      setClassNameKpiFormat("p-invalid");
    } else {
      setErrorKpiFormat("");
      setClassNameKpiFormat("");
    }
    if (decimalPlaces === null) {
      setErrorsDecimalPlaces("Decimal  places  required");
      setClassNameDecimalPlaces("p-invalid");
    } else {
      setErrorsDecimalPlaces("");
      setClassNameDecimalPlaces("");
    }

    if (
      name.length > 0 &&
      kpiType.length > 0 &&
      description.length > 0 &&
      formatkpi.length > 0 &&
      decimalPlaces != null
    ) {
      setActiveIndex(1);
    }
  }

  function previous() {
    setActiveIndex(0);
  }

  const onSort = event => {
    setLazyParams(event);
  };

  function resetKpiForm() {
    setActiveIndex(0);
    setKpiFormula("");
    setKpiConstantValue(0);
    setCheckedDashboard(false);
    setCheckedIIsExpense(false);
    setCheckedStatus(false);
    setDecimalPlaces("2");
    setFormatKpi("");
    setSelectedGroupKpi("");
    setKpiType("");
    setSortKpi(0);
    setKpiName("");
    setKpiDescription("");
    setErrorKpiFormat("");
    setClassNameKpiFormat("");
    setClassNameKpiDescription("");
    setErrorsDescription("");
    setClassNameKpiName("");
    setErrorsName("");
    setErrorsKpiType("");
    setClassNameKpiType("");
    setClassNameDecimalPlaces("");
    setErrorsDecimalPlaces("");
  }
  function cancel() {
    setVisibleKpiSidebar(false);
    resetKpiForm();
  }

  function handleBudgetVersionCodeChange(e) {
    setBudgetVersionCodeValue(e.target.value);
  }

  function handleAddButtonClick() {
    setSideBarTitle(t("newKpiSidebarTitle"));
    setActionType("add");
    resetKpiForm();
    setVisibleKpiSidebar(true);
    setKpiId(null);
  }

  function handleSelectedKpiNameChange(e) {
    setSelectedKpiName(e.value);
  }
  function handleSelecedKpiTypeChange(e) {
    setSelectedKpiType(e.value);
  }
  function clearKpiFormFilters() {
    setSelectedKpiName("");
    setSelectedKpiDescription("");
    setSelectedKpiType("");
  }
  const customSideBarHeader = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleKpiSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {sideBarTitle === t("editKpiSidebarTitle") && (
          <span>
            {t("editKpiSidebarTitle")}({kpiName})
          </span>
        )}
        {sideBarTitle === t("newKpiSidebarTitle") && (
          <span>{t("newKpiSidebarTitle")}</span>
        )}
      </div>
    </React.Fragment>
  );

  const customHeaderFilterKpis = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleSearchSideBar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );

  function handleKpiNameChange(e) {
    setKpiName(e.target.value);
    setErrorsName("");
    setClassNameKpiName("");
  }

  function handleKpiDescriptionChange(e) {
    setKpiDescription(e.target.value);
    setErrorsDescription("");
    setClassNameKpiDescription("");
  }

  function handleKpiTypeChange(e) {
    setKpiType(e.target.value);
    setErrorsKpiType("");
    setClassNameKpiType("");
  }

  function handleKpiFormatChange(e) {
    setFormatKpi(e.target.value);
    setErrorKpiFormat("");
    setClassNameKpiFormat("");
  }

  function handleSelectedGroupKpiChange(e) {
    setSelectedGroupKpi(e.target.value);
  }

  function handleSelectedKpiDescriptionChange(e) {
    setSelectedKpiDescription(e.value);
  }

  function handleDecimalPlacesChange(e) {
    setDecimalPlaces(e.value);
    setClassNameDecimalPlaces("");
    setErrorsDecimalPlaces("");
  }

  function handleRatioTypesChange(e) {
    setRatioTypes(e.target.value);
  }

  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];
    newFilters.push({
      key: "1",
      filterValue: formatPeriod(period),
      removable: false,
    });
    newFilters.push({
      key: "2",
      filterValue: budgetVersionCodeValue.id || budgetVersionCodeValue,
      removable: false,
    });
    if (selectedKpiName.id || selectedKpiName) {
      newFilters.push({
        key: "3",
        filterValue: selectedKpiName.id || selectedKpiName,
        removable: true,
      });
    }
    if (selectedKpiDescription.id || selectedKpiDescription) {
      newFilters.push({
        key: "4",
        filterValue: selectedKpiDescription.id || selectedKpiDescription,
        removable: true,
      });
    }
    if (selectedKpiType) {
      newFilters.push({
        key: "5",
        filterValue: selectedKpiType,
        removable: true,
      });
    }
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setKpiFilterBadges(newFilters);

    setKpisfilterValues({
      name:
        typeof selectedKpiName === "object"
          ? selectedKpiName.id
          : selectedKpiName,
      description:
        typeof selectedKpiDescription === "object"
          ? selectedKpiDescription.id
          : selectedKpiDescription,
      type: selectedKpiType,
    });

    setVisibleSearchSideBar(false);
  }

  function onFilterItemRemove(key) {
    switch (key) {
      case "3":
        setSelectedKpiName("");
        setKpisfilterValues({
          name: "",
          description: selectedKpiDescription.id || selectedKpiDescription,
          type: selectedKpiType,
        });
        break;
      case "4":
        setSelectedKpiDescription("");
        setKpisfilterValues({
          name: selectedKpiName.id || selectedKpiName,
          description: "",
          type: selectedKpiType,
        });
        break;
      case "6":
        removeItemFromLocalStorage("currentClickedKpisName");
        setKpisfilterValues({
          name: "",
          description: "",
          type: selectedKpiType,
        });

        break;
      default:
        setSelectedKpiType("");
        setKpisfilterValues({
          name: selectedKpiName.id || selectedKpiName,
          description: selectedKpiDescription.id || selectedKpiDescription,
          type: "",
        });
    }
    setKpiFilterBadges(current => current.filter(el => el.key !== key));
  }

  function handleFilterBadgeClear() {
    setKpiFilterBadges([
      { key: "1", filterValue: formatPeriod(period), removable: false },
      {
        key: "2",
        filterValue: budgetVersionCodeValue.id,
        removable: false,
      },
    ]);
    setSelectedKpiName("");
    removeItemFromLocalStorage("currentClickedKpisName");
    setSelectedKpiDescription("");
    setSelectedKpiType("");
    setKpisfilterValues({
      name: "",
      description: "",
      type: "",
    });
  }
  function getSelectedRow(rowdata) {
    setKpiFormula(rowdata.formula);
    setKpiId(rowdata.kpiId);
    setKpiName(rowdata.name);
    setKpiDescription(rowdata.description);
    setKpiType(rowdata.type);
    setCheckedIIsExpense(rowdata.expense);
    setDecimalPlaces(rowdata.decimalPlaces);
    setCheckedDashboard(rowdata.dashboardKpi);
    setFormatKpi(rowdata.numberFormat);
    setSortKpi(rowdata.sort);
    setRatioTypes(rowdata.percentageType);
    setCheckedStatus(rowdata.active);
    setSelectedGroupKpi(rowdata.groupKpiId);
    setKpiConstantValue(rowdata.value);
  }

  function deleteKpis(kpiId) {
    KpisService.deleteKpiByIdAndHotelId(DefaultHotelId, kpiId)
      .then(res => {
        setKpisData(kpisData.filter(value => value.kpiId !== kpiId));
        showSuccessToast(toast, t, "delete");
        LovGetKpisByHotelId();
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }

  function dialogCallBack() {
    setYear(getLocalStorage("year"));
    setMonth(getLocalStorage("month"));
    setDialogVisible(false);
  }

  const dialogFooter = (
    <div>
      <Button
        label="OK"
        className="p-button-danger"
        onClick={() => dialogCallBack()}
      />
    </div>
  );

  function handleSearchButtonClick() {
    setVisibleSearchSideBar(true);
  }

  function handleSearchPeriodChange(event) {
    const year = event.target.value.substring(0, 4);
    const month = event.target.value.substring(5);
    const periodValue = year + 0 + month;
    setPeriod(periodValue);
  }

  function addKpi(kpiAccountDepartmentData, formula, constantValue) {
    setClickedButton(true);
    KpisService.addKpisByHotelId(
      DefaultHotelId,
      kpiName,
      kpiDescription,
      kpiType,
      formula,
      constantValue,
      formatkpi,
      decimalPlaces,
      checkedIIsExpense,
      ratioTypes,
      sortKpi,
      checkedDashboard,
      checkedStatus,
      kpiAccountDepartmentData,
      selectedGroupKpi.id
    )
      .then(res => {
        setClickedButton(false);
        loadLazyKpiData();
        resetKpiForm();
        setVisibleKpiSidebar(false);

        showSuccessToast(toast, t, "add");

        LovGetKpisByHotelId();
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }

  function updateKpi(kpiAccountDepartmentData, formula, kpiConstantValue) {
    setClickedButton(true);
    KpisService.updateKpisByHotelId(
      DefaultHotelId,
      kpiId,
      kpiName,
      kpiDescription,
      kpiType,
      formula,
      kpiConstantValue,
      formatkpi,
      decimalPlaces,
      checkedIIsExpense,
      ratioTypes,
      sortKpi,
      checkedDashboard,
      checkedStatus,
      kpiAccountDepartmentData,
      selectedGroupKpi
    )
      .then(res => {
        setClickedButton(false);
        loadLazyKpiData();
        resetKpiForm();
        setVisibleKpiSidebar(false);

        showSuccessToast(toast, t, "update");

        LovGetKpisByHotelId();
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }

  function filterGroupkpiList(filterValue) {
    GroupKpisServices.getGroupKpisByHotelId(
      DefaultHotelId,
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        groupKpiId: filterValue,
        name: filterValue,
        searchMode: "OR",
      }
    ).then(res => {
      setGroupKpiData(
        res.data.groupKpis.map(item => ({
          id: item.groupKpiId.groupKpiId,
          name: item.name,
        }))
      );
    });
  }
  function filterkpiListName(filterValue) {
    KpisService.lovGetKpisByHotelId(DefaultHotelId, {
      selectedKpiName: filterValue,
      selectedKpiDescription: "",
    }).then(res => {
      setKpisFiltredList(
        res.data.map(item => ({
          id: item.name,
        }))
      );
    });
  }
  function filterkpiListDescription(filterValue) {
    KpisService.lovGetKpisByHotelId(DefaultHotelId, {
      selectedKpiName: "",
      selectedKpiDescription: filterValue,
    }).then(res => {
      setKpisFiltredList(
        res.data.map(item => ({
          id: item.description,
        }))
      );
    });
  }
  function filterBudgetVersionList(filterValue) {
    BudgetVersionsService.getBudgetVersionsByHotelId(
      DefaultHotelId,
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        budgetVersionCode: filterValue,
        description: "",
      }
    ).then(res => {
      setBudgetVersion(
        res.data.budgetVersions.map(item => ({
          id: item.budgetVersionId.budgetVersionCode,
        }))
      );
    });
  }
  function handleVisibleEditSidebar() {
    setVisibleKpiSidebar(true);
    setSideBarTitle(t("editKpiSidebarTitle"));
    setActionType("edit");
    setActiveIndex(0);
  }

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <DataControls
        addButtonLabel={t("addButton")}
        filters={kpiFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onAddButtonClick={handleAddButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        onFilterItemRemove={onFilterItemRemove}
        displayedDataControl="true"
        displayedImportExportDataControl="false"
      />
      <ConfirmDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        message={dialogMessage}
        footer={dialogFooter}
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        header="Information"
      />

      <DataTable
        value={kpisData}
        lazy
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
        responsiveLayout="scroll"
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column
          field="name"
          sortable
          header={t("KpiDatatableColumnTitleName")}
        />
        <Column
          field="description"
          sortable
          header={t("KpiDatatableColumnTitleDescription")}
        />
        <Column
          field="type"
          sortable
          header={t("KpiDatatableColumnTitleType")}
        />
        <Column
          field="groupKpi.name"
          sortable
          header={t("KpiDatatableColumnTitleGroup")}
        />

        <Column
          header={t("KpiDatatableColumnTitleCurrentMonth")}
          sortable
          body={rowdata => {
            return (
              <div style={{ width: "100%" }}>
                <div id="setup-for-account-datatable">
                  <Button
                    className="compute-kpi-button p-button-outlined"
                    label="A"
                    onClick={() => {
                      computeKpiActionCurrentMonth(rowdata);
                    }}
                  />
                  <Button
                    label="B"
                    className="ml-3 compute-kpi-button  p-button-outlined"
                    onClick={() => {
                      computeKpiBudgetCurrentMonth(rowdata);
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
        <Column
          header={t("KpiDatatableColumnTitleYearToDate")}
          sortable
          body={rowdata => {
            return (
              <div style={{ width: "100%" }}>
                <div id="setup-for-account-datatable">
                  <Button
                    label={t("A")}
                    className=" compute-kpi-button p-button-outlined"
                    onClick={() => {
                      computeKpiActionYearToDate(rowdata);
                    }}
                  />
                  <Button
                    label={t("B")}
                    className="compute-kpi-button ml-3 p-button-outlined"
                    onClick={() => {
                      computeKpiBudgetYearToDate(rowdata);
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
        <Column
          body={rowdata => {
            return actionBodyTemplate(
              rowdata,
              t,
              handleVisibleEditSidebar,
              getSelectedRow,
              rowdata.kpiId,
              deleteKpis
            );
          }}
        />
      </DataTable>

      {/* Start- SideBar for Add / Update Kpis */}
      <Sidebar
        modal="true"
        blockScroll="true"
        showCloseIcon={false}
        icons={customSideBarHeader}
        id="sidebar-for-add-account"
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleKpiSidebar}
        position="right"
        onHide={() => setVisibleKpiSidebar(false)}
      >
        <Steps
          className="steps-for-add-kpis"
          model={items}
          activeIndex={activeIndex}
        />
        {activeIndex === 0 && (
          <React.Fragment>
            <div className="surface-card p-0 border-round p-fluid">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label htmlFor="KpiName" className="font-medium text-900">
                    {t("labelKpiNameField")}
                  </label>
                  {sideBarTitle === t("editKpiSidebarTitle") ? (
                    <InputText
                      className={classNameKpiName}
                      value={kpiName}
                      onChange={handleKpiNameChange}
                      type="text"
                      id="KpiName"
                      disabled
                    />
                  ) : (
                    <InputText
                      id="KpiName"
                      className={classNameKpiName}
                      value={kpiName}
                      onChange={handleKpiNameChange}
                      type="text"
                    />
                  )}
                  <span style={{ color: "red" }}>{errorsName}</span>
                </div>
                <div className="field mb-4 col-12">
                  <label
                    htmlFor="KpiDescription"
                    className="font-medium text-900"
                  >
                    {t("labelKpiDescriptionField")}
                  </label>
                  <InputText
                    id="KpiDescription"
                    className={classNameKpiDescription}
                    type="text"
                    value={kpiDescription}
                    onChange={handleKpiDescriptionChange}
                  />
                  <span style={{ color: "red" }}>{errorsDescription}</span>
                </div>
                <div className="grid">
                  <div className="col">
                    <div className="field mb-4 col-12">
                      <label htmlFor="KpiType" className="font-medium text-900">
                        {t("labelkpiTypeField")}
                      </label>
                      <Dropdown
                        className={classNameKpiType}
                        placeholder={t("placeholderKpiTypeField")}
                        value={kpiType}
                        options={typeKpi}
                        onChange={handleKpiTypeChange}
                        optionValue="name"
                        optionLabel="name"
                        name="KpiType"
                        inputId="KpiType"
                      ></Dropdown>
                      <span style={{ color: "red" }}>{errorsKpiType}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field mb-4 col-12">
                      <label
                        htmlFor="KpiFormat"
                        className="font-medium text-900"
                      >
                        {t("labelKpiFormatField")}
                      </label>
                      <Dropdown
                        className={classNameKpiFormat}
                        placeholder={t("placeholderKpiFormatField")}
                        value={formatkpi}
                        options={format}
                        onChange={handleKpiFormatChange}
                        optionValue="name"
                        optionLabel="name"
                        name="KpiFormat"
                        inputId="KpiFormat"
                      ></Dropdown>
                      <span style={{ color: "red" }}>{errorsKpiFormat}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field mb-4 col-12">
                      <label
                        htmlFor="KpiGroup"
                        className="font-medium text-900"
                      >
                        {t("labelkpiGroupField")}
                      </label>
                      <CustomAutoComplete
                        value={selectedGroupKpi}
                        options={groupKpiData}
                        filterList={filterGroupkpiList}
                        onChange={handleSelectedGroupKpiChange}
                        placeholder={t("placeholderGroupKpiField")}
                        dropdown={true}
                        name="KpiGroup"
                        inputId="KpiGroup"
                      ></CustomAutoComplete>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field mb-4 col-12">
                      <label
                        htmlFor="DecimalPlaces"
                        className="font-medium text-900"
                      >
                        {t("labelDecimalPlacesField")}
                      </label>
                      <InputNumber
                        value={decimalPlaces}
                        inputId="DecimalPlaces"
                        onChange={handleDecimalPlacesChange}
                        className={classNameDecimalPlaces}
                      />
                      <span style={{ color: "red" }}>
                        {errorsDecimalPlaces}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="field mb-4 col-12">
                  <label
                    htmlFor="displayOptions"
                    className="font-medium text-900"
                  >
                    {t("labelRatioTypeField")}
                  </label>
                  <Dropdown
                    placeholder={t("placeholderRatioTypeField")}
                    value={ratioTypes}
                    options={RatioTypesOptions}
                    onChange={handleRatioTypesChange}
                    optionValue="name"
                    optionLabel="name"
                    inputId="displayOptions"
                    name="displayOptions"
                  ></Dropdown>
                </div>
                <div className="field mb-4 col-12">
                  <div className="grid">
                    <div style={{ marginRight: "12px", marginLeft: "7px" }}>
                      <label
                        htmlFor="isExpense"
                        className="font-medium text-900"
                      >
                        {t("labelExpenseField")}
                      </label>
                    </div>

                    <div>
                      <InputSwitch
                        inputId="isExpense"
                        checked={checkedIIsExpense}
                        onChange={e => setCheckedIIsExpense(e.value)}
                      ></InputSwitch>
                    </div>
                  </div>
                </div>

                <div className="field mb-4 col-12 md:col-12">
                  <Button
                    label={t("cancelButton")}
                    onClick={() => cancel()}
                    className=" p-button-secondary  p-button-outlined  w-auto mr-2"
                  />
                  <Button
                    iconPos="right"
                    icon="pi pi-arrow-right"
                    label={t("nextButton")}
                    onClick={() => {
                      validateKpisInput(
                        kpiName,
                        kpiDescription,
                        kpiType,
                        formatkpi,
                        decimalPlaces
                      );
                    }}
                    className="p-button-primary  p-button-outlined  w-auto"
                  />
                </div>
              </div>
            </div>
            <ScrollTop
              target="parent"
              threshold={100}
              className="custom-scrolltop"
              icon="pi pi-arrow-up"
            />
          </React.Fragment>
        )}
        {activeIndex === 1 && (
          <div className="kpi-type" style={{ marginTop: "70px" }}>
            {kpiType === "TRANSACTION" && (
              <React.Fragment>
                <KpiAccountDepartment
                  actionType={actionType}
                  addKpi={addKpi}
                  updateKpi={updateKpi}
                  clickedButton={clickedButton}
                  kpiId={kpiId}
                  onButtonPreviousClick={previous}
                ></KpiAccountDepartment>
              </React.Fragment>
            )}
            {kpiType === "EXPRESSION" && (
              <React.Fragment>
                <Expresssion
                  actionType={actionType}
                  kpisList={kpisList}
                  period={period}
                  budgetVersionCode={budgetVersionCodeValue}
                  formula={kpiFormula}
                  kpiName={kpiName}
                  onPreviousButtonClick={previous}
                  clickedButton={clickedButton}
                  addKpi={addKpi}
                  updateKpi={updateKpi}
                ></Expresssion>
              </React.Fragment>
            )}
            {kpiType === "CONSTANT" && (
              <React.Fragment>
                <KpiConstant
                  actionType={actionType}
                  kpiConstantValue={kpiConstantValue}
                  onPreviousButtonClick={previous}
                  addKpi={addKpi}
                  updateKpi={updateKpi}
                  clickedButton={clickedButton}
                ></KpiConstant>
              </React.Fragment>
            )}
          </div>
        )}
      </Sidebar>

      {/* Start- SideBar for Search Kpis */}
      <Sidebar
        modal="true"
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderFilterKpis}
        id="sidebar-filter"
        visible={visibleSearchSideBar}
        position="right"
        className="p-sidebar-md"
        onHide={() => setVisibleSearchSideBar(false)}
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <InputMask
                  mask="9999/99"
                  id="yearMonth"
                  placeholder={t("placeholderPeriodField")}
                  value={year + "/" + month}
                  onChange={handleSearchPeriodChange}
                />
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={budgetVersionCodeValue}
                  options={budgetVersion}
                  filterList={filterBudgetVersionList}
                  onChange={handleBudgetVersionCodeChange}
                  placeholder={t("placeholderBudgetVersionCodeField")}
                  dropdown={true}
                  name="budgetVersionCode"
                  inputId="budgetVersionCode"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedKpiName}
                  options={kpisFiltredList}
                  filterList={filterkpiListName}
                  onChange={handleSelectedKpiNameChange}
                  placeholder={t("placeholderKpiNameField")}
                  name="KpiName"
                  inputId="KpiName"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedKpiDescription}
                  options={kpisFiltredList}
                  filterList={filterkpiListDescription}
                  onChange={handleSelectedKpiDescriptionChange}
                  placeholder={t("placeholderKpiDescriptionField")}
                  name="KpiDescription"
                  inputId="KpiDescription"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <Dropdown
                  className={classNameKpiType}
                  placeholder={t("placeholderKpiTypeField")}
                  value={selectedKpiType}
                  options={typeKpi}
                  onChange={handleSelecedKpiTypeChange}
                  optionValue="name"
                  optionLabel="name"
                  name="KpiType"
                  inputId="KpiType"
                ></Dropdown>
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  onClick={handleSearch}
                  label={t("findButton")}
                  className="p-button  p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => {
                    clearKpiFormFilters();
                  }}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={() => setVisibleSearchSideBar(false)}
                  className="p-button-secondary p-button-outlined  w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
    </React.Fragment>
  );
}
