import React from "react";
import FrontNavigation from "././FrontNavigation/FrontNavigation";
import tokenService from "../services/TokenService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokenService.isAuth()) {
      navigate("/");
    }
  });

  return (
    <div>
      <FrontNavigation />
    </div>
  );
}
