import React from "react";
import { Chip } from "primereact/chip";
import { FaRedo } from "react-icons/fa";

export default function FilterBadge(props) {
  const chipTemplate = item => {
    return (
      <React.Fragment>
        <span className="p-chip-text cursor-pointer" onClick={props.onClick}>
          {item.filterValue}
        </span>
        {item.removable && (
          <span
            tabIndex="0"
            className="p-chip-remove-icon pi pi-times-circle"
            onClick={() => props.onRemove(item.key)}
          ></span>
        )}
      </React.Fragment>
    );
  };

  const chips =
    props.filters !== undefined && props.filters.length > 0
      ? props.filters.map(item => {
          return (
            <Chip
              key={item.key}
              className="mr-2 mb-2"
              template={chipTemplate(item)}
            />
          );
        })
      : null;

  function unRemovableCount(filters) {
    let count = 0;

    filters.forEach(el => {
      if (!el.removable) count = count + 1;
    });

    return count;
  }

  return (
    <div className="flex flex align-items-center">
      <div className="flex align-items-center flex-wrap">{chips}</div>
      <div>
        {props.filters !== undefined &&
          props.filters.length > unRemovableCount(props.filters) && (
            <FaRedo
              className="cursor-pointer text-600"
              onClick={props.onClear}
            ></FaRedo>
          )}
      </div>
    </div>
  );
}
