import api from "./ApiService";
import fileDownload from "js-file-download";
class DepartmentService {
  getDepartmentByHotelId(hotelId, lazyParams, filters) {
    return api.get(hotelId + "/departments", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        departmentCode: filters.departmentCode,
        departmentName: filters.departmentName,
        searchMode: filters.searchMode,
      },
    });
  }

  lovGetDepartmentByHotelId(hotelId, filters) {
    return api.get(hotelId + "/departments/lov", {
      params: {
        departmentCode: filters.selectedDepartmentCode,
        departmentName: filters.selectedDepartmentName,
      },
    });
  }
  getDepartmentByDepartmentIdAndHotelId(hotelId, id) {
    return api.get(hotelId + "/departments/" + id);
  }

  deleteDepartmentByDepartmentIdAndHotelId(hotelId, id) {
    return api.delete(hotelId + "/departments/" + id);
  }
  updateDepartmentByHotelId(hotelId, departmentCode, departmentName) {
    return api.put(hotelId + "/departments/update", {
      departmentCode: departmentCode,
      departmentName: departmentName,
    });
  }
  addDepartmentByHotelId(hotelId, departmentCode, departmentName) {
    return api.post(hotelId + "/departments/add", {
      departmentCode: departmentCode,
      departmentName: departmentName,
    });
  }
  uploadDepartmentFile(hotelId, formData) {
    return api.post(hotelId + "/departments/import", formData);
  }
  exportDepartmentFile(hotelId, filters) {
    return api
      .get(
        hotelId + "/departments/export",
        {
          params: {
            departmentCode: filters.departmentCode,
            departmentName: filters.departmentName,
          },
        },
        { responseType: "blob" }
      )
      .then(res => {
        fileDownload(res.data, "Department.csv");
      });
  }
}
export default new DepartmentService();
