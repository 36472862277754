import api from './ApiService';


 class SideBarService {
    getMenuUser(){
        return api.get( 'menus/user_menus');
    }
    
   
  }
  export default new SideBarService()