import React from "react";
import ReactDOM from "react-dom";
import { Toolbar } from "primereact/toolbar";
import { Sidebar } from "primereact/sidebar";
import { Link } from "react-router-dom";
import Profile from "./../User/Profile";
import { useState } from "react";

export default function FrontNavigation(props) {
  const [visibleSidebarLeft, setVisibleSideBarLeft] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const leftContents = (
    <React.Fragment>
      <div id="leftbarIcon">
        <i className="pi pi-bars" onClick={() => setVisibleSideBarLeft(true)} />
      </div>
      <Sidebar
        id="op-sidebar"
        visible={visibleSidebarLeft}
        onHide={() => setVisibleSideBarLeft(false)}
      >
        operandi
      </Sidebar>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <div id="toolbar-action-right">
        <div id="toolbar-cog-button">
          <Link to="/ControlPanel/Accounts">
            <i className="pi pi-cog p-toolbar-separator mr-2" />
          </Link>
        </div>
        <div id="toolbar-logo">
          <img
            src={`https://app.operandi.travel/images/logos/${props.hotelLogo}`}
            alt="user logo"
            onClick={() => setVisibleProfile(true)}
          />
          <Profile
            visibleProfile={visibleProfile}
            userData={["Ahlem", "touati"]}
          />
        </div>

        <div id="toolbar-sign-out-button"></div>
      </div>
    </React.Fragment>
  );

  return <Toolbar id="op-toolbar" left={leftContents} right={rightContents} />;
}
