import React, { useState, useEffect, useCallback, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import * as moment from "moment";
import { InputNumber } from "primereact/inputnumber";

import DataControls from "../../components/DataTools/DataControls";
import MemoTransactioService from "../../services/MemoTransactioService";
import { getLocalStorage } from "../../services/LocalStorage";
import CustomFilterSideBar from "../../components/CustomFilterSideBar";
import AccountService from "../../services/AccountService";
import DepartmentService from "../../services/DepartmentService";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import { useLanguageContext } from "../../context/GlobalProvider";
import {
  showErrorToast,
  showSuccessToast,
  showWarnToast,
} from "../../components/CustomToast";

import { actionBodyTemplate } from "../../utils/DataTableAction";

export default function MemoTransaction(props) {
  const [totalRecords, setTotalRecords] = useState(0);
  const [memoTransactionData, setMemoTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [visibleAddMemoSidebar, setVisibleAddMemoSidebar] = useState(false);
  const [accountCode, setAccountCode] = useState("");
  const [departmentCode, setDepartmentCode] = useState("");
  const [transactionDate, setTransactionDate] = useState(null);
  const [description, setDescription] = useState("");
  const [visibleUpdateMemoSidebar, setVisibleUpdateMemoSidebar] =
    useState(false);
  const [visibleFilterSideBar, setVisibleFilterSideBar] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedAccountCode, setSelectedAccountCode] = useState("");
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [month, setMonth] = useState(getLocalStorage("month"));
  const [year, setYear] = useState(getLocalStorage("year"));
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [accountCodeList, setAccountCodeList] = useState([]);
  const [departmentCodeList, setDepartmentCodeList] = useState([]);
  const [memoTransactionFilterBadges, setMemoTransactionFilterBadges] =
    useState([]);
  const [filters, setFilters] = useState({
    accountCode: "",
    departmentCode: "",
    description: "",
    amount: "",
    month: month,
    year: year,
  });
  const toast = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const onSort = event => {
    setLazyParams(event);
  };
  const onPage = event => {
    setLazyParams(event);
  };
  const { t } = useLanguageContext();
  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(false);

  function formatPeriod(year, month) {
    return year + "/" + month;
  }

  useEffect(() => {
    if (props.selectedHotel) {
      setMemoTransactionFilterBadges([
        { key: "1", filterValue: formatPeriod(year, month), removable: false },
      ]);
      setMonth(month);
      setYear(year);
    }
  }, [props.selectedHotel]);

  const loadLazyMemoTransactionData = useCallback(() => {
    setLoading(true);
    MemoTransactioService.getMemoTransactionByHotelId(
      DefaultHotelId,
      lazyParams,
      filters
    ).then(res => {
      setMemoTransactionData(res.data.transactions);
      setTotalRecords(res.data.totalRecords);
      setLoading(false);
    });
  }, [DefaultHotelId, lazyParams, filters]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyMemoTransactionData();
    }
  }, [loadLazyMemoTransactionData, props.selectedHotel]);

  function handleSelectedAccountCodeChange(event) {
    setSelectedAccountCode(event.target.value);
  }
  function handleSelectedDepartmentCodeChange(event) {
    setSelectedDepartmentCode(event.target.value);
  }
  function handleSelectedDesciptionChange(event) {
    setSelectedDescription(event.target.value);
  }
  function handleAmountChange(event) {
    setAmount(event.value);
  }
  function clearMemoTransactionFilters() {
    setSelectedAccountCode("");
    setSelectedDepartmentCode("");
    setSelectedDescription("");
    setAmount("");
  }
  function dialogCallBack() {
    setYear(getLocalStorage("year"));
    setMonth(getLocalStorage("month"));
    setDialogVisible(false);
  }

  function handleFormatDate(value = new Date()) {
    return moment(value, "DD-MM-YYYY").format();
  }
  function saveMemoTransaction() {
    setClickedButton(true);
    MemoTransactioService.addMemoTransactionByHotelId(
      DefaultHotelId,
      year,
      month,
      accountCode.id,
      departmentCode.id,
      amount,
      description,
      handleFormatDate(transactionDate)
    )
      .then(res => {
        setAccountCode("");
        setDepartmentCode("");
        setAmount("");
        setDescription("");
        setTransactionDate(null);
        loadLazyMemoTransactionData();
        showSuccessToast(toast, t, "add");
        setClickedButton(false);
        setVisibleAddMemoSidebar(false);
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }

  function editMemoTransaction(e) {
    setClickedButton(true);
    e.preventDefault();
    handleFormatDate(transactionDate);

    MemoTransactioService.updateTransactionByHotelId(
      DefaultHotelId,
      transactionId,
      year,
      month,
      accountCode,
      departmentCode,
      description,
      handleFormatDate(transactionDate),
      amount
    )
      .then(res => {
        setClickedButton(false);
        showSuccessToast(toast, t, "update");
        loadLazyMemoTransactionData();
        setVisibleUpdateMemoSidebar(false);
        setAccountCode("");
        setDepartmentCode("");
        setAmount("");
        setDescription("");
        setTransactionDate(null);
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }
  function handleAccountCodeChange(event) {
    setAccountCode(event.target.value);
  }
  function handleDepartmentCodeChange(event) {
    setDepartmentCode(event.target.value);
  }

  function handleVisibleFilterSideBar() {
    setVisibleFilterSideBar(false);
  }
  function handleTransactionDateChange(event) {
    setTransactionDate(event.value);
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function handleButtonClick() {
    setVisibleAddMemoSidebar(true);
  }
  function getSelectedRow(rowdata) {
    setAccountCode(rowdata.accountCode);
    setDepartmentCode(rowdata.departmentCode);
    setAmount(rowdata.amount);
    setTransactionDate(new Date(rowdata.transactionDate));
    setDescription(rowdata.description);
    setTransactionId(rowdata.transactionId);
  }
  function deleteMemoTransaction(transactionId) {
    MemoTransactioService.deleteTransactionByHotelIdAndId(
      DefaultHotelId,
      transactionId
    )
      .then(res => {
        setMemoTransactionData(
          memoTransactionData.filter(
            value => value.transactionId !== transactionId
          )
        );
        showSuccessToast(toast, t, "delete");
      })
      .catch(error => {
        showErrorToast(error.response.data.message);
      });
  }

  function handleSearchButtonClick() {
    setVisibleFilterSideBar(true);
  }
  const formatFiltredAmount = value => {
    return parseFloat(amount).toFixed(3);
  };
  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];

    newFilters.push({
      key: "1",
      filterValue: formatPeriod(year, month),
      removable: false,
    });
    if (selectedAccountCode.id || selectedAccountCode) {
      newFilters.push({
        key: "2",
        filterValue: selectedAccountCode.id || selectedAccountCode,
        removable: true,
      });
    }

    if (selectedDepartmentCode.id || selectedDepartmentCode) {
      newFilters.push({
        key: "3",
        filterValue: selectedDepartmentCode.id || selectedDepartmentCode,
        removable: true,
      });
    }
    if (selectedDescription.id || selectedDescription) {
      newFilters.push({
        key: "4",
        filterValue: selectedDescription.id || selectedDescription,
        removable: true,
      });
    }
    if (amount) {
      newFilters.push({
        key: "5",
        filterValue: formatFiltredAmount(amount),
        removable: true,
      });
    }
    setFilters({
      accountCode: selectedAccountCode.id || selectedAccountCode,
      departmentCode: selectedDepartmentCode.id || selectedDepartmentCode,
      description: selectedDescription.id || selectedDescription,
      amount: amount,
      month: month,
      year: year,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setMemoTransactionFilterBadges(newFilters);

    setVisibleFilterSideBar(false);
  }
  function handleFilterBadgeClear() {
    setMemoTransactionFilterBadges([
      { key: "1", filterValue: formatPeriod(year, month), removable: false },
    ]);
    setSelectedAccountCode("");
    setSelectedDepartmentCode("");
    setSelectedDescription("");
    setAmount("");

    setFilters({
      accountCode: "",
      departmentCode: "",
      description: "",
      amount: "",
      month: month,
      year: year,
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "2":
        setSelectedAccountCode("");
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
        break;
      case "3":
        setSelectedDepartmentCode("");
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
        break;
      case "4":
        setSelectedDescription("");
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
        break;

      default:
        setFilters({
          accountCode: "",
          departmentCode: "",
          description: "",
          amount: "",
          month: month,
          year: year,
        });
    }
    setMemoTransactionFilterBadges(current =>
      current.filter(el => el.key !== key)
    );
  }

  const formatDate = value => {
    return new Date(value).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const dateBodyTemplate = rowData => {
    return formatDate(rowData.transactionDate);
  };
  const formatAmount = value => {
    return value.toLocaleString("en", { minimumFractionDigits: 3 });
  };
  const amountBodyTemplate = rowdata => {
    return formatAmount(rowdata.amount);
  };
  const customHeaderNewMemo = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleAddMemoSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("newMemoTransactionSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderEditMemo = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => resetForm()}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("editMemoTransactionSidebarTitle")}
      </div>
    </React.Fragment>
  );

  function handlePeriodChange(event) {
    setYear(event.target.value.substring(0, 4));
    setMonth(event.target.value.substring(5));
  }
  const dialogFooter = (
    <div>
      <Button
        label="OK"
        className="p-button-danger"
        onClick={() => {
          dialogCallBack();
        }}
      />
    </div>
  );

  function exportCSVMemoTransactionFile() {
    MemoTransactioService.exportMemoTransactionFile(
      DefaultHotelId,
      filters
    ).then(res => {
      showWarnToast(toast, t);
    });
  }

  function resetForm() {
    setAccountCode("");
    setDepartmentCode("");
    setAmount("");
    setDescription("");
    setTransactionDate("");
    setVisibleUpdateMemoSidebar(false);
  }
  function filteredAccountCode(filterValue) {
    AccountService.lovGetAccountByHotelId(DefaultHotelId, {
      selectedAccountCode: filterValue,
      selectedAccountName: "",
      selectedAccountType: "Memo",
      searchMode: "AND",
    }).then(res => {
      setAccountCodeList(
        res.data.map(item => ({
          id: item.accountCode,
        }))
      );
    });
  }
  function filteredDepartmentCode(filterValue) {
    DepartmentService.lovGetDepartmentByHotelId(DefaultHotelId, {
      selectedDepartmentCode: filterValue,
      selecteddepartmentName: "",
      searchMode: "OR",
    }).then(res => {
      setDepartmentCodeList(
        res.data.map(item => ({
          id: item.departmentCode,
        }))
      );
    });
  }
  function handleVisibleEditSidebar() {
    setVisibleUpdateMemoSidebar(true);
  }
  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <DataControls
        addButtonLabel={t("addButton")}
        onAddButtonClick={handleButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        filters={memoTransactionFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        exportCSV={exportCSVMemoTransactionFile}
        displayedImportExportDataControl="true"
        displayedDataControl="true"
      />

      <ConfirmDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        message={dialogMessage}
        footer={dialogFooter}
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        header="Information"
      />
      <DataTable
        value={memoTransactionData}
        className="relative w-full"
        lazy
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
        responsiveLayout="scroll"
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column field="accountCode" sortable header={t("accountCode")} />
        <Column
          field="account.accountName"
          sortable
          header={t("accountName")}
        />
        <Column field="departmentCode" sortable header={t("departmentCode")} />
        <Column
          field="department.departmentName"
          sortable
          header={t("departmentName")}
        />
        <Column
          field="transactionDate"
          sortable
          header={t("transactionDate")}
          dataType="date"
          body={dateBodyTemplate}
        />
        <Column field="description" sortable header={t("description")} />
        <Column
          field="amount"
          body={amountBodyTemplate}
          sortable
          header={t("transactionAmount")}
        />
        <Column
          body={rowdata => {
            return actionBodyTemplate(
              rowdata,
              t,
              handleVisibleEditSidebar,
              getSelectedRow,
              rowdata.transactionId,
              deleteMemoTransaction
            );
          }}
        />
      </DataTable>

      {/* Start- SideBar for add Memo */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderNewMemo}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleAddMemoSidebar}
        position="right"
        onHide={() => setVisibleAddMemoSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="accountCode" className="font-medium text-900">
                {t("accountCode")}
              </label>
              <CustomAutoComplete
                value={accountCode}
                options={accountCodeList}
                filterList={filteredAccountCode}
                onChange={handleAccountCodeChange}
                placeholder="Select a Account Code"
                dropdown={false}
                inputId="accountCode"
              ></CustomAutoComplete>
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="departmentCode" className="font-medium text-900">
                {t("departmentCode")}
              </label>
              <CustomAutoComplete
                value={departmentCode}
                options={departmentCodeList}
                filterList={filteredDepartmentCode}
                onChange={handleDepartmentCodeChange}
                placeholder="Select a Department Code"
                dropdown={false}
                inputId="departmentCode"
              ></CustomAutoComplete>
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="description" className="font-medium text-900">
                {t("description")}
              </label>
              <InputText
                placeholder={t("placeholderDescriptionField")}
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                id="description"
              />
            </div>

            <div className="field mb-4 col-12">
              <label htmlFor="transactionDate" className="font-medium text-900">
                {t("transactionDate")}
              </label>
              <Calendar
                placeholder={t("placeholderTransactionDateField")}
                inputId="transactionDate"
                type="date"
                value={transactionDate}
                onChange={handleTransactionDateChange}
                dateFormat="mm/dd/yy"
                showIcon
              />
            </div>

            <div className="field mb-4 col-12">
              <label htmlFor="amount" className="font-medium text-900">
                {t("amount")}
              </label>
              <InputNumber
                inputId="amount"
                value={amount}
                onValueChange={handleAmountChange}
                mode="decimal"
                locale="en-US"
                minFractionDigits={3}
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("saveButton")}
                icon="pi pi-plus"
                loading={clickedButton}
                onClick={() => saveMemoTransaction()}
                className="p-button w-auto  p-button-success mr-2"
              />
              <Button
                label={t("cancelButton")}
                onClick={() => setVisibleAddMemoSidebar(false)}
                className="p-button-secondary p-button-outlined  w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>

      {/* Start- SideBar for edit Memo */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderEditMemo}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleUpdateMemoSidebar}
        position="right"
        onHide={() => setVisibleUpdateMemoSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="accountCode" className="font-medium text-900">
                {t("accountCode")}
              </label>
              <CustomAutoComplete
                value={accountCode}
                options={accountCodeList}
                filterList={filteredAccountCode}
                onChange={handleAccountCodeChange}
                placeholder="Select a Account Code"
                dropdown={false}
                inputId="accountCode"
              ></CustomAutoComplete>
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="departmentCode" className="font-medium text-900">
                {t("departmentCode")}
              </label>
              <CustomAutoComplete
                value={departmentCode}
                options={departmentCodeList}
                filterList={filteredDepartmentCode}
                onChange={handleDepartmentCodeChange}
                placeholder="Select a Department Code"
                dropdown={false}
                inputId="departmentCode"
              ></CustomAutoComplete>
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="description" className="font-medium text-900">
                {t("description")}
              </label>
              <InputText
                id="description"
                type="text"
                value={description}
                onChange={handleDescriptionChange}
              />
            </div>

            <div className="field mb-4 col-12">
              <label htmlFor="transactionDate" className="font-medium text-900">
                {t("transactionDate")}
              </label>
              <Calendar
                inputId="transactionDate"
                id="icon"
                type="date"
                value={transactionDate}
                onChange={handleTransactionDateChange}
                dateFormat="mm/dd/yy"
                showIcon
              />
            </div>

            <div className="field mb-4 col-12">
              <label htmlFor="amount" className="font-medium text-900">
                {t("amount")}
              </label>
              <InputNumber
                inputId="amount"
                value={amount}
                onValueChange={handleAmountChange}
                mode="decimal"
                locale="en-US"
                minFractionDigits={3}
              />
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("updateButton")}
                onClick={editMemoTransaction}
                loading={clickedButton}
                className="p-button w-auto p-button-success mr-2"
              />
              <Button
                label={t("cancelButton")}
                onClick={() => resetForm()}
                className="p-button-secondary p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <CustomFilterSideBar
        visibleFilterSideBar={visibleFilterSideBar}
        month={month}
        year={year}
        selectedAccountCode={selectedAccountCode}
        selectedDepartmentCode={selectedDepartmentCode}
        selectedDescription={selectedDescription}
        amount={amount}
        handleSearch={handleSearch}
        handleAmountChange={handleAmountChange}
        handleSelectedDepartmentCodeChange={handleSelectedDepartmentCodeChange}
        handleSelectedDesciptionChange={handleSelectedDesciptionChange}
        handleSelectedAccountCodeChange={handleSelectedAccountCodeChange}
        handleVisibleFilterSideBar={handleVisibleFilterSideBar}
        handlePeriodChange={handlePeriodChange}
        clearFilters={clearMemoTransactionFilters}
        selectedAccountType="Memo"
      ></CustomFilterSideBar>
    </React.Fragment>
  );
}
