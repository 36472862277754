import React from "react";
import { AutoComplete } from "primereact/autocomplete";

export default function CustomAutoComplete(props) {
  function handleFilter(event) {
    props.filterList(event.query);
  }

  const itemTemplate = item => {
    return (
      <div className="flex align-items-center">
        <div style={{ marginRight: "21px" }}>{item.id}</div>
        <div>{item.name}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <AutoComplete
        value={props.value}
        suggestions={props.options}
        onChange={props.onChange}
        completeMethod={handleFilter}
        field="id"
        dropdown={props.dropdown}
        placeholder={props.placeholder}
        itemTemplate={itemTemplate}
        disabled={props.disabled}
        name={props.name}
        inputId={props.inputId}
        
      ></AutoComplete>
    </React.Fragment>
  );
}
