import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "./assets/myprimeflex/primeflex.css";
import "highcharts/css/highcharts.css";
import "./i18n";
import GlobalProvider from "./context/GlobalProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// If you want to start measuring performance in your app, pass a function to log results
reportWebVitals();
