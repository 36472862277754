import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaRegBell } from "react-icons/fa";
import { Sidebar } from "primereact/sidebar";

import LeftMenu from "../LeftMenu";
import Profile from "../User/Profile";
import UserService from "../../services/UserService";
import TokenService from "../../services/TokenService";
import { getLocalStorage, setLocalStorage } from "../../services/LocalStorage";
import { clearLocalStorage } from "../../services/LocalStorage";
import { showErrorToast } from "../../components/CustomToast";
import { useLanguageContext } from "../../context/GlobalProvider";
import BudgetVersionsService from "../../services/BudgetVersionsService";

//Use in a component
export default function BackNavigation(props) {
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [userData, setUserData] = useState(getLocalStorage("userHotels"));
  const [hotelName, setHotelName] = useState(TokenService.getHotelName());
  const [hotelLogo, setHotelLogo] = useState("");
  const [groupHotelName, setGroupHotelName] = useState("");
  const [selectedHotel, setSelectedHotel] = useState(
    getLocalStorage("defaultHotelId")
  );
  const [hotelListName, setHotelListName] = useState([]);
  const [finalFullName, setFinalFullName] = useState("");

  //For visible sidebar
  const [visibleSideBarNotification, setVisibleSideBarNotification] =
    useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { t } = useLanguageContext();

  //Get Budget Version Code
  useEffect(() => {
    BudgetVersionsService.getBudgetVersionsByHotelId(
      getLocalStorage("defaultHotelId"),
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        budgetVersionCode: "",
        description: "",
      }
    ).then(res => {
      setLocalStorage(
        "budgetVersionCode",
        res.data.budgetVersions[0].budgetVersionId.budgetVersionCode
      );
    });
  }, []);

  useEffect(() => {
    if (props.selectedHotel) {
      getLocalStorage("userHotels").forEach(el => {
        if (el.hotelId === props.selectedHotel) {
          setHotelName(el.name);
          setHotelLogo(el.logo);
          setGroupHotelName(el.groupHotels.name);
        }
      });
    }
    UserService.getUserInformation(getLocalStorage("username"))
      .then(res => {
        res.data.apiRoles.forEach(el => {
          if (el.apiRoleId === 1) {
            setLocalStorage("role", el.name);
          }
        });

        setLocalStorage("userId", res.data.apiUserId);
        setLocalStorage("firstName", res.data.firstName);
        setLocalStorage("lastName", res.data.lastName);
        setLocalStorage("password", res.data.apiPassword);
        setUserData(res.data);
        let initial_full_name = res.data.firstName + " " + res.data.lastName;

        let full_name = initial_full_name.split(" ");
        let final_full_name = full_name[0][0];

        if (initial_full_name) {
          if (full_name.length >= 2 && full_name[1]) {
            final_full_name += full_name[1][0];
          }
        } else {
          final_full_name = "";
        }
        setFinalFullName(final_full_name);
        UserService.getClosedPeriod(getLocalStorage("defaultHotelId"))
          .then(res => {
            setLocalStorage(
              "year",
              String(res.data.closedPeriod).substring(0, 4)
            );
            setLocalStorage(
              "month",
              String(res.data.closedPeriod).substring(5)
            );
          })
          .catch(error => {
            showErrorToast(toast, t, error);
          });
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }, [props.selectedHotel, t]);

  useEffect(() => {
    setHotelListName(
      getLocalStorage("userHotels").map(item => ({
        id: item.hotelId,
        name: item.name,
        logo: item.logo,
        pilot: item.pilot,
      }))
    );
  }, []);
  const handleLogout = () => {
    clearLocalStorage();
    navigate("/");
  };
  function onHotelChange(e) {
    setSelectedHotel(e.target.value);
    setLocalStorage("defaultHotelId", e.target.value);
    props.onHandleSelectHotel(e.target.value);
  }

  const selectedHotelTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value  flex align-items-center">
          <img
            alt={option.name}
            src={`https://app.operandi.travel/images/logos/${option.logo}`}
            style={{ width: "20px", height: "20px" }}
            onError={e =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`border-circle  flag flag-${option.name.toLowerCase()}`}
          />
          <div className="selected-hotel-name">{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const hotelOptionTemplate = option => {
    return (
      <React.Fragment>
        <div className="country-item  flex align-items-center">
          <img
            alt={option.name}
            src={`https://app.operandi.travel/images/logos/${option.logo}`}
            style={{ width: "17px" }}
            onError={e =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.name.toLowerCase()}`}
          />
          <div
            className="hotel-name"
            style={{ marginLeft: "12px", color: option.pilot ? "red" : "" }}
          >
            {option.name}
          </div>
        </div>
      </React.Fragment>
    );
  };
  function handleMenuHide() {
    setVisibleLeft(false);
    props.onLeftMenuStateChange(false);
  }
  function handleProfileHide() {
    setVisibleProfile(false);
  }
  const leftContents = (
    <React.Fragment>
      {!props.leftMenuState && (
        <React.Fragment>
          <i
            className="pi pi-bars text-xl mr-2   cursor-pointer"
            style={{ color: "white" }}
            onClick={() => {
              setVisibleLeft(true);
              props.onLeftMenuStateChange(true);
            }}
          ></i>
          <Link to="/LandingPage">
            <img
              className="cursor-pointer"
              src="../assets/img/operandi-logo.png"
              alt="operandi logo"
              style={{ width: "100%", marginLeft: "2%" }}
            />
          </Link>
        </React.Fragment>
      )}
      <div>
        <Dropdown
          className="hotel-list-dropdown ml-5"
          valueTemplate={selectedHotelTemplate}
          itemTemplate={hotelOptionTemplate}
          value={selectedHotel}
          options={hotelListName}
          onChange={onHotelChange}
          optionLabel="name"
          optionValue="id"
          filter
          filterBy="name"
          filterMatchMode="startsWith"
          placeholder="Select a Hotel"
          name="hotelListDropDown"
          inputId="hotelListDropDown"
        />
      </div>
      <LeftMenu visibleLeft={visibleLeft} onMenuHide={handleMenuHide} />
    </React.Fragment>
  );

  const rightContents = option => {
    return (
      <React.Fragment>
        <ul className="list-none p-0 -m-5  hidden lg:flex lg:align-items-center select-none lg:flex-row  border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
          <li>
            <FaRegBell
              className="text-4xl	text-50	cursor-pointer"
              cass="true"
              onClick={() => {
                setVisibleSideBarNotification(true);
              }}
            />
            <Sidebar
              position="right"
              visible={visibleSideBarNotification}
              style={{ width: "500px" }}
              onHide={() => setVisibleSideBarNotification(false)}
            ></Sidebar>
          </li>
          <li>
            <div
              className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center  font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full"
            >
              <div>
                <div
                  className="profile-class"
                  onClick={() => setVisibleProfile(true)}
                >
                  <span style={{ color: "white" }}>{finalFullName}</span>
                </div>
                <Profile
                  logout={handleLogout}
                  userData={userData}
                  hotelLogo={hotelLogo}
                  hotelName={hotelName}
                  groupHotelName={groupHotelName}
                  visibleProfile={visibleProfile}
                  onProfileHide={handleProfileHide}
                />
              </div>
            </div>
          </li>
        </ul>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Toolbar
        className="overflow-hidden backNavigation-toolbar"
        left={leftContents}
        right={rightContents}
      />
    </React.Fragment>
  );
}
