import api from "./ApiService";
import fileDownload from "js-file-download";

class TransactionService {
  getTransactionByHotelId(hotelId, lazyParams, filters) {
    return api.get(hotelId + "/transactions", {
      params: {
        month: filters.month,
        year: filters.year,
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        accountCode: filters.accountCode,
        departmentCode: filters.departmentCode,
        description: filters.description,
        searchMode: filters.searchMode,
        amount: filters.amount,
      },
    });
  }
  uploadTransactionFile(hotelId, formData) {
    return api.post(hotelId + "/transactions/import", formData);
  }
  uploadTransactionFileProgress(hotelId, groupHotelId) {
    return api.get(hotelId + "/transactions/progress", {
      params: {
        groupHotelId: groupHotelId,
      },
    });
  }

  lovGetTransactionByHotelId(hotelId, filters) {
    return api.get(hotelId + "/transactions/lov", {
      params: {
        description: filters.selectedDescription,
        amount: filters.selectedAmount,
      },
    });
  }
  exportTransactionFile(hotelId, filters) {
    return api
      .get(
        hotelId + "/transactions/export",
        {
          params: {
            year: filters.year,
            month: filters.month,
            accountCode: filters.accountCode,
            departmentCode: filters.departmentCode,
            description: filters.description,
            amount: filters.amount,
          },
        },

        { responseType: "blob" }
      )
      .then(res => {
        fileDownload(res.data, "Transaction.csv");
      });
  }
  getLastImportDate(hotelId) {
    return api.get(hotelId + "/transactions/last-import-date");
  }
}

export default new TransactionService();
