import React from "react";
import { Tooltip } from "primereact/tooltip";

const CustomFilterSideBarHeader = (onVisibleFilterSideBar, t) => {
  return (
    <React.Fragment>
      <Tooltip target=".custom-target-icon" />

      <button
        data-pr-tooltip={t("closeDataTooltip")}
        type="button"
        className="custom-target-icon p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={onVisibleFilterSideBar}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div></div>

      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );
};

export { CustomFilterSideBarHeader };
