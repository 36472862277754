import { confirmDialog } from "primereact/confirmdialog";

const CustomConfirmDialogForKpiAssignment = (t, item, message, deleteItem) => {
  return confirmDialog({
    message: message,
    header: t("headerTitleDeleteConfirmDialog"),
    icon: "pi pi-info-circle",
    acceptClassName: "p-button-danger",
    accept: () => deleteItem(item),
    acceptLabel: t("acceptLabelConfirmDialog"),
    rejectLabel: t("rejectLabelConfirmDialog"),
  });
};

export { CustomConfirmDialogForKpiAssignment };
