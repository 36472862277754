import api from "./ApiService";

class GroupKpisService {
  getGroupKpisByHotelId(hotelId, lazyParams, filters) {
    return api.get(hotelId + "/group_kpis", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        groupKpiId: filters.groupKpiId,
        name: filters.name,
        searchMode: filters.searchMode,
      },
    });
  }
  getGroupKpisByHotelIdAndId(hotelId, id) {
    return api.get(hotelId + "/group_kpis/" + id);
  }

  lovGetGroupKpiByHotelId(hotelId, filters) {
    return api.get(hotelId + "/group_kpis/lov", {
      params: {
        groupKpiId: filters.selectedGroupKpiId,
        name: filters.selectedGroupKpisName,
      },
    });
  }
  addGroupKpisByHotelId(hotelId, groupKpiId, name, sort) {
    return api.post(hotelId + "/group_kpis/add", {
      groupKpiId: groupKpiId,
      name: name,
      sort: sort,
    });
  }
  updateGroupKpisByHotelId(hotelId, groupKpiId, name, sort) {
    return api.put(hotelId + "/group_kpis/update", {
      groupKpiId: groupKpiId,
      name: name,
      sort: sort,
    });
  }
  deleteGroupKpiByHotelIdAndId(hotelId, id) {
    return api.delete(hotelId + "/group_kpis/" + id);
  }
}
export default new GroupKpisService();
