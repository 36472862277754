import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Link } from "react-router-dom";

import DynamicFaIcon from "../components/Icon";
import { getLocalStorage } from "../services/LocalStorage";
import { useLanguageContext } from "../context/GlobalProvider";
import { getMenuItemName } from "../utils/MenuItemName";

export default function LeftMenu(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useLanguageContext();

  const customIcons = (
    <React.Fragment>
      <div className="flex align-items-center flex-shrink-0">
        <i
          className="pi pi-bars text-xl ml-2 text-white cursor-pointer "
          style={{ color: "black" }}
          onClick={props.onMenuHide}
        ></i>

        <Link to="/LandingPage">
          <img
            src="../assets/img/operandi-logo.png"
            className="ml-2 cursor-pointer"
            alt="operandi logo"
            style={{ width: "100%", marginLeft: "2%" }}
          />
        </Link>
      </div>
    </React.Fragment>
  );
  return (
    <Sidebar
      showCloseIcon={false}
      icons={customIcons}
      modal={false}
      id="left-menu-sidebar"
      className=" h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none"
      style={{ width: "255px" }}
      visible={props.visibleLeft}
      onHide={props.onMenuHide}
    >
      <div className="flex flex-column">
        <div className="mt-0">
          <ul className="menu-item-list list-none p-3 m-0">
            {getLocalStorage("menu").map((item, index) => {
              return (
                <li className="nav-item" key={index}>
                  <Link
                    to={item.path}
                    className={`menu-link no-underline p-ripple flex align-items-center cursor-pointer p-2   border-round  transition-duration-150 transition-colors link-menu-item${
                      index === selectedIndex ? " selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                  >
                    <span className="pi mr-2">
                      <DynamicFaIcon name={item.iconName} />
                    </span>

                    <span className="font-medium">
                      {getMenuItemName(t, item.menuId)}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className="flex align-items-center ml-1"
        style={{ position: "fixed", width: "100%", bottom: 0 }}
      >
        <span>{process.env.REACT_APP_FD_VERSION}</span>
      </div>
    </Sidebar>
  );
}
