import api from "./ApiService";

class BudgetVersionsService {
  getBudgetVersionsByHotelId(hotelId, lazyParams, filters) {
    return api.get(hotelId + "/budget_versions", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        budgetVersionCode: filters.budgetVersionCode,
        description: filters.description,
      },
    });
  }
  getBudgetVersionsByHotelIdAndId(hotelId, id) {
    return api.get(hotelId + "/budget_versions/" + id);
  }

  lovGetBudgetVersionByHotelId(hotelId, filters) {
    return api.get(hotelId + "/budget_versions/lov", {
      params: {
        budgetVersionCode: filters.selectedBudgetVersionCode,
        description: filters.selectedBudgetVersionDescription,
      },
    });
  }
  addBudgetVersionsByHotelId(hotelId, budgetVersionCode, description) {
    return api.post(hotelId + "/budget_versions/add", {
      budgetVersionCode: budgetVersionCode,
      description: description,
    });
  }
  updateBudgetVersionsByHotelId(hotelId, budgetVersionCode, description) {
    return api.put(hotelId + "/budget_versions/update", {
      budgetVersionCode: budgetVersionCode,
      description: description,
    });
  }
  deleteBudgetVersionsByHotelIdAndId(hotelId, id) {
    return api.delete(hotelId + "/budget_versions/" + id);
  }
}

export default new BudgetVersionsService();
