import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translation/En.json";
import translationFr from "./translation/Fr.json";
import { getLocalStorage } from "./services/LocalStorage";

//Creating object with the variables of imported translation files
const resources = {
  EN: {
    translation: translationEN,
  },
  FR: {
    translation: translationFr,
  },
};
// const LanguageDetector = {
//   type: "languageDetector",
//   detect: () => {
//     setLocalStorage("language", "");
//     return "EN";
//   },
//   init: () => {},
//   cacheUserLanguage: () => {},
// };
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getLocalStorage("language"),
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
