import ReactDOM from "react-dom";
import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";

import AuthenticationService from "../../services/AuthenticationService";
import TokenService from "../../services/TokenService";
import SidebarService from "../../services/SidebarService";
import UserService from "../../services/UserService";
import { getLocalStorage, setLocalStorage } from "../../services/LocalStorage";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast } from "../../components/CustomToast";
import { useAuthContext } from "../../context/GlobalProvider";

export default function Login(props) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorsUserName, setErrorsUserName] = useState("");
  const [classNameUserName, setClassNameUserName] = useState("");
  const [errorsPassword, setErrorsPassword] = useState("");
  const [classNamePassword, setClassNamePassword] = useState("");
  const navigate = useNavigate();
  const toast = useRef(null);
  const { t } = useLanguageContext();
  const { isAuth, setAuth } = useAuthContext();
  //console.log(isAuth)
  function onChangeUserName(e) {
    setUserName(e.target.value);
    setErrorsUserName("");
    setClassNameUserName("");
  }
  function onChangePassword(e) {
    setPassword(e.target.value);
    setErrorsPassword("");
    setClassNamePassword("");
  }

  useEffect(() => {
    getLocalStorage("menu");
  }, []);

  function handleLogin() {
    if (username.length === 0 && password.length === 0) {
      showErrorToast(
        toast,
        t,
        t("toastMessageErrorRequiredEmailPasswordFields")
      );

      setErrorsUserName(t("messageErrorRequiredEmail"));
      setClassNameUserName("p-invalid");
      setErrorsPassword(t("messageErrorRequiredPassword"));
      setClassNamePassword("p-invalid");
    } else {
      AuthenticationService.login(username, password)
        .then(response => {
          setLocalStorage("user", response.data);
          setAuth(response.data || false);
          TokenService.getLocalRefreshToken();
          setLocalStorage("username", username);
          SidebarService.getMenuUser()
            .then(res => {
              setLocalStorage("menu", res.data);

              UserService.getUserInformation(username)
                .then(res => {
                  setLocalStorage("language", res.data.language);
                  setLocalStorage("userHotels", res.data.hotels);
                  res.data.hotels.forEach(el => {
                    setLocalStorage("groupHotel", el.groupHotels.groupHotelsId);
                    setLocalStorage("groupHotelName", el.groupHotels.name);
                    if (el.pilot === true) {
                      setLocalStorage("pilotHotelName", el.name);
                      setLocalStorage("pilotHotelLogo", el.logo);
                      setLocalStorage("pilotHotelId", el.hotelId);
                    }
                  });
                  setLocalStorage("defaultHotelId", res.data.defaultHotelId);

                  setUserName("");
                  setPassword("");
                  //navigate("/ControlPanel/Accounts");
                  navigate("/LandingPage");
                })
                .catch(error => {
                  showErrorToast(toast, t, error);
                });
            })
            .catch(error => {
              showErrorToast(toast, t, error);
            });
        })
        .catch(error => {
          showErrorToast(toast, t, error);
        });
    }
  }

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <img
              src="../assets/img/operandi-logo-2.png"
              alt="operandi-logo"
              height={50}
              className="mb-3"
            />
            <div className="text-900 text-3xl font-medium mb-3">
              {t("loginPageTitle")}
            </div>
          </div>

          <div>
            <InputText
              className={`w-full mb-3 ${classNameUserName}`}
              placeholder={t("placeholderEmailAddressField")}
              value={username}
              onChange={onChangeUserName}
              id="emailAddress"
            />
            <div style={{ color: "red" }}>{errorsUserName}</div>

            <Password
              inputId="passwod"
              className={`password-input w-full mb-3 ${classNamePassword}`}
              placeholder={t("placeholderPasswordField")}
              value={password}
              onChange={onChangePassword}
              feedback={false}
              toggleMask
            />

            <div style={{ color: "red" }}>{errorsPassword}</div>
            <div className="flex align-items-center justify-content-between mb-6">
              {/* <div className="flex align-items-center">
                <Checkbox
                  id="rememberme3"
                  className="mr-2"
                  checked={checked}
                  onChange={e => setChecked(e.checked)}
                />

                <label htmlFor="rememberme">Remember me</label>
              </div> */}
              <Link
                to="/ForgetPassword"
                className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
              >
                {t("forgetPasswordLinkTitle")}
              </Link>
            </div>

            <Button
              label={t("signInButton")}
              className="w-full"
              onClick={() => {
                handleLogin();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
