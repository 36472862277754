function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
}

function getLocalStorage(key, initialValue) {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
}
function removeItemFromLocalStorage(particularKey) {
  const storage = JSON.parse(localStorage.getItem(particularKey));

  if (storage !== null) localStorage.removeItem(particularKey);
}

function clearLocalStorage() {
  let language = getLocalStorage("language");
  localStorage.clear();
  setLocalStorage("language", language);
}
export {
  setLocalStorage,
  getLocalStorage,
  removeItemFromLocalStorage,
  clearLocalStorage,
};
