import reportApi from "./ReportApiService";
import fileDownload from "js-file-download";

class ConcordanceReportService {
  printPdfReport(
    reportName,
    reportOutputName,
    reportId,
    reportParamString,
    exportFormat
  ) {
    return reportApi
      .get("print", {
        params: {
          reportName: reportName,
          reportOutputName: reportOutputName,
          reportId: reportId,
          reportParamString: reportParamString,
          exportFormat: exportFormat,
        },

        responseType: "blob",
        headers: {
          "Content-type": "application/pdf",
        },
      })
      .then(response => {
        const pdfOutputFilename = `${reportName}.pdf`;
        fileDownload(response.data, pdfOutputFilename);
      })
      .catch(error => {
        console.error(error);
      });
  }

  printExcelReport(
    reportName,
    reportOutputName,
    reportId,
    reportParamString,
    exportFormat
  ) {
    return reportApi
      .get("print", {
        params: {
          reportName: reportName,
          reportOutputName: reportOutputName,
          reportId: reportId,
          reportParamString: reportParamString,
          exportFormat: exportFormat,
        },

        responseType: "blob",
        headers: {
          "Content-type": "application/octet-stream",
        },
      })
      .then(response => {
        const excelOutputFilename = `${reportName}.xlsx`;
        fileDownload(response.data, excelOutputFilename);
      })
      .catch(error => {
        console.error(error);
      });
  }
  printCsvReport(
    reportName,
    reportOutputName,
    reportId,
    reportParamString,
    exportFormat
  ) {
    return reportApi
      .get("print", {
        params: {
          reportName: reportName,
          reportOutputName: reportOutputName,
          reportId: reportId,
          reportParamString: reportParamString,
          exportFormat: exportFormat,
        },
        responseType: "blob",
      })
      .then(response => {
        const csvOutputFilename = `${reportName}.csv`;
        fileDownload(response.data, csvOutputFilename);
      })
      .catch(error => {
        console.error(error);
      });
  }
}

export default new ConcordanceReportService();
