const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refresh_token;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.access_token;
  }
  getReportLocalAccessToken(){
    const reportUser = JSON.parse(localStorage.getItem("reportUser"));
    return reportUser?.access_token;

  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.access_token = token;

    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  
  isAuth() {
    const user = this.getUser();

    if (user) {
      const decodedJwt = parseJwt(user.access_token);

      if (decodedJwt.exp * 1000 > Date.now()) {
        return true;
      }
      return false;
    }

    return false;
  }

  checkTokenExpirationMiddleware() {
    const token =
      JSON.parse(localStorage.getItem("user")) &&
      JSON.parse(localStorage.getItem("user"))["token"];
    if (parseJwt(token).exp < Date.now() / 1000) {
      //next(action);
      localStorage.clear();
      return false;
    }
    return false;
    //next(action);
  }

  setMenu(menu) {
    localStorage.setItem("menu", JSON.stringify(menu));
  }
  getMenu() {
    return JSON.parse(localStorage.getItem("menu"));
  }
  setHotelName(hotelName) {
    localStorage.setItem("hotelName", JSON.stringify(hotelName));
  }
  getHotelName() {
    return JSON.parse(localStorage.getItem("hotelName"));
  }
  setToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  }
  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }
  removeToken() {
    localStorage.removeItem("token");
  }
 
 
 
}

export default new TokenService();
