import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import AuthenticationService from "../../services/AuthenticationService";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast, showSuccessToast } from "../../components/CustomToast";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useRef(null);
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }
  const forgetPassword = () => {
    setLoading(true);
    AuthenticationService.forgetPasswordRequest(email)
      .then(res => {
        showSuccessToast(toast, t, "forgetPassword");

        setLoading(false);
        setEmail("");
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  };

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <img
              src="../assets/img/operandi-logo-2.png"
              alt="operandi-logo"
              height={50}
              className="mb-3"
            />
            <div className="text-center m-5-auto">
              <h2>Forget password</h2>
            </div>

            {t("forgetPasswordMessage")}
            <p>
              <br />
              <InputText
                id="emailAddress"
                placeholder={t("placeholderEmailAddressField")}
                value={email}
                onChange={handleEmailChange}
                required
                type="email"
                className="w-full"
              />
            </p>
            <p>
              <Link
                to="/"
                className="text-xl  underline ml-2 text-blue-500 text-right cursor-pointer"
              >
                {/* Login */}
                {t("loginLinkTitle")}
              </Link>
            </p>
            <p>
              <Button
                loading={loading}
                id="sub_btn"
                label={t("resetPasswordButton")}
                onClick={forgetPassword}
              />
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
