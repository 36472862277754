const showSuccessToast = (toast, t, messageContent, messageDetail) => {
  return toast.current.show({
    severity: "success",
    summary:
      (messageContent === "add" && t("toastSummaryAdd")) ||
      (messageContent === "update" && t("toastSummaryUpdate")) ||
      (messageContent === "delete" && t("toastSummaryDelete")) ||
      (messageContent === "import" && t("toastMessageSuccessfullyUploaded")) ||
      (messageContent === "computeKpi" && t("toastKpiValueMessage")) ||
      (messageContent === "forgetPassword" && t("toastSummary")) ||
      (messageContent === "resetPassword" && t("toastSummary")),
    detail:
      (messageContent === "add" && t("toastMessageSuccessfullyAdded")) ||
      (messageContent === "update" && t("toastMessageSuccessfullyUpdated")) ||
      (messageContent === "delete" && t("toastMessageSuccessfullyDeleted")) ||
      (messageContent === "import" && t("toastMessageSuccessfullyUploaded")) ||
      (messageContent === "computeKpi" && messageDetail) ||
      (messageContent === "forgetPassword" &&
        t("successMessageForgetPassword")) ||
      (messageContent === "resetPassword" && t("successMessageResetPassword")),

    life: 60000,
  });
};
const showInfoToast = (toast, t) => {
  return toast.current.show({
    severity: "info",
    summary: t("toastMessageSuccessfullyUploaded"),
    detail: t("toastMessageFileUploaded"),
    life: 60000,
  });
};
const showWarnToast = (toast, t) => {
  return toast.current.show({
    severity: "warn",
    summary: t("toastSummaryExport"),
    detail: t("messageDataExported"),
    life: 60000,
  });
};
const showErrorToast = (toast, t, error) => {
 
  return toast.current.show({
    severity: "error",
    summary:
      (error.response.data.message && t("errorMessage")) ||
      (error.response.data.error_message && t("internalErrorMessageSummary")),
    detail:
      (error.response.data.message && error.response.data.message) ||
      (error.response.data.error_message && t("internalErrorMessageDetail")),
    life: 60000,
  });
};

export { showErrorToast, showInfoToast, showSuccessToast, showWarnToast };
