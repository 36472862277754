import api from "./ApiService";

class ApiHttpTraceService {
  getApiHttpTrace(lazyParams, filters) {
    return api.get("api_http_traces", {
      params: {
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        method: filters.method,
        uri: filters.uri,

        responseStatusCode: filters.responseStatusCode,
        requestBody: filters.requestBody,
        requestDate: filters.requestDate,
        apiUserName: filters.apiUserName,
        searchMode: filters.searchMode,
      },
    });
  }
  lovUsers(filters) {
    return api.get("/users/lov", {
      params: {
        apiUserName: filters.apiUserName,
        firstName: filters.firstName,
        lastName: filters.lastName,
      },
    });
  }
  lovGetPermission(filters) {
    return api.get("api_permission/lov", {
      params: {
        path: filters.path,
        description: filters.description,
      },
    });
  }
}
export default new ApiHttpTraceService();
