import React from "react";
import { Dialog } from "primereact/dialog";

export default function CustomDialog(props) {

    const dialogHeader = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
          <span className="text-xl text-900 font-bold text-red-600">
           {props.headerTitle}
          </span>
        </div>
      );
  return (
    <React.Fragment>
      <Dialog
        header={dialogHeader}
        visible={props.visibleDialog}
        maximizable
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={props.onHideDialog}
      >
        <div className="m-0">
          <div className="card">{props.dialogContent}</div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}



