import * as Icons from "react-icons/fa";
import React from "react";

const DynamicFaIcon = ({ name }) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent />;
};
export default DynamicFaIcon;
