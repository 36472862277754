import api from "./ApiService";
import fileDownload from "js-file-download";

class BudgetService {
  uploadBugetFile(formData) {
    return api.post("budget_items/import", formData).then(function (response) {
      console.log(JSON.stringify(response.data));
    });
  }
  getBudgetItems(hotelId, lazyParams, filters) {
    return api.get("budget_items", {
      params: {
        hotelId: hotelId,
        month: filters.month,
        year: filters.year,
        page: lazyParams.page,
        itemsPerPage: lazyParams.rows,
        isSortingAsc: lazyParams.sortOrder,
        sortingProperty: lazyParams.sortField,
        budgetVersionCode: filters.budgetVersionCode,
        accountCode: filters.accountCode,
        departmentCode: filters.departmentCode,
      },
    });
  }
  deleteBudgetItem(hotelId, year) {
    return api.delete("budget_items/delete", {
      params: { hotelId: hotelId, year: year },
    });
  }
  exportBudgetFile(hotelId, filters) {
    return api
      .get(
        "budget_items/export",
        {
          params: {
            hotelId: hotelId,
            month: filters.month,
            year: filters.year,
            accountCode: filters.accountCode,
            departmentCode: filters.departmentCode,
            budgetVersionCode: filters.budgetVersionCode,
          },
        },
        { responseType: "blob" }
      )
      .then(res => {
        fileDownload(res.data, "Budget.csv");
      });
  }
}

export default new BudgetService();
