import { confirmDialog } from "primereact/confirmdialog";

const ConfirmDelete = (t, item, deleteItem) => {
  return confirmDialog({
    message: t("confirmDialogDeleteMessage"),
    header: t("headerTitleDeleteConfirmDialog"),
    icon: "pi pi-info-circle",
    acceptClassName: "p-button-danger",
    accept: () => deleteItem(item),
    acceptLabel: t("acceptLabelConfirmDialog"),
    rejectLabel: t("rejectLabelConfirmDialog"),
  });
};

export { ConfirmDelete };
