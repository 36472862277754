import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { Tooltip } from "primereact/tooltip";

import AccountService from "../../services/AccountService";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import DataControls from "../../components/DataTools/DataControls";
import { getLocalStorage } from "../../services/LocalStorage";
import CustomFileUpload from "../../components/CustomFileUpload";
import CustomConfirmDialog from "../../components/CustomConfirmDialog";
import { useLanguageContext } from "../../context/GlobalProvider";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
  showWarnToast,
} from "../../components/CustomToast";
import { actionBodyTemplate } from "../../utils/DataTableAction";

export default function Account(props) {
  const [accountCode, setAccountCode] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountData, setAccountData] = useState([]);
  const [accountCodeList, setAccountCodeList] = useState([]);
  const [accountNameList, setAccountNameList] = useState([]);
  const [visibleAddSidebar, setVisibleAddSidebar] = useState(false);
  const [visibleEditSidebar, setVisibleEditSidebar] = useState(false);
  const [visibleSearchSidebar, setVisibleSearchSidebar] = useState(false);
  const [selectedAccount, setselectedAccount] = useState(null);
  const [selectedAccountCode, setSelectedAccountCode] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [visibleImportSidebar, setVisibleImportSidebar] = useState(false);
  const [accountFilterBadges, setAccountFilterBadges] = useState([]);
  const [visibleConfirmDialogUpload, setVisibleConfirmDialogUpload] =
    useState(false);

  const accountTypeList = [
    { id: "1", name: "Memo" },
    { id: "2", name: "Balance Sheet" },
    { id: "3", name: "Creditor" },
    { id: "4", name: "Debitor" },
    { id: "5", name: "P&L" },
  ];

  const toast = useRef(null);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });

  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [filters, setFilters] = useState({
    accountCode: "",
    accountName: "",
    accountType: "",
  });
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(false);

  //for file  upload component
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showSpinnerBar, setShowSpinnerBar] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const loadLazyData = useCallback(() => {
    setLoading(true);
    AccountService.getAccountByHotelId(
      DefaultHotelId,
      lazyParams,
      filters
    ).then(res => {
      setTotalRecords(res.data.totalRecords);
      setAccountData(res.data.accounts);
      setLoading(false);
    });
  }, [lazyParams, filters, DefaultHotelId]);

  const { t } = useLanguageContext();
  useEffect(() => {
    loadLazyData();
  }, [loadLazyData]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyData();
    }
  }, [loadLazyData, props.selectedHotel]);

  const onPage = event => {
    setLazyParams(event);
  };
  const onSort = event => {
    setLazyParams(event);
  };

  function exportCSVAccountFile() {
    AccountService.exportAccountFile(DefaultHotelId, filters).then(res => {
      showWarnToast(toast, t);
    });
  }

  function handleAcountCodeChange(e) {
    setAccountCode(e.target.value);
  }

  function handleAcountNameChange(e) {
    setAccountName(e.target.value);
  }
  function handleAcountTypeChange(e) {
    setAccountType(e.target.value);
  }

  function deleteAccount(accountCode) {
    AccountService.deleteAccountByHotelIdAndId(DefaultHotelId, accountCode)
      .then(res => {
        setAccountData(
          accountData.filter(
            value => value.accountId["accountCode"] !== accountCode
          )
        );
        showSuccessToast(toast, t, "delete");
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }

  function saveAccount() {
    setClickedButton(true);
    AccountService.addAccountByHotelId(
      DefaultHotelId,
      accountCode,
      accountName,
      accountType
    )
      .then(res => {
        setClickedButton(false);
        setAccountCode("");
        setAccountName("");
        setAccountType("");
        loadLazyData();

        showSuccessToast(toast, t, "add");
        setVisibleAddSidebar(false);
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }

  function editAccount(e) {
    e.preventDefault();
    setClickedButton(true);
    AccountService.updateAccountByHotelId(
      DefaultHotelId,
      accountCode,
      accountName,
      accountType
    )
      .then(res => {
        setClickedButton(false);
        showSuccessToast(toast, t, "update");
        loadLazyData();
        setVisibleEditSidebar(false);
        setAccountCode("");
        setAccountName("");
        setAccountType("");
      })
      .catch(error => {
        setClickedButton(false);
        showErrorToast(toast, t, error);
      });
  }

  function clearFilters() {
    setSelectedAccountCode("");
    setSelectedAccountName("");
    setSelectedAccountType("");
  }

  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];
    if (selectedAccountCode.id || selectedAccountCode) {
      newFilters.push({
        key: "1",
        filterValue: selectedAccountCode.id || selectedAccountCode,
        removable: true,
      });
    }
    if (selectedAccountName.id || selectedAccountName) {
      newFilters.push({
        key: "2",
        filterValue: selectedAccountName.id || selectedAccountName,
        removable: true,
      });
    }

    if (selectedAccountType) {
      newFilters.push({
        key: "3",
        filterValue: selectedAccountType,
        removable: true,
      });
    }

    setFilters({
      accountCode: selectedAccountCode.id || selectedAccountCode,
      accountName: selectedAccountName.id || selectedAccountName,
      accountType: selectedAccountType,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setAccountFilterBadges(newFilters);

    setVisibleSearchSidebar(false);
  }
  function handleFilterBadgeClear() {
    setAccountFilterBadges([]);
    setSelectedAccountCode("");
    setSelectedAccountName("");
    setSelectedAccountType("");
    setFilters({
      accountCode: "",
      accountName: "",
      accountType: "",
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "1":
        setSelectedAccountCode("");
        setFilters({
          accountCode: "",
          accountName: selectedAccountName.id || selectedAccountName,
          accountType: selectedAccountType,
        });
        break;
      case "2":
        setSelectedAccountName("");
        setFilters({
          accountCode: selectedAccountCode.id || selectedAccountCode,
          accountName: "",
          accountType: selectedAccountType,
        });
        break;
      default:
        setSelectedAccountType("");
        setFilters({
          accountCode: selectedAccountCode.id || selectedAccountCode,
          accountName: selectedAccountName.id || selectedAccountName,
          accountType: "",
        });
    }
    setAccountFilterBadges(current => current.filter(el => el.key !== key));
  }
  function handleSelectedAccountCodeChange(event) {
    setSelectedAccountCode(event.target.value);
  }

  function handleSelectedAccountNameChange(event) {
    setSelectedAccountName(event.target.value);
  }

  function handleSelectedAccountTypeChange(event) {
    setSelectedAccountType(event.target.value);
  }
  function handleButtonClick() {
    setVisibleAddSidebar(true);
  }
  function handleSearchButtonClick() {
    setVisibleSearchSidebar(true);
  }
  function getSelectedRow(rowdata) {
    setAccountCode(rowdata.accountId["accountCode"]);
    setAccountName(rowdata.accountName);
    setAccountType(rowdata.accountType);
  }

  function handleImportButtonClick() {
    setVisibleImportSidebar(true);
  }
  const uploadAccountCSVFile = event => {
    setLoadingUpload(true);
    setShowCancelButton(false);
    setShowSpinnerBar(true);
    setDisabledButton(true);
    const FormData = global.FormData;
    const formData = new FormData();

    formData.append("file", event.files[0]);

    AccountService.uploadAccountFile(DefaultHotelId, formData)
      .then(res => {
        showInfoToast(toast, t);
        setLoadingUpload(false);
        setShowCancelButton(true);
        setShowSpinnerBar(false);
        setDisabledButton(false);
      })
      .catch(error => {
        showErrorToast(toast, t, error);
        setLoadingUpload(false);
        setShowCancelButton(true);
        setShowSpinnerBar(false);
        setDisabledButton(false);
      });
  };

  const customHeaderNewAccount = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleAddSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("newAccountSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderEditAccount = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => {
          setVisibleEditSidebar(false);
          formReset();
        }}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("editAccountSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderFilterAccount = (
    <React.Fragment>
      <Tooltip target=".custom-target-icon" />

      <button
        data-pr-tooltip={t("closeDataTooltip")}
        type="button"
        className="custom-target-icon p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleSearchSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div></div>

      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderImportAccount = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleImportSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("importAccountFileSidebarTitle")}
      </div>
    </React.Fragment>
  );
  function formReset() {
    setAccountCode("");
    setAccountName("");
    setAccountType("");
    setVisibleEditSidebar(false);
  }
  function filteredAccountCode(filterValue) {
    AccountService.lovGetAccountByHotelId(DefaultHotelId, {
      selectedAccountCode: filterValue,
      selectedAccountName: "",
      selectedAccountType: "",
      searchMode: "AND",
    }).then(res => {
      setAccountCodeList(
        res.data.map(item => ({
          id: item.accountCode,
        }))
      );
    });
  }
  function filteredAccountName(filterValue) {
    AccountService.lovGetAccountByHotelId(DefaultHotelId, {
      selectedAccountCode: "",
      selectedAccountName: filterValue,
      selectedAccountType: "",
      searchMode: "AND",
    }).then(res => {
      setAccountNameList(
        res.data.map(item => ({
          id: item.accountName,
        }))
      );
    });
  }
  function handleVisibleEditSidebar() {
    setVisibleEditSidebar(true);
  }

  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <DataControls
        addButtonLabel={t("addButton")}
        filters={accountFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        onAddButtonClick={handleButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        onImportFileClick={handleImportButtonClick}
        exportCSV={exportCSVAccountFile}
        displayedDataControl="true"
        displayedImportExportDataControl="true"
        displayedImportDataControl="true"
      />
      <CustomConfirmDialog
        visibleConfirmDialogUpload={visibleConfirmDialogUpload}
      ></CustomConfirmDialog>
      <DataTable
        className="relative w-full"
        value={accountData}
        lazy
        selectionMode="single"
        selection={selectedAccount}
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        onSelectionChange={e => setselectedAccount(e.value)}
        responsiveLayout="scroll"
        breakpoint="960px"
        dataKey="accountId.accountCode"
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column
          field="accountId.accountCode"
          sortable
          header={t("accountCode")}
        ></Column>
        <Column field="accountName" sortable header={t("accountName")} />
        <Column field="accountType" sortable header={t("accountType")} />
        <Column
          body={rowdata => {
            return actionBodyTemplate(
              rowdata,
              t,
              handleVisibleEditSidebar,
              getSelectedRow,
              rowdata.accountId["accountCode"],
              deleteAccount
            );
          }}
        />
      </DataTable>
      {/* Start- SideBar for add account */}
      <Sidebar
        showCloseIcon={false}
        blockScroll="true"
        icons={customHeaderNewAccount}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleAddSidebar}
        position="right"
        onHide={() => setVisibleAddSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="accountCode" className="font-medium text-900">
                {t("accountCode")}
              </label>
              <InputText
                value={accountCode}
                onChange={handleAcountCodeChange}
                type="text"
                id="accountCode"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="accountName" className="font-medium text-900">
                {t("accountName")}
              </label>
              <InputText
                type="text"
                value={accountName}
                onChange={handleAcountNameChange}
                id="accountName"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="accountType" className="font-medium text-900">
                {t("accountType")}
              </label>
              <Dropdown
                placeholder={t("placeholderAccountTypeField")}
                value={accountType}
                options={accountTypeList}
                onChange={handleAcountTypeChange}
                optionValue="name"
                optionLabel="name"
                name="accountType"
                inputId="accountType"
              ></Dropdown>
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("saveButton")}
                icon="pi pi-plus"
                loading={clickedButton}
                onClick={() => saveAccount()}
                className="p-button w-auto p-button-success mr-2"
              />

              <Button
                label={t("cancelButton")}
                onClick={() => setVisibleAddSidebar(false)}
                className="p-button-secondary p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      {/* Start- SideBar for edit account */}
      <Sidebar
        showCloseIcon={false}
        blockScroll="true"
        icons={customHeaderEditAccount}
        visible={visibleEditSidebar}
        position="right"
        className="p-sidebar-md	"
        onHide={() => setVisibleEditSidebar(false)}
      >
        <div className="surface-card p-0  border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="accountCode" className="font-medium text-900">
                {t("accountCode")}
              </label>
              <InputText
                value={accountCode}
                disabled
                onChange={handleAcountCodeChange}
                type="text"
                id="accountCode"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="accountName" className="font-medium text-900">
                {t("accountName")}
              </label>
              <InputText
                type="text"
                value={accountName}
                onChange={handleAcountNameChange}
                id="accountName"
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="accountType" className="font-medium text-900">
                {t("accountType")}
              </label>
              <Dropdown
                disabled
                value={accountType}
                options={accountTypeList}
                onChange={handleAcountTypeChange}
                optionValue="name"
                optionLabel="name"
                name="accountType"
                inputId="accountType"
              ></Dropdown>
            </div>

            <div className="field mb-4 col-12 md:col-12">
              <Button
                label={t("updateButton")}
                onClick={editAccount}
                loading={clickedButton}
                className="p-button p-button-success w-auto mr-2"
              />

              <Button
                label={t("cancelButton")}
                onClick={() => formReset()}
                className="p-button-secondary p-button-outlined w-auto"
              />
            </div>
          </div>
        </div>
      </Sidebar>
      {/* Start- SideBar for filter account */}
      <Sidebar
        showCloseIcon={false}
        icons={customHeaderFilterAccount}
        blockScroll="true"
        id="sidebar-filter"
        visible={visibleSearchSidebar}
        position="right"
        className="p-sidebar-md	"
        onHide={() => setVisibleSearchSidebar(false)}
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedAccountCode}
                  options={accountCodeList}
                  filterList={filteredAccountCode}
                  onChange={handleSelectedAccountCodeChange}
                  placeholder={t("placeholderAccountCodeField")}
                  dropdown={false}
                  name="selectedAccountCode"
                  inputId="selectedAccountCode"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedAccountName}
                  options={accountNameList}
                  filterList={filteredAccountName}
                  onChange={handleSelectedAccountNameChange}
                  placeholder={t("placeholderAccountNameField")}
                  dropdown={false}
                  name="selectedAccountName"
                  inputId="selectedAccountName"
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <Dropdown
                  placeholder={t("placeholderAccountTypeField")}
                  options={accountTypeList}
                  value={selectedAccountType}
                  onChange={handleSelectedAccountTypeChange}
                  optionValue="name"
                  optionLabel="name"
                  name="selectedAccountType"
                  inputId="selectedAccountType"
                ></Dropdown>
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label={t("findButton")}
                  className="p-button p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => {
                    clearFilters();
                  }}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={() => setVisibleSearchSidebar(false)}
                  className="p-button-secondary p-button-outlined  w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
      {/* Start- SideBar for import file account */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderImportAccount}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        visible={visibleImportSidebar}
        position="right"
        onHide={() => setVisibleImportSidebar(false)}
      >
        <div className="surface-card p-0 border-round p-fluid">
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12" style={{ textAlign: "right" }}>
              <FaFileDownload style={{ color: "blue" }} />

              <Link
                to="../../../assets/files/Account.csv"
                target="_blank"
                download
              >
                {t("titleAccountTemplateDownloadLink")}
              </Link>
            </div>
            <div className="field mb-4 col-12">
              <label> {t("labelImportFile")} : </label>

              <CustomFileUpload
                uploadCSVFile={uploadAccountCSVFile}
                loadingUpload={loadingUpload}
                showCancelButton={showCancelButton}
                showSpinnerBar={showSpinnerBar}
                disabledButton={disabledButton}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <ConfirmDialog />
    </React.Fragment>
  );
}
