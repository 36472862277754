import React from "react";
import { Dropdown } from "primereact/dropdown";

import { useLanguageContext } from "../context/GlobalProvider";

const LanguageSelect = () => {
  const { languages, language, onClickLanguageChange } = useLanguageContext();

  return (
    <React.Fragment>
      <Dropdown
        value={language}
        options={languages}
        onChange={onClickLanguageChange}
        optionLabel="label"
        optionValue="name"
        name="language"
        inputId="language"
      />
    </React.Fragment>
  );
};

export default LanguageSelect;
