import React, { useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { useLanguageContext } from "../../context/GlobalProvider";

export default function KpiConstant(props) {
  const [kpiConstantValue, setKpiConstantValue] = useState(0);

  const { t } = useLanguageContext();
 

  function saveKpi() {
    if (props.actionType === "add") {
      props.addKpi(null, null, kpiConstantValue);
    } else {
      props.updateKpi(null, null, kpiConstantValue);
    }
  }

  function handleKpiValueChange(e) {
    setKpiConstantValue(e.value);
  }

  return (
    <React.Fragment>
      <div className="surface-card p-1  border-round p-fluid">
        <div className="grid formgrid p-fluid">
          <div className="field mb-4 col-12">
            <label htmlFor="value" className="font-medium text-900">
              {t("value")}
            </label>
            <InputNumber
              placeholder="Add value"
              value={props.kpiConstantValue}
              onChange={handleKpiValueChange}
            ></InputNumber>
          </div>
        </div>

        <div className="field mb-4 col-12 md:col-12">
          <Button
            iconPos="left"
            icon="pi pi-arrow-left"
            label={t("previousButton")}
            onClick={props.onPreviousButtonClick}
            className="p-button-secondary p-button-outlined  w-auto  mr-2"
          />
          <Button
            label={t("saveButton")}
            icon="pi pi-plus"
            onClick={() => saveKpi()}
            loading={ props.clickedButton}
            className="p-button p-button-success w-auto "
          />
        </div>
      </div>
    </React.Fragment>
  );
}
