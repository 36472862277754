import React, { useState } from "react";
import { useEffect } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useCallback } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";

import CustomAutoComplete from "../../components/CustomAutoComplete";
import BudgetService from "../../services/BudgetService";
import DataControls from "../../components/DataTools/DataControls";
import BudgetVersionsService from "../../services/BudgetVersionsService";
import { getLocalStorage } from "../../services/LocalStorage";
import DepartmentService from "../../services/DepartmentService";
import AccountService from "../../services/AccountService";
import CustomFileUpload from "../../components/CustomFileUpload";
import CustomConfirmDialog from "../../components/CustomConfirmDialog";
import { useLanguageContext } from "../../context/GlobalProvider";
import {
  showErrorToast,
  showInfoToast,
  showWarnToast,
} from "../../components/CustomToast";

export default function Budget(props) {
  const [budgetData, setBudgetData] = useState([]);
  const [month, setMonth] = useState(getLocalStorage("month"));
  const [year, setYear] = useState(getLocalStorage("year"));
  const [selectedBudgetVersionCode, setSelectedBudgetVersionCode] =
    useState("");
  const [budgetVersionCodeValue, setBudgetVersionCodeValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visibleSearchBudgetSidebar, setVisibleSearchBudgetSidebar] =
    useState(false);
  const [yearValue, setYearValue] = useState("");
  const [visibleUploadBudgetSidebar, setVisibleUploadBudgeSidebar] =
    useState(false);
  const [budgetVersionCodeList, setBudgetVersionCodeList] = useState([]);
  const [budgetVersionList, setBudgetVersionList] = useState([]);
  const [accountCodeList, setAccountCodeList] = useState([]);
  const [departmentCodeList, setDepartmentCodeList] = useState([]);
  const [selectedAccountCode, setSelectedAccountCode] = useState("");
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortOrder: null,
    sortField: null,
  });
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [filters, setFilters] = useState({
    budgetVersionCode: "",
    accountCode: "",
    departmentCode: "",
    month: month,
    year: year,
  });
  const [budgetFilterBadges, setBudgetFilterBadges] = useState([]);
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [visibleConfirmDialogUpload, setVisibleConfirmDialogUpload] =
    useState(false);

  //for file  upload component
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showSpinnerBar, setShowSpinnerBar] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const toast = useRef(null);
  const onSort = event => {
    setLazyParams(event);
  };
  const onPage = event => {
    setLazyParams(event);
  };
  function formatPeriod(year, month) {
    return year + "/" + month;
  }
  const { t } = useLanguageContext();

  //Get Budget Data
  const loadLazyBudgetData = useCallback(() => {
    setLoading(true);
    BudgetService.getBudgetItems(DefaultHotelId, lazyParams, filters)
      .then(res => {
        setBudgetData(res.data.budgets);
        setTotalRecords(res.data.totalRecords);
        setLoading(false);
      })
      .catch(error => {
        setDialogVisible(true);
        setLoading(false);
        setDialogMessage(error.response.data.message);
      });
  }, [DefaultHotelId, lazyParams, filters]);

  useEffect(() => {
    if (props.selectedHotel) {
      loadLazyBudgetData();
    }
  }, [loadLazyBudgetData, props.selectedHotel]);

  //Get Budget Version Code
  useEffect(() => {
    BudgetVersionsService.getBudgetVersionsByHotelId(
      DefaultHotelId,
      lazyParams,
      {
        budgetVersionCode: "",
        description: "",
      }
    ).then(res => {
      setBudgetVersionCodeList(
        res.data.budgetVersions.map(item => ({
          id: item.budgetVersionId.hotelId,
          name: item.budgetVersionId.budgetVersionCode,
        }))
      );
    });
  }, [DefaultHotelId, lazyParams]);

  useEffect(() => {
    if (props.selectedHotel) {
      setBudgetFilterBadges([
        { key: "1", filterValue: formatPeriod(year, month), removable: false },
      ]);
    }
  }, [props.selectedHotel]);

  function clearBudgetFilters() {
    setSelectedBudgetVersionCode("");
    setSelectedAccountCode("");
    setSelectedDepartmentCode("");
  }

  function handleYearValueChange(e) {
    setYearValue(e.target.value);
  }

  function handleSelectedBudgetVersionCodeChange(e) {
    setSelectedBudgetVersionCode(e.target.value);
  }

  function handleBudgetVersionCodeChange(e) {
    setBudgetVersionCodeValue(e.target.value);
  }
  const customHeaderUploadbudget = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => formReset()}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>

      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("uploadSideBarTitle")}
      </div>
    </React.Fragment>
  );
  const customHeaderFilterBudget = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleSearchBudgetSidebar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      {/* <div>
        <button
          type="button"
          className="p-sidebar-icon p-link"
          aria-label="refresh"
        >
          <FaRedo
            onClick={() => {
              clearBudgetFilters();
            }}
          />
        </button>
      </div> */}
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("filterSidebarTitle")}
      </div>
    </React.Fragment>
  );
  function handleButtonClick() {
    setVisibleUploadBudgeSidebar(true);
  }

  const uploadBudgetFile = event => {
    //setVisibleConfirmDialogUpload(true);
    setLoadingUpload(true);
    setShowCancelButton(false);
    setShowSpinnerBar(true);
    setDisabledButton(true);

    const FormData = global.FormData;
    const formData = new FormData();
    formData.append("groupHotelId ", getLocalStorage("groupHotel"));
    formData.append("budgetVersionCode", budgetVersionCodeValue);
    formData.append("year", yearValue);
    formData.append("file", event.files[0]);
    BudgetService.uploadBugetFile(formData)
      .then(res => {
        //setVisibleConfirmDialogUpload(false);
        showInfoToast(toast, t);
        setBudgetVersionCodeValue("");
        setYearValue("");
        loadLazyBudgetData();
        setVisibleUploadBudgeSidebar(false);
        setLoadingUpload(false);
        setShowCancelButton(true);
        setShowSpinnerBar(false);
        setDisabledButton(false);
      })

      .catch(error => {
        //setVisibleConfirmDialogUpload(false);
        setLoadingUpload(false);
        setShowCancelButton(true);
        setShowSpinnerBar(false);
        setDisabledButton(false);
        showErrorToast(toast, t, error);
      });
  };

  function dialogCallBack() {
    setYear(getLocalStorage("year"));
    setMonth(getLocalStorage("month"));
    setDialogVisible(false);
  }

  function handleSearchButtonClick() {
    setVisibleSearchBudgetSidebar(true);
  }
  function handleSelectedAccountCodeChange(event) {
    setSelectedAccountCode(event.target.value);
  }
  function handleSelectedDepartmentCodeChange(event) {
    setSelectedDepartmentCode(event.target.value);
  }

  function handlePeriodChange(e) {
    setYear(e.target.value.substring(0, 4));
    setMonth(e.target.value.substring(5));
  }

  const dialogFooter = (
    <div>
      <Button
        label="OK"
        className="p-button-danger"
        onClick={() => {
          dialogCallBack();
        }}
      />
    </div>
  );
  function formReset() {
    setBudgetVersionCodeValue("");
    setYearValue("");
    setVisibleUploadBudgeSidebar(false);
  }

  function filteredBudgetVersionCode(filterValue) {
    BudgetVersionsService.lovGetBudgetVersionByHotelId(DefaultHotelId, {
      selectedBudgetVersionCode: filterValue,
      selectedBudgetVersionDescription: "",
      searchMode: "AND",
    }).then(res => {
      setBudgetVersionList(
        res.data.map(item => ({
          id: item.budgetVersionCode,
        }))
      );
    });
  }

  function filteredAccountCode(filterValue) {
    AccountService.lovGetAccountByHotelId(DefaultHotelId, {
      selectedAccountCode: filterValue,
      selectedAccountName: "",
      selectedAccountType: "",
      searchMode: "AND",
    }).then(res => {
      setAccountCodeList(
        res.data.map(item => ({
          id: item.accountCode,
        }))
      );
    });
  }

  function filteredDepartmentCode(filterValue) {
    DepartmentService.lovGetDepartmentByHotelId(DefaultHotelId, {
      selectedDepartmentCode: filterValue,
      selectedDepartmentName: "",
      searchMode: "OR",
    }).then(res => {
      setDepartmentCodeList(
        res.data.map(item => ({
          id: item.departmentCode,
        }))
      );
    });
  }
  function handleSearch(e) {
    e.preventDefault();

    let newFilters = [];

    newFilters.push({
      key: "1",
      filterValue: formatPeriod(year, month),
      removable: false,
    });

    if (selectedBudgetVersionCode.id || selectedBudgetVersionCode) {
      newFilters.push({
        key: "2",
        filterValue: selectedBudgetVersionCode.id || selectedBudgetVersionCode,
        removable: true,
      });
    }
    if (selectedAccountCode.id || selectedAccountCode) {
      newFilters.push({
        key: "3",
        filterValue: selectedAccountCode.id || selectedAccountCode,
        removable: true,
      });
    }

    if (selectedDepartmentCode.id || selectedDepartmentCode) {
      newFilters.push({
        key: "4",
        filterValue: selectedDepartmentCode.id || selectedDepartmentCode,
        removable: true,
      });
    }
    setFilters({
      budgetVersionCode:
        selectedBudgetVersionCode.id || selectedBudgetVersionCode,
      accountCode: selectedAccountCode.id || selectedAccountCode,
      departmentCode: selectedDepartmentCode.id || selectedDepartmentCode,
      month: month,
      year: year,
    });
    setLazyParams({
      first: 0,
      rows: 10,
      page: 0,
      sortOrder: null,
      sortField: null,
    });
    setBudgetFilterBadges(newFilters);

    setVisibleSearchBudgetSidebar(false);
  }
  function handleFilterBadgeClear() {
    setBudgetFilterBadges([
      { key: "1", filterValue: formatPeriod(year, month), removable: false },
    ]);
    setSelectedBudgetVersionCode("");
    setSelectedAccountCode("");
    setSelectedDepartmentCode("");

    setFilters({
      budgetVersionCode: "",
      accountCode: "",
      departmentCode: "",
      month: month,
      year: year,
    });
  }
  function onFilterItemRemove(key) {
    switch (key) {
      case "2":
        setSelectedBudgetVersionCode("");
        setFilters({
          budgetVersionCode: "",
          accountCode: "",
          departmentCode: "",
          month: month,
          year: year,
        });
        break;
      case "3":
        setSelectedAccountCode("");
        setFilters({
          budgetVersionCode: "",
          accountCode: "",
          departmentCode: "",
          month: month,
          year: year,
        });
        break;
      case "4":
        setSelectedDepartmentCode("");
        setFilters({
          budgetVersionCode: "",
          accountCode: "",
          departmentCode: "",
          month: month,
          year: year,
        });
        break;

      default:
    }
    setBudgetFilterBadges(current => current.filter(el => el.key !== key));
  }

  function exportCSVBudgetFile() {
    BudgetService.exportBudgetFile(DefaultHotelId, filters).then(res => {
      showWarnToast(toast, t);
    });
  }

  const formatAmount = value => {
    return value.toLocaleString("en", { minimumFractionDigits: 3 });
  };

  const amountBodyTemplate = rowdata => {
    return formatAmount(rowdata.amount);
  };
  return (
    <React.Fragment>
      <DataControls
        addButtonLabel={t("uploadButton")}
        onAddButtonClick={handleButtonClick}
        onSearchButtonClick={handleSearchButtonClick}
        filters={budgetFilterBadges}
        onFilterBadgeClear={handleFilterBadgeClear}
        onFilterItemRemove={onFilterItemRemove}
        exportCSV={exportCSVBudgetFile}
        displayedDataControl="true"
        displayedImportExportDataControl="true"
        displayedImportDataControl="false"
      />
      <CustomConfirmDialog
        visibleConfirmDialogUpload={visibleConfirmDialogUpload}
      ></CustomConfirmDialog>
      <ConfirmDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        message={dialogMessage}
        footer={dialogFooter}
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        header="Information"
      />
      <Toast ref={toast}></Toast>
      <DataTable
        value={budgetData}
        className="relative w-full"
        lazy
        onPage={onPage}
        onSort={onSort}
        loading={loading}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 25, 50, 100]}
        first={lazyParams.first}
        rows={lazyParams.rows}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
        responsiveLayout="scroll"
        totalRecords={totalRecords}
        emptyMessage={t("datatableEmptyMessage")}
      >
        <Column
          field="budgetVersionCode"
          sortable
          header={t("budgetVersionCode")}
        />
        <Column field="accountCode" sortable header={t("accountCode")} />
        <Column
          field="account.accountName"
          sortable
          header={t("accountName")}
        />
        <Column field="departmentCode" sortable header={t("departmentCode")} />
        <Column
          field="department.departmentName"
          sortable
          header={t("departmentName")}
        />
        <Column
          field="amount"
          body={amountBodyTemplate}
          sortable
          header={t("amount")}
        />
      </DataTable>

      {/* Start- SideBar for Upload  budget File */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderUploadbudget}
        visible={visibleUploadBudgetSidebar}
        className="h-full  relative overflow-hidden z-5  p-sidebar-md"
        position="right"
      >
        <div className="field mb-4 col-12" style={{ textAlign: "left" }}>
          <FaFileDownload style={{ color: "blue" }} />

          <Link to="../../../assets/files/Budget.csv" target="_blank" download>
            {t("titleBudgetTemplateDownloadLink")}
          </Link>
        </div>
        <div className="field mb-4 col-12">
          <Dropdown
            placeholder={t("placeholderBudgetVersionCodeField")}
            value={budgetVersionCodeValue}
            options={budgetVersionCodeList}
            onChange={handleBudgetVersionCodeChange}
            optionValue="name"
            optionLabel="name"
            name="budgetVersionCodeValue"
            inputId="budgetVersionCodeValue"
          />
        </div>
        <div
          className="divBudget"
          style={{ display: budgetVersionCodeValue ? "block" : "none" }}
        >
          <div className="field mb-4 col-12">
            <InputText
              value={yearValue}
              onChange={handleYearValueChange}
              placeholder={t("placeholderYearField")}
            ></InputText>
          </div>
          <CustomFileUpload
            uploadCSVFile={uploadBudgetFile}
            loadingUpload={loadingUpload}
            showCancelButton={showCancelButton}
            showSpinnerBar={showSpinnerBar}
            disabledButton={disabledButton}
          />
        </div>
      </Sidebar>
      {/* Start- SideBar for Search budget  */}
      <Sidebar
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderFilterBudget}
        id="sidebar-filter"
        visible={visibleSearchBudgetSidebar}
        position="right"
        className="p-sidebar-md"
        onHide={() => setVisibleSearchBudgetSidebar(false)}
      >
        <form onSubmit={handleSearch}>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedBudgetVersionCode}
                  options={budgetVersionList}
                  filterList={filteredBudgetVersionCode}
                  onChange={handleSelectedBudgetVersionCodeChange}
                  placeholder={t("placeholderBudgetVersionCodeField")}
                  dropdown={false}
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedAccountCode}
                  options={accountCodeList}
                  filterList={filteredAccountCode}
                  onChange={handleSelectedAccountCodeChange}
                  placeholder={t("placeholderAccountCodeField")}
                  dropdown={false}
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  value={selectedDepartmentCode}
                  options={departmentCodeList}
                  filterList={filteredDepartmentCode}
                  onChange={handleSelectedDepartmentCodeChange}
                  placeholder={t("placeholderDepartmentCodeField")}
                  dropdown={false}
                ></CustomAutoComplete>
              </div>
              <div className="field mb-4 col-12">
                <InputMask
                  mask="9999/99"
                  placeholder={t("placeholderPeriodField")}
                  value={year + "/" + month}
                  onChange={handlePeriodChange}
                  type="text"
                  name="yearMonthField"
                />
              </div>

              <div className="field mb-4 col-12 md:col-12">
                <Button
                  type="submit"
                  label={t("findButton")}
                  className="p-button p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => {
                    clearBudgetFilters();
                  }}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={() => setVisibleSearchBudgetSidebar(false)}
                  className="p-button-secondary p-button-outlined  w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
    </React.Fragment>
  );
}
