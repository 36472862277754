import React from "react";
import { useLocation } from "react-router-dom";

export function Footer() {
  const { pathname } = useLocation();
  if (pathname === "/") return null;
  return (
    <React.Fragment>
      {/* className="surface-section px-4 py-6 md:px-6 lg:px-8 text-center"  */}
      <div
        className="surface-section   md:px-6 lg:px-8 text-center"
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "60px",
        }}
      >
        <div className="text-900 font-bold text-900 text-xl mt-4 mb-3">
          &copy; 2024 ,Operandi
        </div>
        <p className="text-600 line-height-3 mt-0 mb-4"></p>
        <div className="flex align-items-center justify-content-center">
          <div className="cursor-pointer text-700 mr-5">
            <i className="pi pi-linkedin"></i>
          </div>
          <div className="cursor-pointer text-700 mr-5">
            <i className="pi pi-facebook"></i>
          </div>
          <div className="cursor-pointer text-700">
            <i className="pi pi-twitter"></i>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
