import React from "react";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLanguageContext } from "../context/GlobalProvider";

export default function CustomConfirmDialog(props) {
  const { t } = useLanguageContext();

  const customConfirmDialogFooter = () => {
    return null;
  };

  const customConfirmDialogIcon = () => {
    return (
      <React.Fragment>
        <ProgressSpinner
          style={{ width: "50px", height: "50px" }}
          strokeWidth="8"
          className="col-6"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </React.Fragment>
    );
  };

  const customConfirmDialogMessage = () => {
    return <React.Fragment>{t("uploadIndicationMessage")}</React.Fragment>;
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        className="confirmUploadDialog grid"
        visible={props.visibleConfirmDialogUpload}
        message={customConfirmDialogMessage}
        icon={customConfirmDialogIcon}
        footer={customConfirmDialogFooter}
        breakpoints={{ "960px": "20vw", "640px": "10vw" }}
        style={{ width: "20vw", height: "10vw" }}
      />
    </React.Fragment>
  );
}
