import axios from "axios";
import qs from "qs";
import TokenService from "./TokenService";
import { getLocalStorage } from "./LocalStorage";

const API_URL = process.env.REACT_APP_FD_API_BASE_URl;

class AuthenticationService {
  login(username, password) {
    return axios.post(
      API_URL + "token",
      qs.stringify({
        username: username,
        password: password,
      }),
      { headers: { "content-type": "application/x-www-form-urlencoded" } }
    );
  }
  forgetPasswordRequest(email) {
    return axios.get(API_URL + "users/forget_password", {
      params: {
        username: email,
      },

      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
  }
  resetPasswordRequest(password, passwordConfirmation) {
    return axios.post(
      API_URL + "users/reset_password",
      {
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      { headers: { Authorization: `Bearer ${getLocalStorage("token")}` } }
    );
  }
  get getCurrentUser() {
    return getLocalStorage("user");
  }

  refreshToken() {
    return axios
      .get(API_URL + "token/refresh", {
        headers: {
          Authorization: "Bearer " + TokenService.getLocalRefreshToken(),
        },
      })
      .then(res => {
        if (res.data.access_token) {
          TokenService.updateLocalAccessToken(res.data.access_token);
          return TokenService.getLocalAccessToken();
        }
      });
  }
}

export default new AuthenticationService();
