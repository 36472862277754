import React, { useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";

import { useLanguageContext } from "../context/GlobalProvider";

export default function CustomFileUpload(props) {
  const { t } = useLanguageContext();
  const selectMaxFileSize = 1024 * 1024 * 100;
  const ref = useRef();
  const onHandleClearSelectedFile = e => {
    ref.current.clear();
  };

  return (
    <React.Fragment>
      {/* <div className="flex flex align-items-center justify-content-center flex-wrap">
        <div >
          <FileUpload
            mode="basic"
            name="file"
            accept=".csv"
            maxFileSize={selectMaxFileSize}
            customUpload={true}
            chooseLabel={t("fileUploadButton")}
            //disabled={props.clickedButton}
            uploadHandler={props.uploadCSVFile}
            ref={ref}
          />
        </div>

        <div >
          <Button
            label={t("cancelButton")}
            onClick={() => {
              onHandleClearSelectedFile();
            }}
            className="p-button-secondary  p-button-outlined  w-auto mr-2 ml-2"
          />
        </div>

        <div >
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      </div> */}
      {/* <div className="flex flex-wrap" style={{ maxWidth: "500px" }}>
        <div
          className="flex align-items-center justify-content-center m-2"
          style={{ width: "150px", height: "100px" }}
        >
          <FileUpload
            mode="basic"
            name="file"
            accept=".csv"
            maxFileSize={selectMaxFileSize}
            customUpload={true}
            chooseLabel={t("fileUploadButton")}
            disabled={props.disabledButton}
            uploadHandler={props.uploadCSVFile}
            ref={ref}
          />
        </div>
        {props.showCancelButton ? (
          <div className="flex align-items-center justify-content-center m-2">
            <Button
              label={t("cancelButton")}
              onClick={() => {
                onHandleClearSelectedFile();
              }}
              className="p-button-secondary  p-button-outlined  w-auto mr-2 ml-2"
            />
          </div>
        ) : null}
        {props.showSpinnerBar && (
          <div className="flex align-items-center justify-content-center m-2">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
              loading={props.loadingUpload}
            ></i>
          </div>
        )}
      </div> */}
      <div className="flex justify-content-between pt-3">
        <div className=" w-8 mr-2">
          <FileUpload
            mode="basic"
            name="file"
            accept=".csv"
            maxFileSize={selectMaxFileSize}
            customUpload={true}
            chooseLabel={t("fileUploadButton")}
            disabled={props.disabledButton}
            uploadHandler={props.uploadCSVFile}
            ref={ref}
          />
        </div>
        {props.showCancelButton ? (
          <div className=" w-6 " style={{ marginRight: "17rem" }}>
            <Button
              label={t("cancelButton")}
              onClick={() => {
                onHandleClearSelectedFile();
              }}
              className="p-button-secondary  p-button-outlined  w-auto"
            />
          </div>
        ) : null}
        {props.showSpinnerBar && (
          <div className=" w-6 ml-2" style={{ marginRight: "18rem" }}>
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
              loading={props.loadingUpload.toString()}
            ></i>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
