import React, { useState, useEffect, useCallback } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsOrganization from "highcharts/modules/organization";
import exporting from "highcharts/modules/exporting";
import { Sidebar } from "primereact/sidebar";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import HighchartsTreemap from "highcharts/modules/treemap";
import HighchartsTreegraph from "highcharts/modules/treegraph";

import { useLanguageContext } from "../../context/GlobalProvider";
import BudgetVersionsService from "../../services/BudgetVersionsService";
import KpisService from "../../services/KpisService";
import DataControls from "../../components/DataTools/DataControls";
import { getLocalStorage, setLocalStorage } from "../../services/LocalStorage";
import { formatPeriod } from "../../utils/FormatPeriod";
import CustomAutoComplete from "../../components/CustomAutoComplete";
//import "./Custom-highcharts-style.css";

export default function OrgCharts(props) {
  const [visibleSearchSideBar, setVisibleSearchSideBar] = useState(true);
  const [kpiDependencyItemList, setKpiDependencyItemList] = useState([]);
  const [accountDepartmentValue, setAccountDepartmentValue] = useState("");
  const [year, setYear] = useState(getLocalStorage("year"));
  const [month, setMonth] = useState(getLocalStorage("month"));
  const [period, setPeriod] = useState(
    getLocalStorage("year") + 0 + getLocalStorage("month")
  );
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [budgetVersionCodeValue, setBudgetVersionCodeValue] = useState("");
  const [budgetVersion, setBudgetVersion] = useState([]);
  const [kpiFilterBadges, setKpiFilterBadges] = useState([]);
  const [selectedSearchType, setSelectedSearchType] = useState("");
  const [kpisFiltredList, setKpisFiltredList] = useState([]);

  const [selectedKpiName, setSelectedKpiName] = useState("");
  const [showOrganizationChart, setShowOrganizationChart] = useState(false);
  const [clickedKpisName, setClickedKpisName] = useState(null);
  const kpisTypeSearch = [
    { id: "1", name: "Account Department" },
    { id: "2", name: "KPIs Name" },
  ];

  const { t } = useLanguageContext();

  //Using the accessibility module
  if (typeof window !== `undefined`) {
    highchartsAccessibility(Highcharts);
  }

  if (typeof Highcharts === "object") {
    HighchartsSankey(Highcharts);
    HighchartsOrganization(Highcharts);
  }

  exporting(Highcharts);
  // Initialize the organization chart module
  HighchartsOrganization(Highcharts);

  //Initialize the treegraph chart module
  HighchartsTreemap(Highcharts);
  HighchartsTreegraph(Highcharts);

  useEffect(() => {
    setVisibleSearchSideBar(true);
    if (props.selectedHotel) {
      BudgetVersionsService.getBudgetVersionsByHotelId(
        DefaultHotelId,
        {
          first: 1,
          rows: 500,
          page: 0,
          sortOrder: null,
          sortField: null,
        },
        {
          budgetVersionCode: "",
          description: "",
        }
      ).then(res => {
        setBudgetVersionCodeValue({
          id: res.data.budgetVersions[0].budgetVersionId.budgetVersionCode,
        });

        setKpiFilterBadges([
          { key: "1", filterValue: formatPeriod(period), removable: false },
          {
            key: "2",
            filterValue:
              res.data.budgetVersions[0].budgetVersionId.budgetVersionCode,
            removable: false,
          },
        ]);
      });
    }
  }, []);

  const searchKpisDependencyItem = useCallback(async () => {
    setShowOrganizationChart(true);

    try {
      const res = await KpisService.getKpisDependencyItem(
        DefaultHotelId,
        accountDepartmentValue,
        selectedKpiName.id
      );
      if (res.data.length > 0) {
        getKpisDependencyItem(res.data);
      setSelectedKpiName("")
      setAccountDepartmentValue("")
      setSelectedSearchType("")
      }
    } catch (error) {
      console.error("Error ", error);
    }
  }, [accountDepartmentValue, selectedKpiName]);

  const getKpisDependencyItem = dataItem => {
    if (!dataItem || dataItem.length === 0) {
      setKpiDependencyItemList([]);
      return;
    }

    let treeKpisDependencyData = [];

    const uniqueKpisDataPoints = new Set();

    const childKpisNode = (node, parentLabel) => {
      const currentLabel = node.label;

      const kpisDependencyAdditionalData = {
        id: currentLabel,
        parent: parentLabel,
        formula: node.formula,
        type: node.type,
        value: node.value,
        accountDepartment: node.accountDepartment,
      };

      if (!uniqueKpisDataPoints.has(currentLabel)) {
        treeKpisDependencyData.push(kpisDependencyAdditionalData);
        uniqueKpisDataPoints.add(currentLabel);
      }
      if (node.children) {
        node.children.forEach(child => childKpisNode(child, currentLabel));
      }
    };

    dataItem.forEach(node => {
      childKpisNode(node, undefined);
    });

    setKpiDependencyItemList(treeKpisDependencyData);
  };

  const treegraphOptions = {
    chart: {
      spacingBottom: 30,
      marginRight: 150,
      height: getHeightBasedOnDataLength(kpiDependencyItemList),
      events: {
        click: function () {
          var kpisNameElement = document.querySelector(".kpis-name");

          if (kpisNameElement) {
            var kpisNameLabel = kpisNameElement.textContent;
            getClickedKpisNameFromTreegraph(kpisNameLabel);
          }
        },
      },
    },

    title: {
      text: "",
    },

    series: [
      {
        type: "treegraph",
        keys: ["parent", "id", "level"],
        clip: false,
        data: kpiDependencyItemList,
        cursor: "pointer",
        //allowPointSelect: "true",
        //Invert chart
        reversed: "true",

        marker: {
          symbol: "circle",
          radius: 6,
          fillColor: "#ffffff",
          lineWidth: 3,
        },
        dataLabels: {
          align: "left",
          pointFormat: "{point.id}",
          style: {
            color: "#000000",
            textOutline: "3px #ffffff",
            whiteSpace: "nowrap",
          },
          x: 24,
          crop: false,
          overflow: "y",
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
          },
          {
            level: 2,
            colorByPoint: true,
          },
          {
            level: 3,
            colorVariation: {
              key: "brightness",
              to: -0.5,
            },
          },
          {
            level: 4,
            colorVariation: {
              key: "brightness",
              to: 0.5,
            },
          },
          {
            level: 6,
            dataLabels: {
              x: 10,
            },
            marker: {
              radius: 4,
            },
          },
        ],
        tooltip: {
          hideDelay: 3000,

          pointFormatter: function () {
            if (this.type === "EXPRESSION" && this.formula != null) {
              return `
                      <div style="font-size: 12px; font-family: 'Arial', sans-serif;text-align:center;">
                          <b style="color:red;text-align:center;cursor:pointer;" class="kpis-name">${this.id}</b><br> <b></b><br><b style="color:red;text-align:center">${this.type}</b><br> <b></b><br><b>${this.formula}</b>
                      </div>
                  `;
            } else if (
              this.type === "TRANSACTION" &&
              this.accountDepartment.length > 0
            ) {
              let accountDepartmentList = Array.isArray(this.accountDepartment)
                ? this.accountDepartment
                    .map(
                      item => `<li style="list-style-type:none;">${item}</li>`
                    )
                    .join("<br></br>")
                : "";
              return `
                      <div style="font-size: 12px; font-family: 'Arial', sans-serif;text-align:center;">
                          <b style="color:red;text-align:center;"  class="kpis-name">${this.id}</b><br> <b></b><br><b style="color:red;">${this.type}</b><br> <b></b><br><b>${accountDepartmentList}</b><br>
                      </div>
                  `;
            } else {
              return `
                      <div style="font-size: 12px; font-family: 'Arial', sans-serif;text-align:center;">
                          <b style="color:red;text-align:center;">${this.id}</b><br> <b></b><br> <b style="color:red;">${this.type}</b><br> <b></b><br><b>${this.accountDepartment}</b><br>
                      </div>
                  `;
            }
          },
        },
      },
    ],
  };
  const getClickedKpisNameFromTreegraph = kpisName => {
    setClickedKpisName(kpisName);
    setLocalStorage("currentClickedKpisName", kpisName);
    window.open("/ControlPanel/Kpis", "_blank");
  };
  function adjustChartElementHeight() {
    var elementInBigScreen = document.querySelector(
      '[data-highcharts-chart="2"]'
    );
    var elementInSmallScreen = document.querySelector(
      '[data-highcharts-chart="0"]'
    );
    if (kpiDependencyItemList !== undefined) {
      if (kpiDependencyItemList.length <= 30) {
        if (elementInBigScreen) {
          elementInBigScreen.style.height = "600px";
          elementInBigScreen.style.padding = "0.50rem";
        }
        if (elementInSmallScreen) {
          elementInSmallScreen.style.height = "600px";
          elementInSmallScreen.style.padding = "0.50rem";
        }
      } else {
        if (elementInBigScreen) {
          elementInBigScreen.style.height = "1700px";
          elementInBigScreen.style.padding = "0.5rem";
        }
        if (elementInSmallScreen) {
          elementInSmallScreen.style.height = "1700px";
          elementInSmallScreen.style.padding = "0.5rem";
        }
      }
    }
  }
  adjustChartElementHeight();

  function getHeightBasedOnDataLength(kpisMappingData) {
    if (kpisMappingData.length <= 30) {
      return 500;
    } else {
      return 1500;
    }
  }
  function handleSearchPeriodChange(event) {
    const year = event.target.value.substring(0, 4);
    const month = event.target.value.substring(5);
    const periodValue = year + 0 + month;
    setPeriod(periodValue);
  }

  function handleSelectedKpiNameChange(e) {
    setSelectedKpiName(e.value);
  }
  function handleBudgetVersionCodeChange(e) {
    setBudgetVersionCodeValue(e.target.value);
  }
  function filterBudgetVersionList(filterValue) {
    BudgetVersionsService.getBudgetVersionsByHotelId(
      DefaultHotelId,
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        budgetVersionCode: filterValue,
        description: "",
      }
    ).then(res => {
      setBudgetVersion(
        res.data.budgetVersions.map(item => ({
          id: item.budgetVersionId.budgetVersionCode,
        }))
      );
    });
  }

  function filterkpiListName(filterValue) {
    KpisService.lovGetKpisByHotelId(DefaultHotelId, {
      selectedKpiName: filterValue,
      selectedKpiDescription: "",
    }).then(res => {
      setKpisFiltredList(
        res.data.map(item => ({
          id: item.name,
        }))
      );
    });
  }
  function handleSearchTypeChange(e) {
    setSelectedSearchType(e.target.value);
  }
  function handleAcountDepartmentValueChange(e) {
    setAccountDepartmentValue(e.target.value);
  }

  const onButtonClick = e => {
    e.preventDefault();
    setVisibleSearchSideBar(false);
    searchKpisDependencyItem();
  };

  const customHeaderSearchListKpisMapping = (
    <React.Fragment>
      <button
        type="button"
        className="p-sidebar-close p-sidebar-icon p-link"
        aria-label="close"
        onClick={() => setVisibleSearchSideBar(false)}
      >
        <span className="p-sidebar-close-icon pi pi-times"></span>
      </button>
      <div className="text-900 font-bold text-900 text-xl ml-3">
        {t("searchSidebarTitle")}
      </div>
    </React.Fragment>
  );

  function handleSearchButtonClick() {
    setVisibleSearchSideBar(true);
  }
  function handleCancel(e) {
    e.preventDefault();

    setVisibleSearchSideBar(false);
  }
  function clearKpisListMappingFormSearch() {
    setSelectedSearchType("");
    setAccountDepartmentValue("");
    setSelectedKpiName("");
  }
  return (
    <React.Fragment>
      <DataControls
        addButtonLabel="null"
        filters={kpiFilterBadges}
        onSearchButtonClick={handleSearchButtonClick}
        displayedDataControl="true"
        searchButtonlabel="Search"
        displayedImportExportDataControl="false"
      />

      <Sidebar
        modal="true"
        blockScroll="true"
        showCloseIcon={false}
        icons={customHeaderSearchListKpisMapping}
        id="sidebar-filter"
        visible={visibleSearchSideBar}
        position="right"
        className="p-sidebar-md"
        onHide={() => setVisibleSearchSideBar(false)}
      >
        <form>
          <div className="surface-card p-1  border-round p-fluid">
            <div className="grid formgrid p-fluid">
              {/* <div className="field mb-4 col-12">
                <InputMask
                  mask="9999/99"
                  name="yearMonthField"
                  placeholder={t("placeholderPeriodField")}
                  value={year + "/" + month}
                  onChange={handleSearchPeriodChange}
                  disabled
                />
              </div>
              <div className="field mb-4 col-12">
                <CustomAutoComplete
                  name="budgetVersionCode"
                  value={budgetVersionCodeValue}
                  options={budgetVersion}
                  filterList={filterBudgetVersionList}
                  onChange={handleBudgetVersionCodeChange}
                  placeholder={t("placeholderBudgetVersionCodeField")}
                  dropdown={true}
                  disabled={true}
                ></CustomAutoComplete>
              </div> */}
              <div className="field mb-4 col-12">
                <Dropdown
                  value={selectedSearchType}
                  options={kpisTypeSearch}
                  onChange={handleSearchTypeChange}
                  placeholder={t("placeholderTypeSearchDropDown")}
                  optionLabel="name"
                  optionValue="name"
                  name="selectedSearchType"
                  inputId="selectedSearchType"
                />
              </div>

              <div className="pt-5  mb-4 col-12">
                {selectedSearchType === "Account Department" && (
                  <div className="field mb-4 col-12">
                    <label
                      htmlFor="accountDepartmentvalue"
                      className="font-medium text-900"
                    >
                      {t("labelAccountDepartmentField")}
                    </label>
                    <InputText
                      id="accountDepartmentvalue"
                      name="accountDepartmentvalue"
                      placeholder="Enter Account Department"
                      value={accountDepartmentValue}
                      onChange={handleAcountDepartmentValueChange}
                    />
                  </div>
                )}
                {selectedSearchType === "KPIs Name" && (
                  <div className="field mb-4 col-12">
                    <label htmlFor="kpiName" className="font-medium text-900">
                      {t("labelKpiNameField")}
                    </label>
                    <CustomAutoComplete
                      value={selectedKpiName}
                      options={kpisFiltredList}
                      filterList={filterkpiListName}
                      onChange={handleSelectedKpiNameChange}
                      placeholder={t("placeholderKpiNameField")}
                      inputId="kpiName"
                    ></CustomAutoComplete>
                  </div>
                )}
              </div>
              <div className="field mb-4 col-12 md:col-12">
                <Button
                  icon="pi pi-search"
                  onClick={onButtonClick}
                  label={t("searchButton")}
                  className="p-button  p-button-success mr-2 w-auto"
                />
                <Button
                  type="reset"
                  label={t("resetButton")}
                  onClick={() => {
                    clearKpisListMappingFormSearch();
                  }}
                  className="p-button-secondary p-button-outlined mr-2  w-auto"
                />
                <Button
                  label={t("cancelButton")}
                  onClick={e => handleCancel(e)}
                  className="p-button-secondary p-button-outlined  w-auto"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
      {showOrganizationChart && (
        <HighchartsReact
          highcharts={Highcharts}
          options={treegraphOptions}
          allowChartUpdate={true}
        />
      )}
    </React.Fragment>
  );
}
