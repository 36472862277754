import axios from "axios";
import tokenService from "./TokenService";
import AuthenticationService from "./AuthenticationService";
import { getLocalStorage } from "./LocalStorage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_FD_API_BASE_URl,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": getLocalStorage("language"),
    // accept : "application/json",
    // 'content-Type': 'multipart/form-data'
  },
});

instance.interceptors.request.use(
  config => {
    const token = tokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const access_token = await AuthenticationService.refreshToken();
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
